import { 
    LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, FORGOT_PASSWORD
} from "../actions/types";
import  jwt_decode  from 'jwt-decode';

let token = JSON.parse(localStorage.getItem('web1expertsToken'))
const initialState = token ? { loggedIn: true, user:jwt_decode(token) ? jwt_decode(token) : '' } : {};

export default (state = initialState, action) => {
    switch (action.type) {
    case LOGIN_SUCCESS:
        return {
            ...state,
            loggedIn:true,
            user: action.payload
        };
    case LOGIN_FAILURE:
        return {
            ...state,
            loggedIn:false,
            user:action.payload
        };
    case LOGOUT:
        return {};
    case FORGOT_PASSWORD:
        return {
            ...state,
            user:action.payload
        }
    default:
        return state
    }
}
