import React, { Component }from 'react';
import {Alert} from 'react-bootstrap';
import { socket } from '../../actions/actionResponseHandler';
// import config from '../../helpers/config';
import {connect} from 'react-redux';
import { fetchNotifications } from '../../actions/notificationAction';
import Toast from 'react-toast-component';
// const socket = openSocket(config.apiBaseURL2, {transports: ['polling']});

class NotificationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          notifications: [],
          sidebarOpen: false,
          isOpen: false,
          data:{}
        };
    }


    componentDidMount(){
      socket.emit('JoinRecieveNotification', {userId: this.props.userId}) ; // userId as room
      this.getNotify()
      this.getNotifications()
      this.onEventClearNotifications()
      this.onEventReadNotifications()
    }

    getNotifications = async () => {
        await this.props.fetchNotifications(this.props.userId)
        if(this.props.notifications.response){
            this.setState({
                notifications: this.props.notifications.notifications
            })
        }
    }

    setCloseToast = () => { this.setState({ isOpen: false }) }

    getNotify = () => {
      socket.on('onNotify', data => {
        // console.log("Notification Recieved", data)
        // let notifications = this.state.notifications
        // notifications.push(data)
          this.setState({
            isOpen: true,
            data:data,
            // notifications
          }, () => this.getNotifications())
      })
    }

    ReadNotifications = () => {
      socket.emit('read-notifications', { user: this.props.userId, notifications: this.state.notifications.map(n => n._id ) })
    }

    ClearNotifications = () => {
      socket.emit('clear-notifications', { user: this.props.userId, notifications: this.state.notifications.map(n => n._id ) })
    }

    onReadSingleNotification = (notification) => {
      if(notification && notification._id){
        socket.emit('read-notifications', { user: this.props.userId, notifications: notification._id.split() })
      }
    }

    onDeleteSingleNotification = (notification) => {
      if(notification && notification._id){
        socket.emit('clear-notifications', { user: this.props.userId, notifications: notification._id.split() })
      }
    }

    

    onEventClearNotifications = () => {
      socket.on('onClear', data => {
        if(data && data.response){
          this.getNotifications()
        }
      })
    }

    

    onEventReadNotifications = () => {
      socket.on('onRead', data => {
        if(data && data.response){
          this.getNotifications()
        }
      })
    }

    convertDate = (date) => {
      var d = new Date(date);
      var year = (new Date().getFullYear() === d.getFullYear()) ? '' : ", " +  d.getFullYear()
      var monthName = d.toLocaleString('default', { month: 'short' })
      return  (monthName) + " " + d.getDate() +  year
    }

    convertTime = (date) => {
      var d = new Date(date);
      return  d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
  }

    onSetSidebarOpen = () => {
      this.setState({ sidebarOpen: !this.state.sidebarOpen });
    }

    componentWillUnmount(){
      socket.emit('LeaveRecieveNotification', {userId: this.props.userId})
    }


    render(){
       const { sidebarOpen } = this.state
      //  console.log(this.state.notifications)
        return(
            <>
            <Toast
                isOpen={this.state.isOpen}
                hasAutoDismiss={true}
                hasCloseBtn
                description={this.state.data.notify}
                title="Team Web 1 Experts"
                // hasCloseBtn
                closeCallback={() => this.setCloseToast()}
                // description="There's some great info here."
                // title="App Notification!!"
                duration={5000*2}
                // classNames={['']}  // 'success', 'info', 'warning', 'error'
            />
            <button className="notificationPop" onClick={()=> this.onSetSidebarOpen()}>
                <i className="fa fa-bell-o" ></i>
                {(this.state.notifications.length > 0 && this.state.notifications.filter(n => n.read === false).length > 0) &&
                  <span className="badge"> 
                    { this.state.notifications.filter(n => n.read === false).length } 
                  </span>
                }
            </button>
            <div className={`rightSidebar ${sidebarOpen ? 'sidebarOpen': ''}`}>
              <button className="btn-mark" type="button" onClick={() => this.ReadNotifications()}>
                  <i className="fa fa-envelope-open-o"/> Mark as read
              </button>
              <i className="fa fa-times right" onClick={()=> this.setState({ sidebarOpen: false })}></i>
                {this.state.notifications && this.state.notifications.length > 0 
                  ?
                  this.state.notifications.map((notify, idx) => {
                    return(
                      <div className={`${notify.read ? `message`: `unreadMessage`} message`} key={idx}> 
                        {notify.notify} 
                        <span>
                          <small> 
                            { this.convertTime(notify.time)} | {this.convertDate(notify.time)} 
                          </small>
                          <small style={{display:'block'}}>
                              <span style={{display: 'inline', cursor:'pointer'}} className="mr-1" onClick={() => !notify.read ? this.onReadSingleNotification(notify) : null}> 
                                <i className={`${notify.read ? 'fa fa-check-circle' : 'fa fa-eye'} mr-1`}/> 
                                  {notify.read ? 'Marked as read' : 'Mark as read'}
                              </span>
                              <span style={{display: 'inline', cursor:'pointer'}} onClick={() => this.onDeleteSingleNotification(notify)}> 
                                <i className="fa fa-times mr-1"/> 
                                Opt out
                              </span>
                          </small>
                        </span>
                      </div>
                    )
                  })
                  :
                  <Alert  variant="dark" className="m-2 ">
                      <small> No new notification </small>
                  </Alert>
                }
              <button className="btn-clear" type="button" onClick={() => this.ClearNotifications()}>Clear All</button>
            </div>
            </>
        )
    }
}


const mapStateToProps = state => {
  return {
      notifications: state.notify.notifications
  }
}

const dispatchState = { 
  fetchNotifications 
}
export default connect(mapStateToProps, dispatchState)(NotificationComponent)