import { 
    ADD_REPORT,
    FETCH_SINGLE_USER_REPORT,
    FETCH_SINGLE_PROJECT_REPORT
} from "../actions/types";

const initialState = { reportAdd: '', reportFetch:'' }

export default ( state = initialState, action ) => {

    switch (action.type) {
        case ADD_REPORT:
            return {
                ...state,
                reportAdd: action.payload
            }
        case FETCH_SINGLE_USER_REPORT:
            return {
                ...state,
                reportFetch: action.payload
            }
        case FETCH_SINGLE_PROJECT_REPORT:
            return {
                ...state,
                projectReport: action.payload
            }
        default: return state;
    }
}