
//  ---------------  Authenticate Constants --------------- //

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";


//  ---------------  Status Constants --------------- //
export const STATUS_CREATE = "STATUS_CREATE";
export const STATUS_EDIT = "STATUS_EDIT"
export const STATUS_FETCH = "STATUS_FETCH";
export const STATUS_DELETE = "STATUS_DELETE";

// ---------------- Performance Constants ------------ //
export const FETCH_PERFORMANCE = "FETCH_PERFORMANCE";
export const EDIT_PERFORMANCE = "EDIT_PERFORMANCE";
export const DELETE_PERFORMANCE = "DELETE_PERFORMANCE"

// ----------------- Tutorials Constant -------------- //
export const ADD_TUTORIAL = "ADD_TUTORIAL";
export const EDIT_TUTORIAL = "EDIT_TUTORIAL";
export const FETCH_ROLE_TUTORIALS = "FETCH_ROLE_TUTORIALS";
export const DELETE_TUTORIAL = "DELETE_TUTORIAL";

// ---------------- Project Constants ------------ // 
export const ADD_PROJECT = "ADD_PROJECT";
export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const FETCH_INPROGRESS_PROJECTS = "FETCH_INPROGRESS_PROJECTS";
export const FETCH_COMPLETED_PROJECTS = "FETCH_COMPLETED_PROJECTS";
export const FETCH_HOLD_PROJECTS = "FETCH_HOLD_PROJECTS";
export const DELETE_MEMBER_FROM_PROJECT = "DELETE_MEMBER_FROM_PROJECT";
export const UPDATE_PROJECT_STATUS = "UPDATE_PROJECT_STATUS";
export const CHANGE_HOLD_STATUS = "CHANGE_HOLD_STATUS";
export const CHANGE_PROGRESS_STATUS = "CHANGE_PROGRESS_STATUS";
export const ACCEPT_PROJECT_BY_USER = "ACCEPT_PROJECT_BY_USER";
export const GET_ASSIGN_PROJECT_MEMBER = "GET_ASSIGN_PROJECT_MEMBER";
export const GET_SINGLE_PROJECT = "GET_SINGLE_PROJECT";
export const GET_UNASSIGNED_INPROGRESS_PROJECTS = "GET_UNASSIGNED_INPROGRESS_PROJECTS";
export const GET_UNASSIGNED_HOLD_PROJECTS = "GET_UNASSIGNED_HOLD_PROJECTS";
export const GET_UNASSIGNED_COMPLETED_PROJECTS = "GET_UNASSIGNED_COMPLETED_PROJECTS";
export const GET_UNASSIGNED_ALL_PROJECTS = "GET_UNASSIGNED_ALL_PROJECTS";
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const GET_ALL_PROJECTS_OF_USER ="GET_ALL_PROJECTS_OF_USER"
export const DELETE_TASKS = "DELETE_TASKS";
export const LOAD_MORE_TASKS = "LOAD_MORE_TASKS";
//  ---------------  Report Constants --------------- //
export const ADD_REPORT = "ADD_REPORT";
export const FETCH_SINGLE_USER_REPORT = "FETCH_SINGLE_USER_REPORT";
export const FETCH_SINGLE_PROJECT_REPORT = "FETCH_SINGLE_PROJECT_REPORT";

//  ---------------  Member Constants --------------- //
export const SEND_INVITE = "SEND_INVITE";
export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const EDIT_MEMBER = "EDIT_MEMBER";

//  ---------------  Holiday Constants --------------- //
export const ADD_HOLIDAY = "ADD_HOLIDAY";
export const EDIT_HOLIDAY = "EDIT_HOLIDAY";
export const FETCH_HOLIDAY = "FETCH_HOLIDAY";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";

//  --------------- Notes Constant ------------------ //
export const CREATE_UPDATE_INDIVIDUAL_NOTE = "CREATE_UPDATE_INDIVIDUAL_NOTE";
export const CREATE_UPDATE_PUBLIC_NOTE = "CREATE_UPDATE_PUBLIC_NOTE"
export const GET_INDIVIDUAL_NOTE = "GET_INDIVIDUAL_NOTE";
export const GET_PUBLIC_NOTE = "GET_PUBLIC_NOTE";

// --------------- User Constant -------------------- //
export const FETCH_USER = "FETCH_USER";
export const UPDATE_USER_ACCOUNT_STATUS = "UPDATE_USER_ACCOUNT_STATUS";
// --------------- NOtification Constant ------------//

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS"
