import React, { Component } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { createHoliday, fetchHoliday, updateHoliday, deleteHoliday } from '../../actions/holidayAction';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import swal from 'sweetalert';
import { toast } from "react-toastify";
toast.configure()

class HolidayComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            userRole:'',
            permissions:{},
            loggedInUserId:'',
            fields:{},
            openModel: false,
            addLoader: false,
            getLoader: false,
            openEditModel: false,
            updateFields:{},
            editLoader: false,
            holidays:[],
            backupHolidays:[],
            holidayDate:'',
            holidayMonth: new Date(),
        }
    }

    componentDidMount(){
        if(this.props.auth && this.props.auth.loggedIn){
            if(this.props.auth.user && this.props.auth.user.permissions && this.props.auth.user.permissions.holidays){
                this.setState({ 
                    userRole: this.props.auth.user.role, 
                    permissions:{...this.props.auth.user.permissions.holidays},
                    loggedInUserId: this.props.auth.user.userId,
                    getLoader: true
                })
                this.getHolidays()
            }
        }
    }



    handleInputChange = (e) => {
        const { name, value } = e.target
        let fields = this.state.fields
        fields[name] = value
        this.setState({ fields })
    }

    handleEditChange = (e) => {
        const { name, value } = e.target
        let updateFields = this.state.updateFields
        updateFields[name] = value
        this.setState({ updateFields })
    }

    getHolidays = async () => {
        // this.setState({ getLoader: true})
        await this.props.fetchHoliday();
        // console.log(this.props.getResponse)
        if( this.props.getResponse.response ){
            this.setState({
                holidays: this.props.getResponse.holidays,
                backupHolidays: this.props.getResponse.holidays,
                getLoader: false,
            }, () => this.onChangeHolidayMonth(this.state.holidayMonth))
        }else{
            toast.dismiss()
            toast.error( this.props.getResponse.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ getLoader: false})
        }
    }

    handleSubmitHoliday = async (e) => {
        e.preventDefault();
        let data = {}
        data.holidayDate = this.state.holidayDate
        data.occasion = this.state.fields['occasion']
        data.leaveType = this.state.fields['leaveType']
        // console.log( data )
        this.setState({ addLoader: true})
        await this.props.createHoliday(data)
        if(this.props.addResponse.response){
            toast.dismiss()
            toast.success( this.props.addResponse.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ addLoader: false});
            this.getHolidays();
            //  ------------------------------- //
            this.onCloseModel()
        }else{
            toast.dismiss()
            toast.error( this.props.addResponse.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ addLoader: false})
        }
    }

    handleUpdateHoliday = async (e) =>{
        e.preventDefault();
        this.setState({ editLoader: true})
        let id = this.state.updateFields['_id']
        let data = JSON.parse(JSON.stringify(this.state.updateFields));
        delete data['_id']
        delete data['createdAt']
        delete data['updatedAt']
        data.holidayDate = this.state.holidayDate
        // console.log( data )
        await this.props.updateHoliday(id, data)
        if( this.props.updateResponse.response ){
            toast.dismiss()
            toast.success( this.props.updateResponse.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ editLoader: false});
            this.getHolidays();
            //  --------------------------------- //
            this.onCloseEditModel()
        }else{
            toast.dismiss()
            toast.error( this.props.updateResponse.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ editLoader: false})
        }
    }

    onCloseModel = () => {
        this.setState({ openModel: false })
        this.getHolidays()
        this.resetForm()
    }

    onCloseEditModel = () => {
        this.setState({ openEditModel: false })
        this.getHolidays()
        this.resetForm()
    }

    resetForm = () => {
        this.setState({ fields:{}, updateFields:{}, holidayDate:'' })
    }

    onChangeDate = ( date ) => {
        // console.log(date)
        this.setState({ holidayDate: date })
        // let reports = this.state.backUpReports
        // reports = reports.filter( r => {
        //     return r['createdAt'].split('T')[0] === new Date(date).toISOString().split('T')[0]
        // } )
        // this.setState({ reports })
    }

    convertDate = (date) => {
        var gsDayNames = ['Sunday','Monday','Tuesday','Wednessday','Thursday','Friday','Saturday'];
        var d = new Date(date);
        var year =  d.getFullYear()
        var monthName = d.toLocaleString('default', { month: 'short' })
        return  gsDayNames[d.getDay()] + ", " + (monthName) + " " + d.getDate() + ", " + year
    }

    onDeleteHoliday = ( id ) => {
        swal({
            title: "Are you sure?",
            text: `Once Deleted, Can't be Undo`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {
                swal({ title: "Keep Patience", text: "Deleting...", button: false });
                await this.props.deleteHoliday(id)
                if(this.props.deleteResponse.response){
                    swal({ title: "Deleted!", text: this.props.deleteResponse.message, icon: "success" })
                    this.getHolidays();
                }else{
                    swal({ title: "Something went wrong!", text: this.props.deleteResponse.message, icon: "error" })
                }
            }
        });
    }

    onChangeHolidayMonth = (date) => {
        // console.log(date)
        this.setState({ holidayMonth: date })
        let holidays =  this.state.backupHolidays;
        let i = 0
        // console.log("Before Filter", holidays)
        holidays = holidays.filter( p => {
            // console.log('i --->', i++,'Month ---> ', new Date(p['holidayDate']).getMonth()+1, new Date(date).getMonth()+1, ' Year ---> ', new Date(p['holidayDate']).getFullYear(), new Date(date).getFullYear() )
            // return (new Date(p['holidayDate']).getMonth()+1 === new Date(date).getMonth()+1) && (new Date(p['holidayDate']).getFullYear() === new Date(date).getFullYear())
            return (new Date(p['holidayDate']).getFullYear() === new Date(date).getFullYear())
        })

        // console.log("After Filter", holidays)
        this.setState({ holidays })
    }

    render(){
        const { userRole, openModel, fields, getLoader, holidayDate, addLoader, editLoader, holidays, openEditModel, updateFields, permissions } = this.state

        let addButtonDisable = true
        let editButtonDisable = true
        if(fields['occasion'] && holidayDate){
            if(fields['occasion'].trim() && holidayDate != ''){
                addButtonDisable = false
            }
        }

        if(updateFields['occasion'] && holidayDate){
            if(updateFields['occasion'].trim() && holidayDate != ''){
                editButtonDisable = false
            }
        }
        return(            
            <>  
                <Modal show={ openModel } onHide={ this.onCloseModel }>
                    <Modal.Header closeButton>
                        <Modal.Title> Add Holiday </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>       
                        <Row>
                            <Col sm={12}>
                                <form onSubmit={ this.handleSubmitHoliday}>
                                    <Row>
                                        <Col md={12}>
                                            <div class="form-group">
                                                <label htmlFor="exampleInpu1"> Select Holiday Date </label>
                                                <div className="relative">
                                                    <DatePicker 
                                                        selected={this.state.holidayDate} 
                                                        onChange={date => this.onChangeDate(date)} 
                                                        minDate={new Date()}  
                                                        dateFormat="yyyy-MM-dd"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div class="form-group">
                                                <label htmlFor="exampleInput2">Occasion</label>
                                                <input type="text" name="occasion" className="form-control" onChange={this.handleInputChange} value={fields['occasion']} placeholder="Enter Occasion"/>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div class="form-group">
                                                <label htmlFor="exampleInput3">Leave Type</label>
                                                <select className="form-control" name="leaveType" onChange={this.handleInputChange} defaultValue={fields['leaveType']} value={fields['leaveType']}>
                                                    <option value='' disabled selected> Select Leave Type </option>
                                                    <option value='f'> Full Day </option>
                                                    <option value='h'> Half Day </option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div class="form-group text-right">
                                        <Button type="submit" class="btn btn-primary" disabled={ addButtonDisable || addLoader }>{ addLoader ? 'Adding...': 'Add'}</Button>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                {this.state.updateFields &&
                <Modal show={ openEditModel } onHide={ this.onCloseEditModel }>
                    <Modal.Header closeButton>
                        <Modal.Title> Edit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <Row>
                                <Col sm={12}>
                                    <form onSubmit={ this.handleUpdateHoliday}>
                                        <Row>
                                            <Col md={12}>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInpu1"> Holiday Date </label>
                                                    {/* minDate={new Date()} */}
                                                    <div className="relative">
                                                        <DatePicker 
                                                            selected={this.state.holidayDate} 
                                                            onChange={date => this.onChangeDate(date)} 
                                                            minDate={new Date()}  
                                                            dateFormat="yyyy-MM-dd"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInput2">Occasion</label>
                                                    <input type="text" name="occasion" className="form-control" onChange={this.handleEditChange} defaultValue={updateFields['occasion']} placeholder="Enter Occasion"/>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInput3">Leave Type</label>
                                                    <select className="form-control" name="leaveType" onChange={this.handleEditChange} defaultValue={updateFields['leaveType']}>
                                                        <option value='' disabled> Select Leave Type </option>
                                                        <option value='f'> Full Day </option>
                                                        <option value='h'> Half Day </option>
                                                    </select>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="form-group text-right">
                                            <button type="submit" className="btn btn-primary" disabled={ editButtonDisable || editLoader }>{ editLoader ? 'Updating...': 'Update'}</button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                    </Modal.Body>
                </Modal>}
                <div className="d-md-flex justify-content-md-between mb-3 align-items-center">
                    <h3 className="mt-0 mb-0">Holidays</h3>
                    {/* (userRole === 'super_admin' || userRole === 'hr' || userRole === 'qualityAuditor') */}
                    
                    <div className="dateSelect d-sm-flex justify-content-lg-end justify-content-between w-auto">
                        <Form inline>
                            <Form.Label htmlFor="selectYear" className="mr-2 mb-0">Select Year:</Form.Label>
                            <div className="relative">
                                <DatePicker dateFormat="yyyy" yearItemNumber={6} showYearPicker selected={this.state.holidayMonth} onChange={this.onChangeHolidayMonth} />
                            </div>
                        </Form>
                        {(permissions.actions && permissions.actions.canAdd) && <Button className="ml-2" variant="primary" onClick={()=> this.setState({ openModel: true })}>Add Holiday</Button>}
                    </div>
                </div>
                { getLoader  
                    ?
                    <div className="text-center circleSpinnner"><Spinner animation="border" /></div>
                    :
                    (holidays && holidays.length > 0)
                    ?
                    <div className="table-responsive ">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th className="first-child"> Sr. No. </th>
                                    <th className="two-child"> Holiday Date </th>
                                    <th className="four-child"> Occasion </th>
                                    <th className="six-child"> Type </th>
                                    {(permissions.actions && (permissions.actions.canEdit || permissions.actions.canDelete)) && <th> Action </th>}
                                </tr>
                            </thead>
                            <tbody>
                                
                                    {holidays.map((h, idx) => {
                                        return (
                                            
                                            <tr key = {idx}>
                                                <td > {idx+1} </td>
                                                <td> { this.convertDate(h.holidayDate) || 'N/A' } </td>
                                                <td> { h.occasion || 'N/A' } </td>
                                                <td> 
                                                    {h.leaveType === 'f' &&  'Full Day' }
                                                    {h.leaveType === 'h' && 'Half Day'} 
                                                </td>
                                                {(permissions.actions.canEdit || permissions.actions.canDelete) &&
                                                <td>
                                                    { ( permissions.actions.canEdit ) && <i className="fa fa-pencil" aria-hidden="true" onClick={()=> this.setState({ openEditModel: true, updateFields: h, holidayDate: new Date(h.holidayDate) })}/>}
                                                    { ( permissions.actions.canDelete ) && <i className="fa fa-trash-o" aria-hidden="true" onClick={()=> this.onDeleteHoliday(h._id)}/>}
                                                </td>}
                                            </tr>
                                            
                                        )
                                    })}

                            </tbody>
                        </table>
                    </div>
                    :
                    <p className="m-0 p-2 text-center border">
                        Nothing To Show
                    </p>
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    const { auth, holiday } = state
    return {
        auth: auth,
        addResponse: holiday.addHoliday,
        getResponse: holiday.holiday,
        updateResponse: holiday.editHoliday,
        deleteResponse: holiday.deleteHoliday,
    }
}

const dispatchActions = { createHoliday, fetchHoliday, updateHoliday, deleteHoliday }

export default connect(mapStateToProps, dispatchActions)(HolidayComponent) 