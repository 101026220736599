import { EDIT_PERFORMANCE, FETCH_PERFORMANCE, DELETE_PERFORMANCE } from './types';
import { apiRequest, checkTokenExpire } from './actionResponseHandler';
import authHeader from '../helpers/auth-header'
import { toast } from "react-toastify";
toast.configure()

export const fetchPerformance = userId => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const status = await apiRequest( `/performance/${userId}`, requestOptions );
        checkTokenExpire(status)
        await dispatch({ type: FETCH_PERFORMANCE, payload: status })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const editPerformance = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
            body: JSON.stringify(data)  
        }
        const result = await apiRequest( `/performance/edit`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: EDIT_PERFORMANCE, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const deletePerformance = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            }, 
            body: JSON.stringify(data)
        }
        const result = await apiRequest( `/performance/delete`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: DELETE_PERFORMANCE, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}