import { ADD_HOLIDAY, EDIT_HOLIDAY, FETCH_HOLIDAY, DELETE_HOLIDAY } from "../actions/types";
import { apiRequest, checkTokenExpire } from './actionResponseHandler';
import authHeader from '../helpers/auth-header'
import { toast } from "react-toastify";
toast.configure()

export const createHoliday = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
            body: JSON.stringify(data)
        }
        const result = await apiRequest( "/holiday/add", requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: ADD_HOLIDAY, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const fetchHoliday = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            }    
        }
        const result = await apiRequest( `/holidays`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: FETCH_HOLIDAY, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const updateHoliday = (id, data) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
            body: JSON.stringify(data)    
        }
        const result = await apiRequest(`/holiday/edit/${id}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: EDIT_HOLIDAY, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const deleteHoliday = (id) => async dispatch => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },   
        }
        const result = await apiRequest(`/holiday/delete/${id}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: DELETE_HOLIDAY, payload: result })
    } catch (error) {
        // console.log(error)
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}


