import {
    ADD_REPORT,
    FETCH_SINGLE_USER_REPORT,
    FETCH_SINGLE_PROJECT_REPORT
} from './types';
import { apiRequest, checkTokenExpire } from './actionResponseHandler';
import authHeader from '../helpers/auth-header'
import { toast } from "react-toastify";
toast.configure()

export const addReport = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
            body: JSON.stringify(data)
        }
        const result = await apiRequest( "/report/add", requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: ADD_REPORT, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const getSingleUserReports = (userId, date) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/reports/${userId}/${date}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: FETCH_SINGLE_USER_REPORT, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

// export const getSingleUserReports = (userId, date) => async dispatch => {
//     try {
//         const requestOptions = {
//             method: 'GET',
//             headers:{
//                 "Content-Type": "application/json; charset=utf-8",
//                 'Authorization': authHeader()
//             },  
//         }
//         const result = await apiRequest( `/reports/${userId}/${date}`, requestOptions );
//         checkTokenExpire(result)
//         await dispatch({ type: FETCH_SINGLE_USER_REPORT, payload: result  })
//     } catch (error) {
//         if(error && error.status === 401){
//             checkTokenExpire(error.data)
//         }else{
//             toast.dismiss()
//             toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
//         }
//     }
// }

export const getSingleProjectReport = (projectId, date) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/project-reports/${projectId}/${date}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: FETCH_SINGLE_PROJECT_REPORT, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}