import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, FORGOT_PASSWORD } from './types';
import { apiRequest, apiRequest_2 } from './actionResponseHandler';

import { toast } from "react-toastify";
toast.configure()

export const login = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(data)
        }
        const user = await apiRequest( "/user/authenticate", requestOptions );
        if(user.response && user.token){
            localStorage.setItem('web1expertsToken', JSON.stringify(user.token));
            await dispatch({ type: LOGIN_SUCCESS, payload: user })
        }else{
            await dispatch ({ type: LOGIN_FAILURE, payload: user})
        }
        
    } catch (error) {
        await dispatch ({ type: LOGIN_FAILURE, payload:error})
        // toast.dismiss()
        // toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
    }
}

export const logout = () => async dispatch => {
    // remove user from local storage to log user out
    localStorage.removeItem('web1expertsToken');
    if(localStorage.getItem('RememberMe')){
        localStorage.removeItem('RememberMe')
    }
    dispatch({type:LOGOUT})
}

export const forgotPassword = email => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { "Content-Type": "application/json; charset=utf-8" },
        }
        const result = await apiRequest_2( `/reset-password/link/${email}`, requestOptions );
        await dispatch({ type: FORGOT_PASSWORD, payload: result })        
    } catch (error) {
        toast.dismiss()
        toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
    }
}