import { ADD_TUTORIAL, FETCH_ROLE_TUTORIALS, EDIT_TUTORIAL, DELETE_TUTORIAL } from "../actions/types";

const initialState = { tutorials: '', addTutorial:'', editTutorial:'', deleteTutorial:'' }

export default ( state = initialState, action ) => {

    switch (action.type) {
        case ADD_TUTORIAL:
            return {
                ...state,
                addTutorial: action.payload
            }
        case FETCH_ROLE_TUTORIALS:
            return {
                ...state,
                tutorials: action.payload
            }
        case EDIT_TUTORIAL:
            return {
                ...state,
                editTutorial: action.payload
            }
        case DELETE_TUTORIAL:
            return {
                ...state,
                deleteTutorial: action.payload
            }
        default: return state;
    }
}