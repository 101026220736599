import { SEND_INVITE, FETCH_MEMBERS, DELETE_MEMBER, EDIT_MEMBER, UPDATE_USER_ACCOUNT_STATUS } from "../actions/types";

const initialState = { member: '', invite:'', memberDeleted:'', memberUpdated:'', memberAccountStatus:'' }

export default ( state = initialState, action ) => {

    switch (action.type) {
        case SEND_INVITE:
            return {
                ...state,
                invite: action.payload
            }
        case FETCH_MEMBERS:
            return {
                ...state,
                member: action.payload
            }
        case DELETE_MEMBER:
            return {
                ...state,
                memberDeleted: action.payload
            }
        case EDIT_MEMBER:
            return {
                ...state,
                memberUpdated: action.payload
            }
        case UPDATE_USER_ACCOUNT_STATUS:
            return {
                ...state,
                memberAccountStatus: action.payload
            }
        default: return state;
    }

}
