import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { SidebarData } from './SidebarData';
class Sidebar extends Component {
    constructor(props){
        super(props);
        // console.log(props)
        this.state = {
            activeTab:'', 
            isOpen: this.props.isOpen
        }
        // console.log(SidebarData)
    }

    getActiveTab = ( idx ) => {
        // console.log( idx )
        this.setState({ activeTab: idx,  })
    }
    // animate__animated ${this.state.isOpen ? 'animate__slideOutLeft' : 'animate__slideInLeft'}
    render() {
        const { activeTab } = this.state
        // console.log(this.props)
        // if(this.props.auth.loggedIn){
            return (
                <>
                    <ul className={`nav nav-tabs ${this.props.isOpen ? 'menuClose': 'menuOpen'}`} id="myTab" role="tablist">
                    {SidebarData.map((item, idx) =>{
                        let obj = this.props.auth.user ? this.props.auth.user.permissions : {}
                        for (let key in obj) {
                            if(obj[key].path === item.to){
                                if(window.location.pathname === item.to){
                                    var tab = idx
                                }
                                return (
                                    <li className="nav-item" key={idx}>
                                        <Link className={`nav-link`} style={{borderColor: ( (activeTab || tab) === idx) ? '#5937E5' : '', fontWeight: ((activeTab || tab) === idx) ? 600 : '', background: ((activeTab || tab) === idx) ? `rgb(89, 55, 229, 0.05)` : ''}} id="status-tab" to={item.to} onClick={() =>{
                                            this.getActiveTab(idx)
                                            this.props.handleViewSidebar()
                                        }}>{item.icon} {item.title} </Link>
                                    </li>
                                )
                            }
                        }
                    })}
                </ul>
                </>
            )
        // }else{
        //     return null
        // }
    }
}


const mapStateToProps = state => {
    // console.log(state)
    const { auth } = state
    // console.log(auth)
    return {
        auth: auth
    }
}
export default connect(mapStateToProps, null)(Sidebar);