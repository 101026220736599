import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom"; 
// import { toast } from "react-toastify";
import  jwt_decode  from 'jwt-decode';
// import role from '../helpers/role';
// --------------------- Public Routes --------------------- //
// import NotFound from '../components/Errors/404'
// import LoginComponent from '../components/Login/LoginComponent';
// --------------------- Public Routes --------------------- //

// --------------------- Private Routes -------------------- //
// import DashboardComponent from '../components/Dashboard/DashboardComponent';
import StatusComponent from '../components/Status/StatusComponent';
import PerformanceComponent from '../components/Performance/PerformanceComponent';
import TutorialComponent from '../components/Tutorials/TutorialComponent';
import ReportComponent from '../components/Reports/ReportsComponent';
import MembersComponent from '../components/Members/MemberComponent';
// import ViewReportsComponent from '../components/ViewReports/ViewReportsComponent';
import SettingComponent from '../components/Setting/SettingComponent';
// import SettingPage from '../components/SettingPage/SettingPage';
import ProjectsComponent from '../components/Projects/ProjectsComponent';
import NotesComponent from '../components/Notes/NotesComponent';
import HolidayComponent from '../components/Holidays/HolidayComponent';

// --------------------- Private Routes -------------------- //


// --- Error Page --- //
const ROUTES = [
  // { path: "/login", key: "LOGIN", exact: true, component: LoginComponent },  
  { path:'/', key:"DASHBOARD", component: props => {

      if(!localStorage.getItem('web1expertsToken')){
          return <Redirect to='/login'/>
      }

      let token = JSON.parse(localStorage.getItem('web1expertsToken'))
      let userRoots = jwt_decode(token)
      let mod = Object.values(userRoots.permissions)
      let isIncluded = mod.some( p => p.path == window.location.pathname )
      if(!isIncluded){
          return <Redirect to='/status'/>
      }
     
      return <RenderRoutes {...props} />
    },
    routes: [
        { path: "/", key: "DASHBOARD_ROOT", exact: true, component: StatusComponent },
        { path: "/status", key: "STATUS", exact: true, component: StatusComponent },
        { path: "/performance", key: "PERFORMANCE", exact: true, component: PerformanceComponent},
        { path: "/tutorials", key: "TUTORIALS", exact: true, component: TutorialComponent},
        { path: "/projects", key: "PROJECTS", exact: true, component: ProjectsComponent },
        { path: "/report", key: "REPORT", exact: true, component: ReportComponent},
        { path: "/team-members", key: "TEAM", exact: true, component: MembersComponent},
        // { path: "/view-reports", key: "VIEW_REPORTS", exact: true, component: ViewReportsComponent},
        { path: "/setting", key: "SETTING", exact: true, component: SettingComponent },
        { path: "/notes", key: "NOTES", exact: true, component: NotesComponent },
        { path: "/holidays", key: "HOLIDAYS", exact: true, component: HolidayComponent },
    ]
  }
];
export default ROUTES;

function RouteWithSubRoutes(route) {
  // if(!localStorage.getItem('web1expertsToken'))
    return (
      <Route
        path={route.path}
        exact={route.exact}
        render={props => <route.component {...props} routes={route.routes} />}
      />
    );
}


export function RenderRoutes({ routes }) {
    return (
      <Switch>
        {routes.map((route, i) => {
          return <RouteWithSubRoutes key={route.key} {...route} />;
        })}
        {/* <Route render={ props => <NotFound />} /> */}
      </Switch>
    );
}