import { EDIT_PERFORMANCE, FETCH_PERFORMANCE, DELETE_PERFORMANCE } from "../actions/types";

const initialState = { performance: '', editPerformance:'', deletePerformance:'' }

export default ( state = initialState, action ) => {

    switch (action.type) {
        case FETCH_PERFORMANCE:
            return {
                ...state,
                performance: action.payload
            }
        case EDIT_PERFORMANCE:
            return {
                ...state,
                editPerformance: action.payload
            }
        case DELETE_PERFORMANCE:
            return {
                ...state,
                deletePerformance: action.payload
            }
        default: return state;
    }
}
