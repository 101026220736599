import { GET_INDIVIDUAL_NOTE, GET_PUBLIC_NOTE } from './types';
import { apiRequest, checkTokenExpire } from './actionResponseHandler';
import authHeader from '../helpers/auth-header'
import { toast } from "react-toastify";
toast.configure()

export const getIndividualNote = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
        }
        const result = await apiRequest( `/notes/individual`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: GET_INDIVIDUAL_NOTE, payload: result });
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const getPublicNote = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
        }
        const result = await apiRequest( `/notes/public`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: GET_PUBLIC_NOTE, payload: result });
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}