import React, { Component } from 'react';
import { Button, Col, Form, Alert, Modal, Row, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchMembers } from '../../actions/memberAction';
import { 
    selfAddProjectToAdmin, 
    fetchSingleUserProjects, 
    fetchHoldProjectsOfMember, 
    fetchCompletedProjectsOfMember, 
    fetchAllProjectsOfMember, 
    fetchAllInprogressProjects, 
    updateProjectStatus, 
    deleteProject, 
    selfEditProjectToAdmin, 
    fetchCompletedProjects, 
    removeMemberFromProject, 
    changeProjectHoldStatus, 
    fetchHoldProjects, 
    changeProjectProgressStatus, 
    getAssignMembers,  
    getUsersAllProjects,  
    deleteTasks,
    loadMoreTasks 
} from '../../actions/projectActions';
import ReactPaginate from 'react-paginate';
import Autosuggest from 'react-autosuggest';
import swal from 'sweetalert';
import { socket } from '../../actions/actionResponseHandler';
import { toast } from "react-toastify";
import ProjectComponent from './ProjectsComponent';
toast.configure()

class ShowOwnsProjectsComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            taskLists: [{ _id:'', name: '' }],
            completedTasksBackup:[],
            estimateHours:'',
            estimateMinutes:'',
            projectDetails:{
                projectName:'',
                estimateTime:'',
                uor:false,
                isClientContract:'',
            },

            suggestions: [],

            showMemberStep: false,
            userRole:'',
            identifying:'',
            permissions:{},
            loggedInUserId:'',
            loggedInUserName:'',
            oldMembersListBeforeEdit:[],
            assignedMembers:[],
            showAssignedMembers:[],
            membersList:[],
            selectedMemberId:'',
            membersListLoader: false,
            addProjectLoader: false,
            fetchProjectLoader: false,
            projects:[],
            allProjects:[],
            autoSuggestionProjects:[],
            backupProjects:[],
            openTaskModel: false,
            isMoreTasks: true, //default true
            skipTasks: 20, // default for loadmore tasks
            loadingMoreTasks: false,
            tasks:[],
            task__projectName:'',
            // --- STeps --- //
            showProjectName: true,
            showEstimateTime:false,
            showUOR: false,
            showTaskList: false,
            showIsClientContract: false,
            searchInput:'',
            tasksSelectedForDel:[],
            taskDelLoader: false,
            selectedProjectIdForTaskDel:'',


            //--  pagination --//
            currentPage:0,
            resultsPerPage:10,
            //--  pagination --//

            // ------------Edit Project ---------- //
            projectEditDetails:{},
            editProject: false, 
            addProject: true,
            projectId:'',
            acceptProject: false,
            openMemberModel: false,

            //  ----- Completed/In-Progress ------- //
            selectedProjectType: 'p-inprogress',

            //  -------- Drag N Drop ----------- //
            reorderEnabled: false,
            selectedRowIds: [],
            draggingRowId: null,

            //  ----------- only for admin ------------ //
            showAllUsersProjects: false,
            // ------------ //
            isTourOpen: true,
            options: [{name: 'Srigar', id: 1},{name: 'Sam', id: 2}]
        }
    }

    componentDidMount(){
        window.addEventListener('click', this.onWindowClick);
        window.addEventListener('keydown', this.onWindowKeyDown);
        window.addEventListener('touchend', this.onWindowTouchEnd);
        // swal("Module Has been Updated! Testing is in Progress", {
        //     buttons: false,
        //     timer: 2000,
        // });
        if(this.props.auth && this.props.auth.loggedIn){
            // console.log('Logged In User Details', this.props.auth.user)
            if(this.props.auth.user && this.props.auth.user.permissions && this.props.auth.user.permissions.projects){
                this.setState({ 
                    // userRole: this.props.auth.user.role, 
                    permissions:{...this.props.auth.user.permissions.projects},
                    loggedInUserId: this.props.auth.user.userId,
                    identifying: this.props.auth.user.identifying,
                    loggedInUserName: this.props.auth.user.fullname
                    // selectedMemberId:'all-projects',
                }, async ()=> {
                    
                    this.fetchProjectLoader(true)
                    await this.getSingleUserProjects(this.state.loggedInUserId)
                    
                })
            }
        }
    }

    sendNotification = async (members, message) => {
        socket.emit('Send_Notification', { room: members, notify: message});
    }

       //  ------------- Function to enable / disable loader ----------- //
       fetchProjectLoader = (value) => {
        this.setState({fetchProjectLoader: value})
    }
    // ------------- ----------------------------------------- ------- //

    notifyToAllMembersOnAccept = async (projectId, projectname) => {
        await this.props.getAssignMembers(projectId)
        if(this.props.projectMembers.response){
            let members = this.props.projectMembers.members.filter(m=> m.toString() !== this.state.loggedInUserId.toString() ).map(n => n)
            this.sendNotification(members, `${this.state.loggedInUserName} accepted project "${projectname}"`)
        }
    }

    getSingleUserProjects = async (loggedInUserId) => {
        // this.setState({ fetchProjectLoader: true})
        await this.props.fetchSingleUserProjects(loggedInUserId);
        // console.log('Single User Projects ')
        // console.log(this.props.projectFetch)
        if(this.props.projectFetch.response){
            this.setState({
                projects: this.props.projectFetch.projects,
                backupProjects: this.props.projectFetch.projects,
                autoSuggestionProjects:this.props.projectFetch.projects,
                fetchProjectLoader: false,
            })
        }else{
            this.setState({
                fetchProjectLoader: false
            })
        }
    }

    getSingleUserHoldProjects = async (userId) => {
        // this.setState({ fetchProjectLoader: true})
        await this.props.fetchHoldProjectsOfMember(userId);
        // console.log('Single User Hold Projects ', userId)
        // console.log(this.props.holdProject)
        if(this.props.holdProject.response){
            this.setState({
                projects: this.props.holdProject.projects,
                backupProjects: this.props.holdProject.projects,
                // autoSuggestionProjects:this.props.holdProject.projects,
                fetchProjectLoader: false,
            })
        }else{
            this.setState({
                fetchProjectLoader: false
            })
        }
    }

    getSingleUserCompletedProjects = async (userId) => {
        // this.setState({ fetchProjectLoader: true})
        await this.props.fetchCompletedProjectsOfMember(userId);
        // console.log('Single User Completed Projects ', userId)
        // console.log(this.props.completedProject)
        if(this.props.completedProject.response){
            this.setState({
                projects: this.props.completedProject.projects,
                backupProjects: this.props.completedProject.projects,
                // autoSuggestionProjects:this.props.completedProject.projects,
                fetchProjectLoader: false,
            })
        }else{
            this.setState({
                fetchProjectLoader: false
            })
        }
    }

    get_UsersAllProjects = async (userId) => {
        await this.props.getUsersAllProjects(userId);
        // console.log("Member All Projects", userId , this.props.usersAllProjects)
        if(this.props.usersAllProjects.response){
          this.setState({
              projects: this.props.usersAllProjects.projects,
              backupProjects: this.props.usersAllProjects.projects,
              fetchProjectLoader: false,
          })
        }else{
            this.setState({ fetchProjectLoader: false })
        }
    }

    // getAllProjectsOfMember = async () => {
    //     this.setState({ fetchProjectLoader: true})
    //     await this.props.fetchAllProjectsOfMember(this.state.selectedMemberId);
    //     // console.log('All Projects', this.state.selectedMemberId)
    //     // console.log(this.props.projectFetch)
    //     if(this.props.projectFetch.response){
    //         // console.log('fffffffffffffffffffffffffffffffffffff')
    //         let i = 0 // only used for drag and move based on id 
    //         let p = this.props.projectFetch.projects.map(function(elm) {
    //             let o = Object.assign({}, elm);
    //             o.id = i;
    //             i++
    //             return o;
    //         })
    //         this.setState({
    //             allProjects: p.length ? p : [],
    //             backupProjects: p.length ? p : [],
    //             fetchProjectLoader: false,
    //         })
    //     }else{
    //         // console.log(" wwwwwwwwwwwwwwwwww")
    //         this.setState({ fetchProjectLoader: false })
    //     }
    // }


    // getAllProgressProjects = async () => {
    //     this.setState({ fetchProjectLoader: true})
    //     await this.props.fetchAllInprogressProjects();
    //     // console.log('All In-Progress Projects')
    //     // console.log(this.props.progressProject)
    //     if(this.props.progressProject.response){
    //         let i = 0 // only used for drag and move based on id 
    //         let proj = this.props.progressProject.projects.map(function(el) {
    //             let o = Object.assign({}, el);
    //             o.id = i;
    //             i++;
    //             return o;
    //         })
    //         this.setState({
    //             selectedMemberId:'all-projects',
    //             allProjects: proj,
    //             autoSuggestionProjects:this.props.progressProject.projects,
    //             backupProjects: proj,
    //             fetchProjectLoader: false,
    //         })
    //     }else{
    //         this.setState({ fetchProjectLoader: false })
    //     }
    // }

    // getAllCompletedProjects = async () => {
    //     this.setState({ fetchProjectLoader: true})
    //     await this.props.fetchCompletedProjects();
    //     // console.log('All Completed Projects')
    //     // console.log(this.props.completedProject)
    //     if(this.props.completedProject.response){
    //         this.setState({
    //             allProjects: this.props.completedProject.projects,
    //             backupProjects: this.props.completedProject.projects,
    //             fetchProjectLoader: false,
    //         })
    //     }else{
    //         this.setState({ fetchProjectLoader: false })
    //     }
    // }

    // getAllHoldProjects = async () => {
    //     this.setState({ fetchProjectLoader: true})
    //     await this.props.fetchHoldProjects();
    //     // console.log('All Hold Projects')
    //     // console.log(this.props.holdProject)
    //     if(this.props.holdProject.response){
    //         this.setState({
    //             allProjects: this.props.holdProject.projects,
    //             backupProjects: this.props.holdProject.projects,
    //             fetchProjectLoader: false,
    //         })
    //     }else{
    //         this.setState({ fetchProjectLoader: false })
    //     }
    // }

    // getMembersList = async () => {
    //     this.setState({ membersListLoader: true })
    //     await this.props.fetchMembers();
    //     if(this.props.membersList.response){
    //         let memberList  = (this.props.membersList.users && this.props.membersList.users.length) ? this.props.membersList.users.filter(x=> x.role !== 'super_admin') : [] 
    //         this.setState({
    //             membersList: memberList, //this.props.membersList.users,
    //             membersListLoader: false,
    //             // selectedMemberId: this.props.membersList.users[0]._id
    //         })
    //     }else{
    //         toast.dismiss();
    //         toast.error(this.props.membersList.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
    //         this.setState({ membersListLoader: false })
    //     } 
    // }

    changeStep = ( step ) => {
        switch (step) {
            case 1: this.setState({
                        showProjectName: true,
                        showEstimateTime:false,
                        showUOR: false,
                        showTaskList: false,
                        showMemberStep: false,
                        showIsClientContract: false,
                    })
                    break;
            case 3: this.setState({
                        showProjectName: false,
                        showTaskList: false,
                        showEstimateTime:true,
                        showUOR: false,
                        showMemberStep: false,
                        showIsClientContract: false,
                    })
                    break;
            case 4: this.setState({
                        showProjectName: false,
                        showTaskList: false,
                        showEstimateTime:false,
                        showUOR: true,
                        showMemberStep: false,
                        showIsClientContract: false,
                    })
                    break;
            case 2: this.setState({
                        showProjectName: false,
                        showTaskList: true,
                        showEstimateTime:false,
                        showUOR: false,
                        showMemberStep: false,
                        showIsClientContract: false,
                    })
                    break;
            case 5: this.setState({
                        showIsClientContract: true,
                        showProjectName: false,
                        showTaskList: false,
                        showEstimateTime:false,
                        showUOR: false,
                        showMemberStep: false,
                    })
                    break;
            case 9999: this.setState({
                        showProjectName: false,
                        showMemberStep: true,
                        showEstimateTime:false,
                        showUOR: false,
                        showTaskList: false,
                        showIsClientContract: false,
                    })
                    break;
            default: break;
        }
    }

    onHandleProjectChange = (event, { newValue, method }) => {
        let projectDetails = this.state.projectDetails
        projectDetails['projectName'] = newValue
        this.setState({ projectDetails })
      };

      handleClientContractChange = (e) => {
          const {value} = e.target
        let projectDetails = this.state.projectDetails
        projectDetails['isClientContract'] = value
        this.setState({ projectDetails })
      }

    // ----------------------- Auto Complete Suggestion Input Starts Here ------------------------- //

    escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
      
    getSuggestions = (value)  => {
        // console.log(value)
        let { autoSuggestionProjects } = this.state
        const escapedValue = this.escapeRegexCharacters(value.trim());
        
        if (escapedValue === '') {
          return [];
        }
      
        const regex = new RegExp('^' + escapedValue, 'i');
        return autoSuggestionProjects.filter(p => regex.test(p.projectName));
    }
      
    getSuggestionValue = (suggestion) => {
        // console.log("Suggestion Value---->>>", suggestion)
        this.handleResetStepForm()
        this.setState({ editProject: true, addProject: false, projectEditDetails: suggestion }, () => this.onSetEditProjectDetails())  
        return suggestion.projectName;
    }
      
    renderSuggestion = (suggestion, { query }) => {
        return (
            <span> {suggestion.projectName} </span>
        )
        // const matches = AutosuggestHighlightMatch(suggestion.projectName, query);
        // const parts = AutosuggestHighlightParse(suggestion.projectName, matches);
      
        // return (
        //   <span>
        //     {parts.map((part, index) => {
        //       const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;
      
        //       return (
        //         <span className={className} key={index}>
        //           {part.text}
        //         </span>
        //       );
        //     })}
        //   </span>
        // );
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
          suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    // ----------------------- Auto Complete Suggestion Input Ends Here ------------------------- // 

    //  -------------  Step 1 ----------- //
    showProjectName = () => {
        const { userRole, membersList, acceptProject, editProject, suggestions } = this.state
        const inputProps = {
            placeholder: `Type Project Name to 'Edit' or Enter New Project Name to 'Add'`,
            value: this.state.projectDetails['projectName'],
            onChange: this.onHandleProjectChange,
            disabled:(editProject && acceptProject) ? true : false 
        };
        let nextButtonDisable = true
        if(this.state.projectDetails['projectName'].trim().length > 0){
            nextButtonDisable = false
        } 

        return (            
            <div className="p-4 shadow-lg reportForm">
                <div className="row justify-content-center">
                    <div className='col-sm-12 animate__animated animate__fadeInDown second-step'>
                        <div className="form-group d-sm-flex justify-content-between align-items-center">
                            <label>Project Name</label>
                            <Autosuggest 
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={this.renderSuggestion}
                                inputProps={inputProps}
                            />
                            <div className="button-group justify-content-end d-flex">
                                <Button type="submit" variant="primary"  disabled={ nextButtonDisable } onClick={() =>  this.changeStep(5)}> 
                                Next  <i className="fa fa-chevron-right ml-1"></i></Button>                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    showIsClientContract = () => {
        const {userRole} = this.state
        return (            
            <div className="p-4 shadow-lg reportForm">
                <div className="row justify-content-center">
                    <div className='col-sm-12 animate__animated animate__fadeInDown second-step'>
                        <div className="form-group d-sm-flex justify-content-between align-items-center">
                        <label>Client/Contract</label> 
                             <input type="text" name="isClientContract" className="form-control" placeholder="Enter Client/Contract Name" onChange={this.handleClientContractChange} defaultValue={this.state.projectDetails['isClientContract'] !== 'N/A' || '' ? this.state.projectDetails['isClientContract'] : ''}/>                         
                            <div className="button-group justify-content-end d-flex">
                                <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(1)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                <Button type="submit" variant="primary"  onClick={() => (userRole === 'super_admin' || userRole === 'admin') ? this.changeStep(9999) : this.changeStep(2)}> 
                                Next  <i className="fa fa-chevron-right ml-1"></i></Button>                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    handleTaskInputChange = (idx) => (evt) => {
        const newTask = this.state.taskLists.map((task, sidx) => {
            if (idx !== sidx){
                // if(!task.memberId){
                //     task.memberId = this.state.loggedInUserId
                // }
                return task 
            }
          return { ...task, name: evt.target.value, memberId: this.state.loggedInUserId };
        });
        
        this.setState({ taskLists: newTask });
    }

    handleAddTask = () => {
        this.setState({ taskLists: this.state.taskLists.concat([{ name: '' }]) });
      }
      
    handleRemovetask = (idx) => () => {
        this.setState({ taskLists: this.state.taskLists.filter((s, sidx) => idx !== sidx) });
    }


    //  ------ Step 2 For Others User and Step 3 for Admins Only ---- //
    showTaskList = () => {
        const { userRole } = this.state
        let nextButtonDisable = true
        if((userRole === 'super_admin' || userRole === 'admin')){
            nextButtonDisable = false
        }else{
            if(this.state.taskLists.length > 0){
                this.state.taskLists.forEach(( task, idx ) => {
                    if( (task.name.trim().length > 1) && task.name != ""){
                        nextButtonDisable = false
                    }else{
                        nextButtonDisable = true
                    }
                })
            }
        } 
        return (
            <>
            <div className="p-4 shadow-lg reportForm taskStep">
                <div className="row justify-content-center">
                    <div className="col-sm-12 animate__animated animate__fadeInRight">
                        <div className="d-xl-flex justify-content-between">
                            <label>Tasks List</label>
                            {(this.state.taskLists.length == 0) && <button type="button" onClick={this.handleAddTask} className="btn btn-sm btn-success">Add Task</button>}                    
                            <div className="form-group d-sm-flex justify-content-between align-items-center flex-wrap">
                                {this.state.taskLists.map((task, idx) => {
                                    return (
                                        <div className="task-list" key={idx}>
                                            <input
                                                type="text"
                                                placeholder={`Task #${idx + 1}`}
                                                value={task.name}
                                                className="form-control"
                                                onChange={this.handleTaskInputChange(idx)}
                                                disabled={task.isCompleted}
                                            />
                                            <button type="button" onClick={task.isCompleted ? null : this.handleRemovetask(idx)} disabled={task.isCompleted} className="btn btn-sm btn-danger"> <i className="fa fa-minus" aria-hidden="true"></i>
                                            </button>
                                                {(this.state.taskLists.length === idx + 1 ) && <button type="button" onClick={this.handleAddTask} className="btn btn-sm btn-success ml-2"> <i className="fa fa-plus" aria-hidden="true"></i>
                                            </button>}   
                                        </div>
                                    )
                                })}
                                <div className="button-group justify-content-end d-flex">
                                    <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(5)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                    <Button type="submit" variant="primary" disabled={ nextButtonDisable } onClick={() => this.changeStep(3)}>Next <i className="fa fa-chevron-right"></i> </Button>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }

    handleEstimateTimeChange = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    //  --------------  Below Steps 3 & 4 Are for Others Users ------------------------ //

    //  --------------  Step 3 --------------- //
    showEstimateTime = () => {
        let nextButtonDisable = true
        const { estimateHours, estimateMinutes } = this.state 
        if(estimateHours.trim().length > 0 && estimateMinutes.trim().length > 0){
            nextButtonDisable = false
        }
        return (
            <>
            <div className="p-4 shadow-lg reportForm timeStep">
                <div className="row justify-content-center">
                    <div className="col-sm-12 animate__animated animate__fadeInRight">
                        <div className="form-group d-sm-flex justify-content-between align-items-center">
                            <label>Estimated Time</label>
                            {/* <input type="text" name="time" className="form-control" /> */}
                            <div className="timeDiv">
                                <select className="form-control" name="estimateHours" onChange={this.handleEstimateTimeChange} value={this.state.estimateHours}>
                                        <option value="" disabled selected> Select </option>
                                        <option value="00"> 00 </option>
                                        <option value="01"> 01 </option>
                                        <option value="02"> 02 </option>
                                        <option value="03"> 03 </option>
                                        <option value="04"> 04 </option>
                                        <option value="05"> 05 </option>
                                        <option value="06"> 06 </option>
                                        <option value="07"> 07 </option>
                                        <option value="08"> 08 </option>
                                        <option value="09"> 09 </option>
                                        <option value="10"> 10 </option>
                                        <option value="11"> 11 </option>
                                        <option value="12"> 12 </option>
                                        <option value="13"> 13 </option>
                                        <option value="14"> 14 </option>
                                        <option value="15"> 15 </option>
                                        <option value="16"> 16 </option>
                                        <option value="17"> 17 </option>
                                        <option value="18"> 18 </option>
                                        <option value="19"> 19 </option>
                                        <option value="20"> 20 </option>
                                        <option value="21"> 21 </option>
                                        <option value="22"> 22 </option>
                                        <option value="23"> 23 </option>
                                </select>
                                <span> Hours </span>
                                <select className="form-control" name="estimateMinutes" onChange={this.handleEstimateTimeChange} value={this.state.estimateMinutes}>
                                        <option value="" disabled selected> Select </option>
                                        <option value="00"> 00 </option>
                                        <option value="05"> 05 </option>
                                        <option value="10"> 10 </option>
                                        <option value="15"> 15 </option>
                                        <option value="20"> 20 </option>
                                        <option value="25"> 25 </option>
                                        <option value="30"> 30 </option>
                                        <option value="35"> 35 </option>
                                        <option value="40"> 40 </option>
                                        <option value="45"> 45 </option>
                                        <option value="50"> 50 </option>
                                        <option value="55"> 55 </option>
                                </select>
                                <span className="mr-2"> Minutes </span>                            
                            </div>
                            <div className="button-group justify-content-end d-flex">
                                <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(2)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                <Button type="submit" variant="primary" disabled={ nextButtonDisable } onClick={() => this.changeStep(4)}>Next <i className="fa fa-chevron-right"></i></Button>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }

    // ---- Step 4 ---- //

    handleChangeUOR = (e) => {
        const { name, value } = e.target
        // console.log(value)
        let projectDetails = this.state.projectDetails
        if( value == '0'){
            projectDetails[name]= false
        }
        if( value == '1'){
            projectDetails[name] = true
        }
        this.setState({ projectDetails })
    }
    showUOR = () => {
        return (
            <>
            <div className="p-4 shadow-lg reportForm">
                <div className="row justify-content-center">
                    <div className="col-sm-12 animate__animated animate__fadeInRight">
                        <div className="form-group d-sm-flex justify-content-between align-items-center">
                            <label>Did you understand the requirements?</label>
                            <select className="form-control" name="uor" onChange={this.handleChangeUOR} value={this.state.projectDetails['uor'] ? 1 : 0}>
                                <option value='1'>Yes I understood</option>
                                <option value='0'>No I didn't understood</option>
                            </select>
                            <div className="button-group justify-content-end d-flex">
                                <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(3)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                <Button type="submit" variant="primary" disabled={ this.state.addProjectLoader } onClick={(e)=> this.handleProjectSubmit(e)}> { this.state.addProjectLoader ? 'wait..' : this.state.acceptProject ? 'Accept':'Finish'} </Button>    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }

// -----------------Post Action to Server ---------------- //
    handleProjectSubmit = async (e) => {
        e.preventDefault();
        let data = {}
        let members = []
        let assignBy = null
        let flag = 0
        
        if(this.state.userRole === 'super_admin' || this.state.userRole === 'admin'){
            members = this.state.assignedMembers.map(m => m._id)
            assignBy = this.state.loggedInUserId
        }else{
            members.push(this.state.loggedInUserId)
            assignBy = this.state.loggedInUserId
        }

        if(this.state.acceptProject && this.state.editProject){
                flag += 1
                data = {...this.state.projectDetails, estimateTime:`${this.state.estimateHours}:${this.state.estimateMinutes}`, members, accepted: true }
                // console.log("Data Checking ------>>>> " , data)
        }else if(!this.state.acceptProject && this.state.editProject){

            if(this.state.userRole === 'super_admin' || this.state.userRole === 'admin'){
                delete this.state.projectDetails['estimateTime']
                delete this.state.projectDetails['uor']
                data = {...this.state.projectDetails, members, assignBy}
            }else{
                data = {...this.state.projectDetails, estimateTime:`${this.state.estimateHours}:${this.state.estimateMinutes}`, members}
            }
        }else{
            if(this.state.userRole !== 'super_admin' || this.state.userRole !== 'admin'){
                data = {...this.state.projectDetails, estimateTime:`${this.state.estimateHours}:${this.state.estimateMinutes}`, members, assignBy }
            }else{
                delete this.state.projectDetails['estimateTime']
                delete this.state.projectDetails['uor']
                data = {...this.state.projectDetails, members, assignBy }
            }
        }

        data.taskLists = this.state.taskLists.every(task => task.name == '' || task.name == null ) ? [] : this.state.taskLists

        if(this.state.completedTasksBackup.length){
            data.taskLists = data.taskLists.concat(this.state.completedTasksBackup)
        }

        if(this.state.addProject){
            // console.log("New Project Adding...")
            // console.log(data)
            this.setState({ addProjectLoader: true })
            await this.props.selfAddProjectToAdmin(data)
            if(this.props.projectAdd.response){
                toast.dismiss()
                toast.success( this.props.projectAdd.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
                this.setState({ addProjectLoader: false })
                await this.fetchFunctionsOnChange()
                this.handleResetStepForm()
            }else{
                toast.dismiss()
                toast.error( this.props.projectAdd.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
                this.setState({ addProjectLoader: false })
            }
        }
        if(this.state.editProject){
            // console.log("Existing Pro")
            // console.log(this.state.projectId, data)
            this.setState({ addProjectLoader: true })
            await this.props.selfEditProjectToAdmin(this.state.projectId, data)
            if(this.props.updatedProject.response){
                toast.dismiss()
                toast.success( this.props.updatedProject.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
                this.setState({ addProjectLoader: false })
                if(flag > 0){
                    this.notifyToAllMembersOnAccept(this.state.projectId, `${this.state.projectDetails['projectName']}`)
                }
                await this.fetchFunctionsOnChange()
                this.handleResetStepForm()
            }else{
                toast.dismiss()
                toast.error( this.props.updatedProject.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
                this.setState({ addProjectLoader: false })
            }
        }

    }

    handleResetStepForm = () => {
        let resetFields = {
            taskLists: [{ name: '' }],
            estimateHours:'',
            estimateMinutes:'',
            projectDetails:{
                projectName:'',
                estimateTime:'',
                uor:true,
                isClientContract:''
            },
            assignedMembers:[],
            completedTasksBackup:[],
            showProjectName: true,
            showIsClientContract: false,
            showEstimateTime:false,
            showUOR: false,
            showTaskList: false,
            showMemberStep: false,
            projectEditDetails: {},
            acceptProject: false,
            editProject: false, 
            addProject: true
        }
        this.setState({...resetFields})
    }

    convertDate = (date) => {
        var d = new Date(date);
        var year = (new Date().getFullYear() === d.getFullYear()) ? '' : ", " +  d.getFullYear()
        var monthName = d.toLocaleString('default', { month: 'short' })
        return  (monthName) + " " + d.getDate() +  year
    }

    // onCloseTaskModel = () => {
    //     this.setState({ openTaskModel: false , tasks:[]})
    // }
    onCloseTaskModel = () => {
        this.setState({ 
            openTaskModel: false , 
            tasks:[], 
            tasksSelectedForDel:[], 
            taskDelLoader: false, 
            selectedProjectIdForTaskDel:'', 
            task__projectName : '', 
            skipTasks: 20,
            isMoreTasks: true //set default to true
        })
    }

    onCloseMemberModel = () => {
        this.setState({ openMemberModel: false , showAssignedMembers:[]})
    }

    handleSearchInput = (e) => {
        const { value } = e.target;
        let projects = this.state.backupProjects;
        if(value.length > 0){
            projects  = projects.filter( p => {
                return p['projectName'].toLowerCase().match(value.toLowerCase()) || p['isClientContract'].toLowerCase().match(value.toLowerCase())
            })
            this.setState({ projects })
        }else{
            this.setState({ projects })
        }

        this.setState({ searchInput: value })
        // if(this.state.userRole === 'super_admin' || this.state.userRole === 'admin'){
        //     let allProjects = this.state.backupProjects;
        //     if(value.length > 0 ){
        //         allProjects  = allProjects.filter( p => {
        //             return p['projectName'].toLowerCase().match(value.toLowerCase())
        //         })
        //         this.setState({ allProjects })
        //     }else{
        //         this.setState({ allProjects })
        //     }
        // }else{
        //     let projects = this.state.backupProjects;
        //     if(value.length > 0){
        //         projects  = projects.filter( p => {
        //             return p['projectName'].toLowerCase().match(value.toLowerCase()) || p['isClientContract'].toLowerCase().match(value.toLowerCase())
        //         })
        //         this.setState({ projects })
        //     }else{
        //         this.setState({ projects })
        //     }
        // }
    }


    // onDeleteProject = ( id ) => {
    //     swal({
    //         title: "Are you sure?",
    //         text: `Once Deleted, Can't be Undo`,
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //       })
    //       .then(async (willDelete) => {
    //         if (willDelete) {
    //             swal({ title: "Keep Patience", text: "Deleting...", button: false });
    //             await this.props.deleteProject(id)
    //             if(this.props.deletedProject.response){
    //                 swal({ title: "Deleted!", text: this.props.deletedProject.message, icon: "success" })
    //                 if(this.state.selectedProjectType === 'p-inprogress'){
    //                     // console.log('is ............')
    //                     this.getAllProgressProjects()
    //                 }else if(this.state.selectedProjectType === 'p-onhold'){
    //                     this.getAllHoldProjects();
    //                 }else{
    //                     // console.log('sdsddddddddddddddddddd')
    //                     this.getAllCompletedProjects();
    //                 }
    //             }else{
    //                 swal({ title: "Something went wrong!", text: this.props.deletedProject.message, icon: "error" })
    //             }
    //         }
    //       });
    // }


    // onRemoveMemberFromProject = ( id, project ) => {
    //     // console.log("Removed From Project ", project)
    //     swal({
    //         title: "Are you sure?",
    //         text: `Once Deleted, Can't be Undo`,
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //       })
    //       .then(async (willDelete) => {
    //         if (willDelete) {
    //             swal({ title: "Keep Patience", text: "Deleting...", button: false });
    //             await this.props.removeMemberFromProject(id)
    //             if(this.props.removeMember.response){
    //                 this.sendNotification(project.assignedMember.map(m => m.assignTo._id), `You have been removed from project "${project.projectName}"`)
    //                 swal({ title: "Deleted!", text: this.props.removeMember.message, icon: "success" })
    //                 this.getAllProjectsOfMember();
    //             }else{
    //                 swal({ title: "Something went wrong!", text: this.props.removeMember.message, icon: "error" })
    //             }
    //         }
    //       });
    // }

    // -------------- Set Fields For Edit Project --------------- //
    onSetEditProjectDetails = () => {
        const { projectEditDetails } = this.state
        // console.log( projectEditDetails )
        
        let projectDetails = this.state.projectDetails
        projectDetails['projectName'] = projectEditDetails['projectName']
        projectDetails['isClientContract'] = projectEditDetails['isClientContract']
        projectDetails['uor'] = projectEditDetails['assignedMember'][0].uor ? true : false
        let taskLists = []
        let completedTasksBackup = []
        projectEditDetails['taskList'].forEach((task, id) => {
            let data  = {
                _id: task._id,
                name: task.taskName,
                memberId: task.member ? task.member : '',
                isCompleted: task.isCompleted
            }

            if(task.isCompleted){
                completedTasksBackup.push(data)
            }else{
                taskLists.push(data)
            }
        })
        let estimateHours = this.state.estimateHours;
        let estimateMinutes = this.state.estimateMinutes;

        if(this.state.userRole != 'super_admin' || this.state.userRole === 'admin'){
            if(projectEditDetails['assignedMember'][0].estimateTime){
                estimateHours = projectEditDetails['assignedMember'][0].estimateTime.split(':')[0];
                estimateMinutes = projectEditDetails['assignedMember'][0].estimateTime.split(':')[1];
            }
        }

        let assignedMembers = projectEditDetails['assignedMember'].map(x=> x.assignTo)
        let oldMembersListBeforeEdit = projectEditDetails['assignedMember'].map( x => x.assignTo._id)
        let projectId = projectEditDetails['_id'];
    
        // -----------  Replacing the Values to Edit a project  ----------- //
        this.setState({
            taskLists,
            completedTasksBackup,
            projectDetails,
            estimateHours,
            estimateMinutes,
            assignedMembers,
            oldMembersListBeforeEdit,
            projectId,
            // ----------  Show Initial Step ---------- //
            showProjectName: true,
            showEstimateTime:false,
            showUOR: false,
            showTaskList: false,
            showMemberStep: false,
        })

    } 


    // onChangeMemberToShowProjects  = async (e) => {
    //     this.setState({ allProjects: [] })
    //     if(e.target.value === 'all-projects'){
    //         // console.log("value check ----")
    //         this.setState({
    //             selectedMemberId:e.target.value
    //         })
    //        await this.getAllProgressProjects()
    //     }else{
    //         this.setState({
    //             selectedMemberId: e.target.value
    //         }, async ()=> await this.getAllProjectsOfMember())
    //     }
    // }

    checkMembersAccepted = (assignedMember) => {
        return assignedMember.filter(x => x.accepted === true).length;
    }

    changeDisplayProjectType = async (e) => {
        
        this.setState({ selectedProjectType: e.target.value, searchInput:'' })
        this.fetchProjectLoader(true)
        if(e.target.value === 'p-all-projects'){
            await this.get_UsersAllProjects(this.state.loggedInUserId)
        }else if(e.target.value === 'p-inprogress'){
            await this.getSingleUserProjects(this.state.loggedInUserId); // fetch In progress projects for logged in users
        }else if(e.target.value === 'p-onhold'){
            await this.getSingleUserHoldProjects(this.state.loggedInUserId);
        }else{
           await this.getSingleUserCompletedProjects(this.state.loggedInUserId)
        }
        
    }


    //  ------------------------------  Drag N Drop ----------------------------- //
    onDragEnd = result => {
        const { destination, source, reason } = result;
        if (!destination || reason === 'CANCEL') {
          this.setState({
            draggingRowId: null,
          });
          return;
        }
    
        if (
          destination.droppableId === source.droppableId &&
          destination.index === source.index
        ) {
          return;
        }
        const allProjects = Object.assign([], this.state.allProjects);
        const project = this.state.allProjects[source.index];
        allProjects.splice(source.index, 1);
        allProjects.splice(destination.index, 0, project);
        // console.log(allProjects)
        socket.emit('reorder-projects', { data: allProjects });
        this.setState({ allProjects });
    }

    reOrder = () => {
        const { reorderEnabled } = this.state
    
        this.setState({
          reorderEnabled: !reorderEnabled
        })
    }

    onDragStart = start => {
        const id = start.draggableId;
        const selected = this.state.selectedRowIds.find(selectedId => selectedId === id);
    
        // If dragging an item that is not selected, unselect all items
        if (!selected) {
          this.unselectAll();
        }
    
        this.setState({
          draggingRowId: start.draggableId,
        });
      }
    
    unselect = () => {
        this.unselectAll();
    }

    unselectAll = () => {
        this.setState({
            selectedRowIds: [],
        });
    }

    getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && ("#f9f9f9"),
        ...draggableStyle,
    })

    onWindowKeyDown = event => {
        if (event.defaultPrevented) {
          this.unselectAll();
        }
    
        if (event.key === `Escape`) {
          this.unselectAll();
        }
      }
    
      onWindowClick = event => {
        if (event.defaultPrevented) {
          return;
        }
        this.unselectAll();
      }
    
      onWindowTouchEnd = event => {
        if (event.defaultPrevented) {
          return;
        }
        this.unselectAll();
      }
    
      toggleSelection = rowId => () => {
        const selectedRowIds = this.state.selectedRowIds;
        const wasSelected = selectedRowIds.includes(rowId);
    
        const newRowIds = (() => {
          // Row was not previously selected, now will be the only selected row
          if (!wasSelected) {
            return [rowId];
          }
          // Row was part of a selected group of rows, will now become the only selected row
          if (selectedRowIds.length > 1) {
            return [rowId];
          }
          // Row was previously selected but not in a group, will now clear the selection
          return [];
        })();
    
        this.setState({
          selectedRowIds: newRowIds,
        });
      }
    //  ---------------------- Drag N Drop --------------------- //
    
    // handleSwitchChange = async (id, project) => {
    //     swal({
    //         title: "Are you sure?",
    //         text: `You want this project, moved to complete`,
    //         icon: "warning",
    //         buttons: ["Cancel", "Yes"],
    //         dangerMode: true,
    //       })
    //       .then(async (willMove) => {
    //         if (willMove) {
    //             await this.props.updateProjectStatus( id );
    //             if( this.props.updatedStatus.response ){
    //                 this.sendNotification(project.assignedMember.map(m => m.assignTo._id), `Project "${project.projectName}" status changed to Complete `)
    //                 if(this.state.selectedProjectType ==='p-inprogress'){
    //                     this.getAllProgressProjects()
    //                 }else if(this.state.selectedProjectType === 'p-onhold'){
    //                     this.getAllHoldProjects();
    //                 }else{
    //                     this.getAllCompletedProjects();
    //                 }
    //             }else{
    //                 swal({ title: "Oops!", text: this.props.updatedStatus.message, icon: "error" })
    //             }
    //         }
    //       });        
    // }

    // handleSwitchOnHold = async ( id, project ) => {
    //     // console.log("Project Details", project)
    //     swal({
    //         title: "Are you sure?",
    //         text: `You want this project, moved to hold`,
    //         icon: "warning",
    //         buttons: ["Cancel", "Yes"],
    //         dangerMode: true,
    //       })
    //       .then(async (willMove) => {
    //         if (willMove) {
    //             await this.props.changeProjectHoldStatus( id );
    //             if( this.props.holdStatus.response ){
    //                 this.sendNotification(project.assignedMember.map(m => m.assignTo._id), `Project "${project.projectName}" has been put on Hold `)
    //                 if(this.state.selectedProjectType === 'p-inprogress'){
    //                     this.getAllProgressProjects()
    //                 }else if(this.state.selectedProjectType === 'p-onhold'){
    //                     this.getAllHoldProjects();
    //                 }else{
    //                     this.getAllCompletedProjects();
    //                 }
    //             }else{
    //                 swal({ title: "Oops!", text: this.props.holdStatus.message, icon: "error" })
    //             }
    //         }
    //       });      
    // }

    // handleSwitchOnProgress = async ( id, project ) => {

    //     swal({
    //         title: "Are you sure?",
    //         text: `You want this project, moved to progress`,
    //         icon: "warning",
    //         buttons: ["Cancel", "Yes"],
    //         dangerMode: true,
    //       })
    //       .then(async (willMove) => {
    //         if (willMove) {
    //             await this.props.changeProjectProgressStatus( id );
    //             if( this.props.progressStatus.response ){
    //                 this.sendNotification(project.assignedMember.map(m => m.assignTo._id), `Project "${project.projectName}" status changed to "In Progress" `)
    //                 if(this.state.selectedProjectType === 'p-inprogress'){
    //                     this.getAllProgressProjects()
    //                 }else if(this.state.selectedProjectType === 'p-onhold'){
    //                     this.getAllHoldProjects();
    //                 }else{
    //                     this.getAllCompletedProjects();
    //                 }
    //             }else{
    //                 swal({ title: "Oops!", text: this.props.progressStatus.message, icon: "error" })
    //             }
    //         }
    //       });  
    // }

    //  ----------------- Only for Logged In User other than Admin ------- //
    onLoggedInUserProjectEdit = (project) => {
        this.upDivRef.scrollIntoView({behavior: "smooth"})
        this.handleResetStepForm()
        this.setState({ editProject: true, addProject: false, projectEditDetails: project }, () => this.onSetEditProjectDetails())
    }
    //  ----------------- Only for Logged In User other than Admin ------- //

    
    // ----------------------- Tasks Filter -------------------------- //

    getPendingTasks = (tasks) => {
        return tasks.filter(t => t.isCompleted === false )
    }

    getTodayCompletedTasks = (tasks) => {
        return tasks.filter(t => t.isCompleted === true ).filter(t => t.completedOn.split('T')[0] === new Date().toISOString().split('T')[0])
    }

    getYesterdayCompletedTasks = (tasks) => {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        return tasks.filter(t => t.isCompleted === true ).filter(t => t.completedOn.split('T')[0] === new Date(yesterday).toISOString().split('T')[0])
    }

    getPreviousCompletedTasks = (tasks) => {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        return tasks.filter(t => t.isCompleted === true ).filter(t => t.completedOn.split('T')[0] < new Date(yesterday).toISOString().split('T')[0])
    }

    // ---------------------- Tasks Filter --------------------------- //
    fetchFunctionsOnChange = async () => {
        const { selectedProjectType} = this.state
        if(selectedProjectType === 'p-all-projects'){
            await this.get_UsersAllProjects(this.state.loggedInUserId)
        }else if(selectedProjectType === 'p-inprogress'){
            await this.getSingleUserProjects(this.state.loggedInUserId); // fetch In progress projects for logged in users
        }else if(selectedProjectType === 'p-onhold'){
            await this.getSingleUserHoldProjects(this.state.loggedInUserId);
        }else{
           await this.getSingleUserCompletedProjects(this.state.loggedInUserId)
        }
        // if(userRole === 'super_admin' || userRole === 'admin'){
        //     if(selectedProjectType === 'p-inprogress'){
        //         if(selectedMemberId === 'all-projects'){
        //             await this.getAllProgressProjects()
        //         }else{
        //             await this.getAllProjectsOfMember()
        //         } 
        //     }else if(selectedProjectType === 'p-onhold'){
        //         if(selectedMemberId === 'all-projects'){
        //             await this.getAllHoldProjects()
        //         }else{
        //             await this.getSingleUserHoldProjects(selectedMemberId);
        //         }
        //     }else{
        //         if(selectedMemberId === 'all-projects'){
        //             await this.getAllCompletedProjects()
        //         }else{
        //             await this.getSingleUserCompletedProjects(selectedMemberId)
        //         }
        //     }
        // }else{
           
        // }
    }

    onDeleteTasks = (taskId) => {
        this.setState({ taskDelLoader: true })        
        swal({
            title: "Are you sure?",
            text: "Once deleted, Tasks won't be revert!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
                swal({ title: "Keep Patience", text: "Deleting...", button: false });
                let data = {
                    projectId: this.state.selectedProjectIdForTaskDel,
                    // tasksId: this.state.tasksSelectedForDel
                    tasksId: []  // Its used as an array because earlier the service was made for group deletion
                }
                data.tasksId.push(taskId)
                await this.props.deleteTasks(data)
                if(this.props.tasksDeleted.response){
                    await this.fetchFunctionsOnChange()
                    // await this.fetchFilterOnChange()
                    swal({ title: "Deleted!", text: this.props.tasksDeleted.message, icon: "success" })
                    // this.onCloseTaskModel()
                    this.setState({ 
                        tasks: this.state.tasks.filter(t => t._id.toString() !== taskId.toString()) // here after deleting tasks , filter to update tasks list untill modal is closed
                    })
                    // this.setState({ 
                    //     tasksSelectedForDel: [], 
                    //     // tasks: this.state.tasks.filter(pt => !data.tasksId.find(ti => (ti.toString() !== pt.toString() ) )) // here after deleting tasks , filter to update tasks list untill modal is closed
                    // })
                }else{
                    swal({ title: "Oops !", text: this.props.tasksDeleted.message, icon: "error" })
                }

            }
          });

          this.setState({ taskDelLoader: false})
    }

    
    onTasksSelect = (e) => {
        const { checked, value } = e.target
        // console.log(checked, value)
        let tasksSelectedForDel = this.state.tasksSelectedForDel;
        if(checked){
            tasksSelectedForDel.push(value);
        }else{
            tasksSelectedForDel = tasksSelectedForDel.filter(e => e !== value);
        }
        
        this.setState({
            tasksSelectedForDel
        })
    }

    handleScrollTasks = e => {
        let element = e.target
        if( Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) <= 1 
            && this.state.openTaskModel 
            && !this.state.loadingMoreTasks 
            && this.state.isMoreTasks
        ){
            this.onLoadMoreTasks()
        }
    }

    onLoadMoreTasks = async () => {
        var projectId = this.state.selectedProjectIdForTaskDel;
        var userId = this.state.loggedInUserId
        var skip = this.state.skipTasks
        this.setState({ loadingMoreTasks: true })
        await this.props.loadMoreTasks(projectId, userId, skip)
        this.setState({ loadingMoreTasks: false })
        if( this.props.moreTasks && this.props.moreTasks.response && this.props.moreTasks.tasks.length ){
            this.setState({
                tasks: this.state.tasks.concat(this.props.moreTasks.tasks),
                skipTasks: this.state.skipTasks + 20
            })
        }else{
            this.setState({ isMoreTasks: false })
        }
    }

    componentWillUnmount () {
        window.removeEventListener('click', this.onWindowClick);
        window.removeEventListener('click', this.onWindowClick);
        window.removeEventListener('click', this.onWindowClick);
    }

    render(){
        const {
            showProjectName,
            showMemberStep,
            showEstimateTime,
            showIsClientContract,
            showUOR,
            showTaskList,
            userRole,
            projects,
            allProjects,
            currentPage, 
            resultsPerPage,
            editProject,
            addProject,
            acceptProject,
            membersList,
            fetchProjectLoader,
            selectedProjectType,
            selectedRowIds,
            reorderEnabled,
            selectedMemberId,
            isTourOpen,
            showAllUsersProjects
        } = this.state
        const selected = selectedRowIds;
        // console.log(this.state.projectDetails)
        // console.log("Current Page ----->", currentPage)
        const pageNumbers = Math.ceil(projects.length/ resultsPerPage);
        // console.log(pageNumbers)
        // console.log(projects.length + 'ddddddddddddddddd' + allProjects.length)
        const offset = currentPage * resultsPerPage;
        const currentResults = projects.slice(offset, offset + resultsPerPage);
        // console.log(currentResults)
        const paginate = e => this.setState({currentPage:e.selected});
        if(showAllUsersProjects){
            return (
                <ProjectComponent/>
            )
        }else{
            return(  
                <>
                    <div className="list-group" ref={elem => this.upDivRef = elem}>
                        <div className="d-flex justify-content-between mb-3">
                            <h3 className="mb-2 mb-sm-0"> { editProject ?  acceptProject ?  'Accept Project By Filling Details' : 'Edit Project' : 'Add Project'} </h3>
                            <div className="dateSelect d-md-flex justify-content-lg-end admin-btn-div">
                                <Button className="admin-p-btn" onClick={()=> this.setState({ showAllUsersProjects: true })}> Show All Members Projects </Button> 
                                {editProject &&
                                    <Button className="ml-0 ml-sm-3" variant="primary" onClick={()=> {
                                        this.setState({ addProject: true, editProject: false, acceptProject: false })
                                        this.handleResetStepForm()
                                    }}> 
                                        { acceptProject ? 'Cancel' :  'Add Project' } 
                                    </Button>
                                }
                            </div>
                        </div>
                        { showProjectName && this.showProjectName() }
                        { showIsClientContract && this.showIsClientContract()}
                        { showMemberStep && this.showMemberStep()} 
                        { showEstimateTime && this.showEstimateTime() }
                        { showUOR && this.showUOR() }
                        { showTaskList && this.showTaskList() }
                    </div>
                    <div className="projectTable shadow-lg mt-4 p-3 animate__animated animate__fadeInUp first-step">
                        <div className="d-xl-flex justify-content-md-between mb-3">
                            <h3> 
                                My Projects 
                                <sup>
                                    <OverlayTrigger
                                    placement="auto"
                                    overlay={
                                        <Tooltip id={`tooltip-project`}>
                                            The project list is sorted according to priority. Please start working from the top of the list.
                                        </Tooltip>
                                    }
                                    >
                                        <i className ="fa fa-info-circle"/>
                                    </OverlayTrigger>
                                    {/* <i className="fa fa-info-circle" data-toggle="tooltip" data-placement="auto" title="The project list is sorted according to priority. Please start working from the top of the list."/> */}
                                </sup>
                            </h3>
                            <div className="dateSelect d-md-flex justify-content-lg-end justify-content-between align-items-center">
                                <Form inline className="ml-xl-5">
                                    <Form.Control as="select" id="projectType" className={`ml-3`} onChange={this.changeDisplayProjectType} value={selectedProjectType} custom>
                                        <option value="" disabled> Select In-progress/Completed</option>
                                        <option value="p-all-projects">View All</option>
                                        <option value="p-inprogress" style={{ color:"#ffbf00"}}> In Progress </option>
                                        <option value="p-onhold" style={{ color:"#227E9E"}}> On Hold </option>
                                        <option value="p-completed" style={{ color:"#42904e"}}> Completed </option>
                                    </Form.Control>
                                </Form>
                                <Form inline>
                                    <Form.Label className="my-1" htmlFor="inlineFormCustomSelectPref">
                                        Search
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="Search by Project or Client" value= {this.state.searchInput} onChange={this.handleSearchInput}/>
                                </Form>
                            </div>
                        </div>
                        <div className="table-responsive">
                            {fetchProjectLoader ?
                                <div className="text-center circleSpinnner"><Spinner animation="border" /></div>
                            : 
                            (projects && projects.length > 0) 
                                ?
                                // -------------- List to show Projects for Logged In Users --------------  //
                                <table className="table table-bordered table-striped">
                                    <thead>     
                                        <tr>
                                            <th className="border-right-0 first-child"> Sr. No. </th>
                                            <th className="border-right-0 border-left-0 six-child">Project Name</th>
                                            <th className="border-right-0 border-left-0">Client/Contract</th>
                                            <th className="border-right-0 border-left-0">Estimate Time</th>
                                            <th className="border-right-0 border-left-0 four-child"> Understood Requirements </th>
                                            <th className="border-right-0 border-left-0"> Assigned By </th>
                                            <th className="border-right-0 border-left-0"> Tasks </th>
                                            <th className="border-right-0 border-left-0"> Status </th>
                                            {(selectedProjectType === 'p-inprogress') && <th className="border-left-0 seven-child"> Action </th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(currentResults.length > 0  ? currentResults : projects).map((project,idx)=> {
                                            return (
                                                <tr key={idx}>
                                                    <td> { resultsPerPage * currentPage + (idx+1) } </td>
                                                    <td className="border-right-0 border-left-0">
                                                        {project.projectName}
                                                    </td>
                                                    <td className="border-right-0 border-left-0"> {project.isClientContract || 'N/A'} </td>
                                                    <td className="border-right-0 border-left-0"> 
                                                        { project.assignedMember[0].estimateTime 
                                                            ?
                                                            <span> { project.assignedMember[0].estimateTime } </span>
                                                            :
                                                            <span style={{color:'#e02626', fontWeight:'600'}}> Pending  </span>  
                                                        } 
                                                    </td>
                                                    <td className="border-right-0 border-left-0">
                                                        {(project.assignedMember[0].uor !== null)
                                                            ?
                                                            <>
                                                                {(project.assignedMember[0].uor === true) && <span> Yes </span>}
                                                                {(project.assignedMember[0].uor === false) && <span> No </span>}
                                                            </>
                                                            :
                                                            <span style={{color:'#e02626', fontWeight:'600'}}> Pending  </span>  
                                                        }
                                                        
                                                    </td>
                                                    <td className="border-right-0 border-left-0"><span>  { (project.assignedMember[0].assignBy && project.assignedMember[0].assignBy.fullname )? project.assignedMember[0].assignBy.fullname : '-'} </span></td>
                                                    <td className="border-right-0 border-left-0">
                                                        {project.taskList.length < 1 
                                                            ?
                                                            <span style={{color:'#e02626', fontWeight:'600'}}> Pending  </span>  
                                                            :
                                                            <Button variant="primary" disabled={project.taskList.length < 1} onClick={()=> this.setState({ openTaskModel: true, tasks:project.taskList, selectedProjectIdForTaskDel: project._id, task__projectName: project.projectName})}> View </Button>
                                                        }
                                                    </td>
                                                    <td className="border-right-0 border-left-0">
                                                        {project.isAdminAccepted 
                                                            ?
                                                            project.isCompleted
                                                                ?
                                                                    <span className="btn-status-completed"> Completed  </span>
                                                                :
                                                                    project.isOnHold
                                                                        ?
                                                                        <span className="btn-status-hold"> On hold  </span>
                                                                        :
                                                                        project.assignedMember[0].accepted 
                                                                            ?
                                                                            <span className="btn-status-progress"> In Progress  </span>
                                                                            :
                                                                            <Button 
                                                                                variant="success" 
                                                                                onClick={() => {
                                                                                    this.upDivRef.scrollIntoView({behavior: "smooth"})
                                                                                    this.handleResetStepForm()
                                                                                    this.setState({ editProject: true, acceptProject: true,  addProject: false, projectEditDetails: project }, () => this.onSetEditProjectDetails())                                                    
                                                                                }}
                                                                            > 
                                                                                Accept
                                                                            </Button>
                                                            :
                                                            <span style={{color:'#e02626', fontWeight:'600'}}> Pending  </span>  
                                                        }
                                                    </td>
                                                    {(selectedProjectType === 'p-inprogress') &&
                                                    <td className="border-left-0">
                                                        <Button variant="primary"
                                                            disabled = {
                                                                project.isAdminAccepted
                                                                ?
                                                                    project.isOnHold
                                                                    ?
                                                                        true
                                                                    :
                                                                        project.assignedMember[0].accepted 
                                                                            ?
                                                                            false
                                                                            :
                                                                            true
                                                                :
                                                                true
                                                            } 
                                                            onClick={() => 
                                                                project.isOnHold
                                                                ?
                                                                null
                                                                :
                                                                project.assignedMember[0].accepted 
                                                                    ?
                                                                        this.onLoggedInUserProjectEdit(project)   
                                                                    :
                                                                    null
                                                                                                                    
                                                            }>
                                                                Edit 
                                                        </Button>
                                                    </td>}
                                                </tr>
                                            )
                                        })}
                                    </tbody>    
                                </table>
                                :
                                <p className="text-center"> No Project To Show </p>
                            
                            }
                        </div>
                        {(pageNumbers > 1 && !fetchProjectLoader) &&
                        <section className="pagination">
                            <ReactPaginate
                                previousLabel={'<<'}
                                nextLabel={'>>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pageNumbers}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={paginate}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </section>}
                    </div>
                    <Modal id="TaskModel" show = { this.state.openTaskModel } onHide={ this.onCloseTaskModel }>
                        <Modal.Header closeButton>
                            <Modal.Title>Task Status For Project : "{this.state.task__projectName}" </Modal.Title>
                        </Modal.Header>
                        <Modal.Body onScroll={this.handleScrollTasks}>
                            <h5 className="mt-1 text-warning">Pending Tasks</h5>
                            {/* <div className="d-lg-flex justify-content-md-between mb-1">
                                <div className="d-md-flex justify-content-lg-end justify-content-between">
                                    {(this.state.tasksSelectedForDel && this.state.tasksSelectedForDel.length > 0) && <Button className="ml-2" variant="danger" size="sm" disabled={ this.state.taskDelLoader } onClick={()=> this.onDeleteTasks() }> Delete </Button>} 
                                </div>
                            </div> */}
                            {this.getPendingTasks(this.state.tasks).length > 0 
                                ?   <Row className="align-items-center">
                                    <Col sm={12}>
                                        <div className="table-responsive ">
                                            <table className="table table-bordered table-striped">
                                                <tr>
                                                    <th className="border-right-0"> Sr. No.</th>
                                                    <th className="border-right-0 border-left-0">Task Name</th>
                                                    <th className="border-right-0 border-left-0">Status</th>
                                                    <th className="border-left-0">Assigned On</th>
                                                    <th className="border-left-0">Action</th>
                                                </tr>
                                                {this.getPendingTasks(this.state.tasks).map((task, idx) => {
                                                    return(
                                                        <tr key={idx}>
                                                            <td> {idx+1}</td>
                                                            <td className="border-right-0 border-left-0">
                                                                {task.taskName || 'N/A'}
                                                            </td>
                                                           
                                                            <td className="border-right-0 border-left-0">
                                                                {!task.isCompleted  ?
                                                                        <span className="text-warning">Pending</span>
                                                                    :
                                                                    <span className="text-success">Completed</span>
                                                                }
                                                            </td>
                                                            <td>
                                                                {this.convertDate(task.createdAt) || 'N/A'}
                                                            </td>
                                                            <td>
                                                                <OverlayTrigger
                                                                    placement="auto"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-project_del`}>
                                                                            Click to delete
                                                                        </Tooltip>
                                                                    }
                                                                    >
                                                                    {/* <Form.Check inline className="ml-2 mt-2" type="checkbox" id={`inlineCheck`} onChange={this.onTasksSelect} value={task._id}/> */}
                                                                    <Button variant="danger" size="sm" disabled={ this.state.taskDelLoader } onClick={()=> this.onDeleteTasks(task._id) }> Delete </Button>
                                                                </OverlayTrigger>
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </div>
                                    </Col>        
                                    </Row>
                                :   <Alert variant="info"> Nothing to show </Alert>
                            }
                            <h5 className="mt-3 text-success">Today Completed Tasks</h5>
                            {this.getTodayCompletedTasks(this.state.tasks).length > 0 
                                ?   <Row className="align-items-center">
                                    <Col sm={12}>
                                        <div className="table-responsive ">
                                            <table className="table table-bordered table-striped">
                                                <tr>
                                                    <th className="border-right-0"> Sr. No.</th>
                                                    <th className="border-right-0 border-left-0">Task Name</th>
                                                    <th className="border-right-0 border-left-0">Status</th>
                                                    <th className="border-right-0 border-left-0">Assigned On</th>
                                                    <th className="border-left-0">Completed On</th>
                                                </tr>
                                                {this.getTodayCompletedTasks(this.state.tasks).map((task, idx) => {
                                                    return(
                                                        <tr key={idx}>
                                                            <td> {idx+1}</td>
                                                            <td className="border-right-0 border-left-0">
                                                                {task.taskName || 'N/A'}
                                                            </td>
                                                           
                                                            <td className="border-right-0 border-left-0">
                                                                {!task.isCompleted  ?
                                                                        <span className="text-warning">Pending</span>
                                                                    :
                                                                    <span className="text-success">Completed</span>
                                                                }
                                                            </td>
                                                            <td className="border-right-0 border-left-0">
                                                                {this.convertDate(task.createdAt) || 'N/A'}
                                                            </td>
                                                            <td>
                                                                {this.convertDate(task.completedOn) || 'N/A'}
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </div>
                                    </Col>        
                                    </Row>
                                :   <Alert variant="info"> Nothing to show </Alert>
                            }
                            <h5 className="mt-3 text-success">Yesterday Completed Tasks</h5>
                            {this.getYesterdayCompletedTasks(this.state.tasks).length > 0 
                                ?   <Row className="align-items-center">
                                    <Col sm={12}>
                                        <div className="table-responsive ">
                                            <table className="table table-bordered table-striped">
                                                <tr>
                                                    <th className="border-right-0"> Sr. No.</th>
                                                    <th className="border-right-0 border-left-0">Task Name</th>
                                                    <th className="border-right-0 border-left-0">Status</th>
                                                    <th className="border-right-0 border-left-0">Assigned On</th>
                                                    <th className="border-left-0">Completed On</th>
                                                </tr>
                                                {this.getYesterdayCompletedTasks(this.state.tasks).map((task, idx) => {
                                                    return(
                                                        <tr key={idx}>
                                                            <td> {idx+1}</td>
                                                            <td className="border-right-0 border-left-0">
                                                                {task.taskName || 'N/A'}
                                                            </td>
                                                           
                                                            <td className="border-right-0 border-left-0">
                                                                {!task.isCompleted  ?
                                                                        <span className="text-warning">Pending</span>
                                                                    :
                                                                    <span className="text-success">Completed</span>
                                                                }
                                                            </td>
                                                            <td className="border-right-0 border-left-0">
                                                                {this.convertDate(task.createdAt) || 'N/A'}
                                                            </td>
                                                            <td>
                                                                {this.convertDate(task.completedOn) || 'N/A'}
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </div>
                                    </Col>        
                                    </Row>
                                :   <Alert variant="info"> Nothing to show </Alert>
                            }
                            <h5 className="mt-3 text-success">Previous Completed Tasks</h5>
                            {this.getPreviousCompletedTasks(this.state.tasks).length > 0 
                                ?   <Row className="align-items-center mb-3">
                                    <Col sm={12}>
                                        <div className="table-responsive ">
                                            <table className="table table-bordered table-striped">
                                                <tr>
                                                    <th className="border-right-0"> Sr. No.</th>
                                                    <th className="border-right-0 border-left-0">Task Name</th>
                                                    <th className="border-right-0 border-left-0">Status</th>
                                                    <th className="border-right-0 border-left-0">Assigned On</th>
                                                    <th className="border-left-0">Completed On</th>
                                                </tr>
                                                {this.getPreviousCompletedTasks(this.state.tasks).map((task, idx) => {
                                                    return(
                                                        <tr key={idx}>
                                                            <td> {idx+1}</td>
                                                            <td className="border-right-0 border-left-0">
                                                                {task.taskName || 'N/A'}
                                                            </td>
                                                           
                                                            <td className="border-right-0 border-left-0">
                                                                {!task.isCompleted  ?
                                                                        <span className="text-warning">Pending</span>
                                                                    :
                                                                    <span className="text-success">Completed</span>
                                                                }
                                                            </td>
                                                            <td className="border-right-0 border-left-0">
                                                                {this.convertDate(task.createdAt) || 'N/A'}
                                                            </td>
                                                            <td>
                                                                {this.convertDate(task.completedOn) || 'N/A'}
                                                            </td>
                                                            
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </div>
                                    </Col>        
                                    </Row>
                                :   <Alert variant="info" className="mb-3"> Nothing to show </Alert>
                            }
                            {this.state.loadingMoreTasks 
                            ?   <div className="d-md-flex justify-content-center"> loading...</div>
                            :   null
                            }
                        </Modal.Body>
                    </Modal>
                    <Modal id="MembersModel" show = { this.state.openMemberModel } onHide={ this.onCloseMemberModel }>
                        <Modal.Header closeButton>
                            <Modal.Title> Assigned Members </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="align-items-center">
                                <Col sm={12}>
                                    <div className="table-responsive ">
                                        <table className="table table-bordered table-striped">
                                            <tr>
                                                <th className="border-right-0 first-child"> Sr. No. </th>
                                                <th className="border-right-0 border-left-0">Member Name</th>
                                                <th className="border-right-0 border-left-0">Assigned By</th>
                                                <th className="border-right-0 border-left-0 four-child"> Understood Requirements </th>
                                                <th className="border-right-0 border-left-0"> Estimate Time </th>
                                                <th className="border-right-0 border-left-0"> Status </th>
                                                <th className="border-left-0">Assigned On</th>
                                            </tr>
                                            {this.state.showAssignedMembers.map((member, idx) => {
                                                return(
                                                    <tr key={idx}>
                                                        <td> {idx+1} </td>
                                                        <td className="border-right-0 border-left-0">
                                                            {member.assignTo.fullname || 'N/A'}
                                                        </td>
                                                        <td className="border-right-0 border-left-0">
                                                            {member.assignBy.fullname || 'N/A'}
                                                        </td>
                                                        <td className="border-right-0 border-left-0">
                                                            {(member.uor !== null)
                                                                ?
                                                                <>
                                                                    {(member.uor === true) && <span> Yes </span>}
                                                                    {(member.uor === false) && <span> No </span>}
                                                                </>
                                                                :
                                                                <span style={{color:'#e02626', fontWeight:'600'}}> Pending  </span>  
                                                            }
                                                        </td>
                                                        <td className="border-right-0 border-left-0">
                                                            { member.estimateTime 
                                                                ?
                                                                <span> { member.estimateTime } </span>
                                                                :
                                                                <span style={{color:'#e02626', fontWeight:'600'}}> Pending  </span>  
                                                            } 
                                                        </td>
                                                        <td className="border-right-0 border-left-0">
                                                            {member.accepted  ?
                                                                <span style={{color:'#42904e', fontWeight:'600'}}> Accepted  </span>  
                                                                :
                                                                <span style={{color:'#e02626', fontWeight:'600'}}> Pending  </span>  
                                                            }
                                                        </td>
                                                        <td>
                                                            {this.convertDate(member.createdAt) || 'N/A'}
                                                        </td>
                                                        
                                                    </tr>
                                                )
                                            })}
                                        </table>
                                    </div>
                                </Col>        
                            </Row>
                        </Modal.Body>
                    </Modal>
                </>
            )  
        }
    }
}


const mapStateToProps = state => {
    const { project, auth, member } = state
    return {
        projectAdd : project.projectAdd,
        projectFetch:project.projectFetch,
        auth: auth,
        membersList: member.member,
        deletedProject: project.projectDelete,
        updatedProject: project.projectEdit,
        completedProject: project.completedFetch,
        progressProject: project.progressFetch,
        removeMember: project.memberRemoved,
        updatedStatus: project.updatedStatus,
        holdStatus: project.holdStatus,
        holdProject: project.holdFetch,
        progressStatus: project.progressStatus,
        projectMembers :  project.members,
        tasksDeleted: project.deleteTasks,
        usersAllProjects: project.userAllProjects,
        moreTasks: project.moreTasksLoaded,
    }
}
const  dispatchAction =  { 
    fetchMembers, 
    selfAddProjectToAdmin,
    fetchHoldProjectsOfMember, 
    fetchCompletedProjectsOfMember, 
    fetchSingleUserProjects, 
    updateProjectStatus, 
    fetchAllProjectsOfMember, 
    deleteProject, 
    selfEditProjectToAdmin, 
    fetchCompletedProjects, 
    fetchAllInprogressProjects, 
    removeMemberFromProject, 
    changeProjectHoldStatus, 
    fetchHoldProjects, 
    changeProjectProgressStatus, 
    getAssignMembers,  
    getUsersAllProjects, 
    deleteTasks,
    loadMoreTasks 
}

export default connect(mapStateToProps, dispatchAction)(ShowOwnsProjectsComponent)