import openSocket from "socket.io-client";
import config from '../helpers/config'
import { toast } from "react-toastify";

toast.configure()

var BaseURL = config.apiBaseURL;

var BaseURL2 = config.apiBaseURL2;
export const socket = openSocket(BaseURL2, {transports:['polling']});

export const apiRequest = async ( url, requestOptions ) => {
    try {
        const response = await fetch( BaseURL + url, requestOptions);
        const result = await response.json();
        if(result.response === false && result.accountDisabled === true ){
            localStorage.removeItem('web1expertsToken');
            setTimeout(()=>{
                window.location.replace('/login')
            }, 1500)
        }
        return result;
    } catch (e) {
        throw e.response
    }

}

export const apiRequest_2 = async ( url, requestOptions ) => {
    try {
        const response = await fetch( BaseURL2 + url, requestOptions);
        const result = await response.json();
        if(result.response === false && result.accountDisabled === true ){
            localStorage.removeItem('web1expertsToken');
            setTimeout(()=>{
                window.location.replace('/login')
            }, 1500)
        }
        return result;
    } catch (e) {
        throw e.response
    }

}

export const checkTokenExpire = (result) => {
    if(result && result.error && result.error.name === 'TokenExpiredError'){
        localStorage.removeItem('web1expertsToken');
        toast.dismiss()
        toast.error("Session Time Out", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        setTimeout(()=>{
            window.location.replace('/login')
        }, 1200)
    }
}