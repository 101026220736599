import { 
    GET_INDIVIDUAL_NOTE, GET_PUBLIC_NOTE
} from "../actions/types";

const initialState = { getIndividualResponse:'', getPublicResponse:'' };

export default (state = initialState, action) => {
    switch (action.type) {
    case GET_INDIVIDUAL_NOTE:
        return {
            ...state,
            getIndividualResponse: action.payload
        };
    case GET_PUBLIC_NOTE:
        return {
            ...state,
            getPublicResponse: action.payload
        }
    default:
        return state
    }
}
