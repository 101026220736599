import React from 'react';
import './stylesheets/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Layout from './containers/Layout';
import Login from './components/Login/LoginComponent';
import { Route, Switch } from 'react-router-dom';

function App() {
  return (
    <Switch>
      <Route exact path="/login" name="Login Page" render={ props => <Login {...props}/> } />
      <Route path="/" name="Layout" render={ props => <Layout {...props}/> } />
    </Switch>
  );
}

export default App;
