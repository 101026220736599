import { ADD_HOLIDAY, EDIT_HOLIDAY, FETCH_HOLIDAY, DELETE_HOLIDAY } from "../actions/types";

const initialState = { holiday: '', addHoliday:'', editHoliday:'', deleteHoliday:'' }

export default ( state = initialState, action ) => {

    switch (action.type) {
        case ADD_HOLIDAY:
            return {
                ...state,
                addHoliday: action.payload
            }
        case EDIT_HOLIDAY:
            return {
                ...state,
                editHoliday: action.payload
            }
        case FETCH_HOLIDAY:
            return {
                ...state,
                holiday: action.payload
            }
        case DELETE_HOLIDAY:
            return {
                ...state,
                deleteHoliday: action.payload
            }
        default: return state;
    }
}