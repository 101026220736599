import React, { Component } from 'react';
import { Button, Col, Form, FormControl, InputGroup, Modal, Row, Spinner, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchPerformance, editPerformance } from '../../actions/performanceAction';
import { fetchMembers } from '../../actions/memberAction';
import PerformanceComponent from './PerformanceComponent';
// import { fetchUser } from '../../actions/userAction';
// import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
toast.configure()

class PerformanceForAdminComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            reportMonth:new Date(),
            userRole:'',
            identifying:'',
            permissions:{},
            membersList:[],
            membersListLoader: false,
            performance:[],
            backupPerformance:[],
            userId:'',
            memberId:'',
            userRoleForPerformance:'',
            performanceLoader: true,
            onMemberChangeShowTable: false,
            editPerformanceLoader: false,
            singlePerformanceData:{},
            //  ---- Performance Edit ---//
            openEditPerformanceModel: false,
            averagePerformance:0,
            averageAttendance:0,
            ShowPerformance: false
        }
    }

    componentDidMount(){
        if(this.props.auth && this.props.auth.loggedIn){
            if(this.props.auth.user && this.props.auth.user.permissions && this.props.auth.user.permissions.performance){
                this.setState({ 
                    userRole: this.props.auth.user.role, 
                    permissions:{...this.props.auth.user.permissions.performance},
                    userId: this.props.auth.user.userId,
                    identifying: this.props.auth.user.identifying,
                }, async ()=> {
                    await this.setUserRoleForOthersuser(this.state.identifying)
                    await this.getUserOwnPerformance(this.state.userId)
                    this.onChangePerformanceMonth(this.state.reportMonth)
                })
            }
        }
    }

    //  --------------  It is used to store userRole is SEO/Bidder/Hr/Qa to show specific column
    setUserRoleForOthersuser = (role) => {
        this.setState({ userRoleForPerformance: role})
    }

    // getUserDetails = async ( userId ) => {
    //     await this.props.fetchUser(userId);
    //     this.
    // }
 
    onChangePerformanceMonth = (date) => {
        this.setState({ reportMonth: date })
        let performance =  this.state.backupPerformance;
        let i = 0
        performance = performance.filter( p => {
            // console.log('i --->', i++,'Month ---> ', new Date(p['createdAt']).getMonth()+1, new Date(date).getMonth()+1, ' Year ---> ', new Date(p['createdAt']).getFullYear(), new Date(date).getFullYear() )
            return (new Date(p['createdAt']).getMonth()+1 === new Date(date).getMonth()+1) && (new Date(p['createdAt']).getFullYear() === new Date(date).getFullYear())
        })

        let averagePerformance = performance.length ? performance[0].currentMonthPerformance : 0
        let averageAttendance = performance.length ? performance[0].currentMonthAttendance.toFixed(2) : 0
        this.setState({ performance, averagePerformance, averageAttendance })
    }

    getUserOwnPerformance = async ( userId ) => {
        this.setState({ performanceLoader: true })
        await this.props.fetchPerformance( userId );
        // console.log(this.props.userPerformance)
        if(this.props.userPerformance.response){
            this.setState({
                performance: this.props.userPerformance.performance,
                averagePerformance: this.props.userPerformance.performance.length ? this.props.userPerformance.performance[0].currentMonthPerformance : 0,
                averageAttendance: this.props.userPerformance.performance.length ? this.props.userPerformance.performance[0].currentMonthAttendance.toFixed(2) : 0,
                backupPerformance: this.props.userPerformance.performance,
                performanceLoader: false
            })
        }else{
            toast.dismiss()
            toast.error(this.props.userPerformance.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ performanceLoader: false })
        }
    }

    convertDate = (date) => {
        // console.log(new Date(date).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'}))
        var d = new Date(date);
        var year = (new Date().getFullYear() === d.getFullYear()) ? '' : ", " +  d.getFullYear()
        var monthName = d.toLocaleString('default', { month: 'long' })
        var weekDays = d.toLocaleString('default', { weekday:'long'})
        let dt = d.getDate() < 10 ? '0'+ d.getDate() : d.getDate()
        return  weekDays + ", " + dt + " " + (monthName) +  year
    }


    render(){
        const { reportMonth, ShowPerformance, averagePerformance, userRoleForPerformance, performanceLoader, userRole, permissions, editPerformanceLoader, singlePerformanceData, membersList, performance, openEditPerformanceModel, onMemberChangeShowTable } = this.state
        let performanceHighlight = ''
        let fontColor = ''

        if( averagePerformance >= 90){
            performanceHighlight = '#5bba09'  // --  green
            fontColor = '#ffffff'               // -- white
        }else if( averagePerformance <= 89 && averagePerformance >= 70){
            performanceHighlight = '#f7ef11' // -- yellow
            fontColor = '#000000'      // --- black
        }else{
            performanceHighlight = '#d31034' // -- red
            fontColor = '#ffffff'      // --- white
        }

        if(ShowPerformance){
            return (<PerformanceComponent/>)
        }else{
            return(  
                <>
                    <div className="d-lg-flex justify-content-md-between mb-3">
                        <h3 className="m-0">My Performance</h3>
                       
                        <div className="dateSelect d-md-flex justify-content-lg-end justify-content-between">
                            <Form inline>
                                <Form.Label htmlFor="selectMonth" className="mr-2">
                                    Select Month: 
                                </Form.Label>
                                <div className="relative">
                                <DatePicker
                                    dateFormat="MMMM yyyy"
                                    showMonthYearPicker
                                    selected={this.state.reportMonth}
                                    onChange={this.onChangePerformanceMonth}
                                />
                                </div>
                            </Form>
                            <Button className="ml-2 mr-2" onClick={()=> this.setState({ ShowPerformance: true })}> All Member Performance </Button>
                        </div>            
                    </div>
                    {performanceLoader 
                    ?
                        <div className="text-center circleSpinnner"><Spinner animation="border" /></div>
                    :
                    <>    
                    { (performance && performance.length > 0) && 
                    <Row className="mt-2">
                        <Col sm={6}>
                            <h5> 
                                <span 
                                style={{
                                    backgroundColor: performanceHighlight,
                                    color: fontColor, 
                                    padding:'0.25rem 0.5rem', 
                                    borderRadius:'5px'
                                }}>  
                                Performance: <span className="ml-2"> { this.state.averagePerformance }% </span> 
                                </span> 
                            </h5> 
                        </Col>
                        <Col sm={6} className="text-md-right">
                            <h5>Attendance: <span className="ml-2"> { this.state.averageAttendance }% </span> </h5> 
                        </Col>
                    </Row>}
                    <div className="projectTable shadow-lg mt-2 p-3 animate__animated animate__fadeInUp">
                        {(performance && performance.length > 0)
                            ?              
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <tr>
                                            <th className="four-child">Date</th>
                                            <th className="two-child text-center">Status</th>
                                            <th className="three-child text-center">Punctuality</th>
                                            <th className="text-center"> Project Understanding</th>
                                            <th className="text-center"> Repetations </th>
                                            <th className="six-child text-center"> Time Consume  </th>
                                            <th className="five-child text-center">Quality Work</th>
                                            <th className="six-child text-center"> Task Completetion </th>
                                            <th className="seven-child text-center">Reporting</th>
                                            <th className="five-child text-center">Behaviour</th>
                                            <th className="text-center"> Responsibility  </th>
                                            {(userRoleForPerformance === 'seo' || userRoleForPerformance === 'hr' || userRoleForPerformance === 'bidders' || userRoleForPerformance === 'qualityAuditor') &&
                                            <th className="text-center"> Conversion  </th>
                                            }
                                            <th className="eight-child text-center">Total</th>
                                        </tr>
                                        
                                            <>
                                            {performance.map((performance, idx ) => {
                                                let st = false
                                                if(performance.status === 'W' || performance.status === 'O' || performance.status === 'OW' || performance.status === 'A'){
                                                    st  = true
                                                }
                                                return (
                                                    <tr key={idx}>
                                                        <td> { this.convertDate(performance.createdAt) } </td>
                                                        {/* <td> { performance.createdAt.split('T')[0] } </td> */}
    
                                                        <td className="text-center"> 
                                                            { (performance.status === 'A') && 'Absent' }  
                                                            { (performance.status === 'P') && 'Present' }
                                                            { (performance.status === 'S') && 'Short Leave' } 
                                                            { (performance.status === 'H') && 'Half Day' }
                                                            { (performance.status === 'T') && 'Two Hours' }
                                                            { (performance.status === 'W') && 'Weekly Off' }
                                                            { (performance.status === 'O') && 'Holiday' }
                                                            { (performance.status === 'OW') && 'Weekly Off' }
    
                                                        </td>
                                                        <td className="text-center">
                                                            {st ? 'N/A' : performance.punctuality}
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.uor}
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.repetation}
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.timeConsume}
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.qualityWork}
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.taskCompletion }
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.reports}
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.behaviour }
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.responsibility }
                                                        </td>
                                                        {(userRoleForPerformance === 'seo' || userRoleForPerformance === 'hr' || userRoleForPerformance === 'bidders' || userRoleForPerformance === 'qualityAuditor') &&
                                                            <td className="text-center">    
                                                                {st ? 'N/A' : performance.conversion }
                                                            </td>
                                                        }
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : `${performance.total}%` }
                                                        </td>                                                    
                                                    </tr>
                                                )
                                            })}                                
                                            </>                             
                                    </table>
                                </div>
                                :
                                <p className="m-0 p-2 text-center border">
                                    Nothing To Show
                                </p>
                        }                        
                    </div>  
                    </>
                       
                    }
                    <div className="projectTable shadow-sm bg-white mt-4 text-center p-2 animate__animated animate__fadeInUpBig">
                        <Card className="mb-0">
                            <Card.Body >
                                {/* <strong> Salary Calculation Formula = Total Salary / Total Working Days * Number of Working Days * Overll Performance / 100 </strong> */}
                                <strong> Salary Calculation Formula = Total Salary * Attendance Percentage * Performance Percentage / 10000 </strong>
                            </Card.Body>
                        </Card>
                    </div>
                </>
            )
        }
    }
}


const mapStateToProps = state => {
    const { performance, auth, member } = state
    return {
        userPerformance : performance.performance,
        auth: auth,
        membersList: member.member,
        ePerformance: performance.editPerformance
    }
}
const  dispatchAction =  { fetchPerformance, fetchMembers, editPerformance }
export default connect(mapStateToProps, dispatchAction)(PerformanceForAdminComponent)