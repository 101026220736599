import { 
    FETCH_USER,

} from "../actions/types";

const initialState = {user:'', updateHeader:''};

export default (state = initialState, action) => {
    switch (action.type) {
    case FETCH_USER:
        return {
            ...state,
            user: action.payload
        };

    case 'CUSTOME_ACTION_TO_UPDATE_HEADER':
        return {
            ...state,
            updateHeader:action.payload
        }    
    default:
        return state
    }
}
