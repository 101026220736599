import { FETCH_NOTIFICATIONS } from './types';
import { apiRequest, checkTokenExpire } from './actionResponseHandler';
import authHeader from '../helpers/auth-header'
import { toast } from "react-toastify";
toast.configure()


export const fetchNotifications = userId => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            }    
        }
        const result = await apiRequest( `/notifications/${userId}`, requestOptions );
        // checkTokenExpire(result)
        await dispatch({ type: FETCH_NOTIFICATIONS, payload: result })
    } catch (error) {
        toast.dismiss()
        toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        // if(error && error.status === 401){
        //     checkTokenExpire(error.data)
        // }else{
        //     toast.dismiss()
        //     toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        // }
    }
}