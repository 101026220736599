import { ADD_TUTORIAL, FETCH_ROLE_TUTORIALS, EDIT_TUTORIAL, DELETE_TUTORIAL } from './types';
import { apiRequest, checkTokenExpire } from './actionResponseHandler';
import authHeader from '../helpers/auth-header'
import { toast } from "react-toastify";
toast.configure()

export const createTutorial = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
            body: JSON.stringify(data)
        }
        const result = await apiRequest( "/tutorial/add", requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: ADD_TUTORIAL, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}
export const fetchRoleTutorials = role => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            }    
        }
        const result = await apiRequest( `/tutorials/${role}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: FETCH_ROLE_TUTORIALS, payload: result })
    } catch (error) {
        // console.log(error)
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const fetchAllTutorials = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            }    
        }
        const result = await apiRequest( `/tutorials`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: FETCH_ROLE_TUTORIALS, payload: result })
    } catch (error) {
        // console.log(error)
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const updateTutorial = (id, data) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
            body: JSON.stringify(data)    
        }
        const result = await apiRequest(`/tutorial/edit/${id}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: EDIT_TUTORIAL, payload: result })
    } catch (error) {
        // console.log(error)
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const deleteTutorial = (id) => async dispatch => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },   
        }
        const result = await apiRequest(`/tutorial/delete/${id}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: DELETE_TUTORIAL, payload: result })
    } catch (error) {
        // console.log(error)
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}


