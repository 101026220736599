import React, { Component } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import {Row, Col, Container} from 'react-bootstrap';
import ROUTES, { RenderRoutes } from "../routing/Routes";


class Layout extends Component{
    
    constructor(props){
        super(props)
        // console.log(props)
        this.state = {
            sidebarOpen: false
        }
    }

    handleViewSidebar = () => {
        this.setState({sidebarOpen: !this.state.sidebarOpen});
    }

    showButton = () => {
        return (
            <button className="panelToggle" type="button" onClick={()=> this.handleViewSidebar()}> <i className={`fa ${this.state.sidebarOpen ? 'fa-times': 'fa-bars'}`}></i></button>
        )
    }

    render(){
        return (
            <div id="wrapper">
                <Header showButton={this.showButton}/> 
                <section className="default_sec">
                    <Container fluid>
                        <Row>
                            <Col sm={12}>
                                <div className="mainWrapper">
                                     <Sidebar isOpen={this.state.sidebarOpen} handleViewSidebar={this.handleViewSidebar}/>
                                    <div className="tab-content animate__animated animate__fadeIn" id="myTabContent">
                                        <RenderRoutes routes={ROUTES} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        )
    }
}

export default Layout;