import {
    ADD_PROJECT, 
    FETCH_PROJECTS,
    EDIT_PROJECT,
    DELETE_PROJECT,
    FETCH_COMPLETED_PROJECTS,
    FETCH_INPROGRESS_PROJECTS,
    DELETE_MEMBER_FROM_PROJECT,
    UPDATE_PROJECT_STATUS,
    CHANGE_HOLD_STATUS,
    FETCH_HOLD_PROJECTS,
    CHANGE_PROGRESS_STATUS,
    ACCEPT_PROJECT_BY_USER,
    GET_ASSIGN_PROJECT_MEMBER,
    GET_SINGLE_PROJECT,
    GET_UNASSIGNED_INPROGRESS_PROJECTS,
    GET_UNASSIGNED_HOLD_PROJECTS,
    GET_UNASSIGNED_COMPLETED_PROJECTS,
    GET_UNASSIGNED_ALL_PROJECTS,
    DELETE_TASKS,
    GET_ALL_PROJECTS,
    GET_ALL_PROJECTS_OF_USER,
    LOAD_MORE_TASKS
} from './types';
import { apiRequest, checkTokenExpire } from './actionResponseHandler';
import authHeader from '../helpers/auth-header'
import { toast } from "react-toastify";
toast.configure()

export const addProject = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
            body: JSON.stringify(data)
        }
        const result = await apiRequest( "/project/add", requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: ADD_PROJECT, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const selfAddProjectToAdmin = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
            body: JSON.stringify(data)
        }
        const result = await apiRequest( "/project/admin/self/add/", requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: ADD_PROJECT, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const editProject = (projectId, data) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
            body: JSON.stringify(data)
        }
        const result = await apiRequest(`/project/edit/${projectId}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: EDIT_PROJECT, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const selfEditProjectToAdmin = (projectId, data) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
            body: JSON.stringify(data)
        }
        const result = await apiRequest(`/project/admin/self/edit/${projectId}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: EDIT_PROJECT, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const fetchSingleUserProjects = userId =>  async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/project/${userId}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: FETCH_PROJECTS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const fetchAllProjectsOfMember = (userId) =>  async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/projects/${userId}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: FETCH_PROJECTS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const fetchHoldProjectsOfMember = (userId) =>  async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/project/${userId}/hold`, requestOptions );
        // console.log(result)
        checkTokenExpire(result)
        await dispatch({ type: FETCH_HOLD_PROJECTS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const fetchCompletedProjectsOfMember = (userId) =>  async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/project/${userId}/complete`, requestOptions );
        // console.log(result)
        checkTokenExpire(result)
        await dispatch({ type: FETCH_COMPLETED_PROJECTS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const fetchAllInprogressProjects = () =>  async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/projects/inprogress/all`, requestOptions );
        checkTokenExpire(result)
        // console.log(result)
        await dispatch({ type: FETCH_INPROGRESS_PROJECTS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
            // console.log(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const fetchCompletedProjects = () =>  async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/projects`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: FETCH_COMPLETED_PROJECTS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const fetchHoldProjects = () =>  async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/projects/hold/all`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: FETCH_HOLD_PROJECTS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}


export const deleteProject = (id) =>  async dispatch => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/project/delete/${id}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: DELETE_PROJECT, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const onAcceptProjectFromUser = (id) => async dispatch => {
    try{
        
        const requestOptions = {
            method: 'PATCH',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/project/accept/${id}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: ACCEPT_PROJECT_BY_USER, payload: result  })
    }catch(error){
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const removeMemberFromProject = (id) =>  async dispatch => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/project/delete/member/${id}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: DELETE_MEMBER_FROM_PROJECT, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const updateProjectStatus = (id) =>  async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/project/${id}/complete/status`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: UPDATE_PROJECT_STATUS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const changeProjectHoldStatus = (id) =>  async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/project/${id}/hold/status`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: CHANGE_HOLD_STATUS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const changeProjectProgressStatus = (id) => async dispatch => {
    try{
        const requestOptions = {
            method: 'PATCH',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/project/${id}/progress/status`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: CHANGE_PROGRESS_STATUS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}



export const getAssignMembers = (id) => async dispatch => {
    try{
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest( `/project/${id}/members`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: GET_ASSIGN_PROJECT_MEMBER, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}


export const getSingleProject = (id) => async dispatch => {
    
    try{
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest(`/project/${id}/detail`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: GET_SINGLE_PROJECT, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const getUnassignedProgressProjects = () => async dispatch => {
    try{
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest(`/projects/unassigned/inprogress`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: GET_UNASSIGNED_INPROGRESS_PROJECTS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const getUnassignedCompletedProjects = () => async dispatch => {
    try{
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest(`/projects/unassigned/completed`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: GET_UNASSIGNED_COMPLETED_PROJECTS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const getUnassignedHoldProjects = () => async dispatch => {
    try{
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest(`/projects/unassigned/hold`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: GET_UNASSIGNED_HOLD_PROJECTS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const getUnassignedAllProjects = () => async dispatch => {
    try{
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
        }
        const result = await apiRequest(`/projects/unassigned/all`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: GET_UNASSIGNED_ALL_PROJECTS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const deleteTasks = (data) => async dispatch => {
    try{
        const requestOptions = {
            method: 'DELETE',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
            body: JSON.stringify(data)
        }
        const result = await apiRequest(`/tasks`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: DELETE_TASKS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const getAllProjectsList = () => async dispatch => {
    try{
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
        }
        const result = await apiRequest(`/projects/list/all`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: GET_ALL_PROJECTS, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const getUsersAllProjects = (userId) => async dispatch => {
    try{
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
        }
        const result = await apiRequest(`/projects/user/${userId}/all`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: GET_ALL_PROJECTS_OF_USER, payload: result  })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const loadMoreTasks = ( projectId, userId, skip ) => async dispatch => {
    try{
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },
        }
        const result = await apiRequest(`/tasks/load-more?projectId=${projectId}&userId=${userId}&skip=${skip}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: LOAD_MORE_TASKS, payload: result  })
    }catch(error){
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}