import { 
    ADD_PROJECT,
    FETCH_PROJECTS,
    EDIT_PROJECT,
    DELETE_PROJECT,
    FETCH_COMPLETED_PROJECTS, 
    FETCH_INPROGRESS_PROJECTS,
    DELETE_MEMBER_FROM_PROJECT,
    UPDATE_PROJECT_STATUS,
    CHANGE_HOLD_STATUS,
    FETCH_HOLD_PROJECTS,
    CHANGE_PROGRESS_STATUS,
    ACCEPT_PROJECT_BY_USER,
    GET_ASSIGN_PROJECT_MEMBER,
    GET_SINGLE_PROJECT,
    GET_UNASSIGNED_INPROGRESS_PROJECTS,
    GET_UNASSIGNED_HOLD_PROJECTS,
    GET_UNASSIGNED_COMPLETED_PROJECTS,
    GET_UNASSIGNED_ALL_PROJECTS,
    DELETE_TASKS,
    GET_ALL_PROJECTS,
    GET_ALL_PROJECTS_OF_USER,
    LOAD_MORE_TASKS
} from "../actions/types";

const initialState = { project: '', projectAdd:'', members:'', projectFetch:'', projectEdit:'', projectDelete:'', completedFetch:'', progressFetch:'', memberRemoved:'', updatedStatus:'', holdStatus:'', holdFetch:'', acceptProject:'', projectDetail:'', unassignedProgress:'', unassignedHold:'', unassignedCompleted:'', deleteTasks:'', projects:'', userAllProjects:'', moreTasksLoaded:''}

export default ( state = initialState, action ) => {

    switch (action.type) {
        case ADD_PROJECT:
            return {
                ...state,
                projectAdd: action.payload
            }
        case FETCH_PROJECTS:
            // console.log(action.payload)
            return {
                ...state,
                projectFetch: action.payload
            }
        case EDIT_PROJECT:
            return {
                ...state,
                projectEdit: action.payload
            }
        case DELETE_PROJECT:
            return {
                ...state,
                projectDelete: action.payload
            }
        case FETCH_COMPLETED_PROJECTS:
            return {
                ...state,
                completedFetch: action.payload
            }
        case FETCH_HOLD_PROJECTS:
            return {
                ...state,
                holdFetch: action.payload
            }
        case FETCH_INPROGRESS_PROJECTS:
            return {
                ...state,
                progressFetch: action.payload
            }
        case DELETE_MEMBER_FROM_PROJECT:
            return {
                ...state,
                memberRemoved: action.payload
            }
        case UPDATE_PROJECT_STATUS:
            return {
                ...state,
                updatedStatus: action.payload
            }
        case CHANGE_HOLD_STATUS:
            return {
                ...state,
                holdStatus: action.payload
            }
        case CHANGE_PROGRESS_STATUS:
            return {
                ...state,
                progressStatus: action.payload
            }
        case ACCEPT_PROJECT_BY_USER:
            return {
                ...state,
                acceptProject: action.payload
            }
        case GET_ASSIGN_PROJECT_MEMBER:
            return {
                ...state,
                members: action.payload
            }
        case GET_SINGLE_PROJECT:
            return {
                ...state,
                projectDetail: action.payload
            }
        case GET_UNASSIGNED_INPROGRESS_PROJECTS: 
            return {
                ...state,
                unassignedProgress: action.payload
            }
        case GET_UNASSIGNED_HOLD_PROJECTS:
            return {
                ...state,
                unassignedHold: action.payload
            }
        case GET_UNASSIGNED_COMPLETED_PROJECTS:
            return {
                ...state,
                unassignedCompleted: action.payload
            }
        case GET_UNASSIGNED_ALL_PROJECTS:
            return {
                ...state,
                unassignedAll: action.payload
            }
        case DELETE_TASKS:
            return {
                ...state,
                deleteTasks: action.payload
            }
        case GET_ALL_PROJECTS:
            return {
                ...state,
                projects: action.payload
            }
        case GET_ALL_PROJECTS_OF_USER:
            return {
                ...state,
                userAllProjects: action.payload
            }
        case LOAD_MORE_TASKS:
            return {
                ...state,
                moreTasksLoaded: action.payload
            }
        default: return state;
    }
}
