import { STATUS_CREATE, STATUS_FETCH, STATUS_DELETE, STATUS_EDIT } from './types';
import { apiRequest, checkTokenExpire } from './actionResponseHandler';
import authHeader from '../helpers/auth-header'
import { toast } from "react-toastify";
toast.configure()

export const createStatus = text => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
            body: JSON.stringify(text)
        }
        const status = await apiRequest( "/status/create", requestOptions );
        checkTokenExpire(status)
        await dispatch({ type: STATUS_CREATE, payload: status })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const editStatus = (statusId, text) => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            },  
            body: JSON.stringify(text)
        }
        const status = await apiRequest( `/status/edit/${statusId}`, requestOptions );
        checkTokenExpire(status)
        await dispatch({ type: STATUS_EDIT, payload: status })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const fetchStatus = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            }    
        }
        // console.log(requestOptions)
        const status = await apiRequest( "/status", requestOptions );
        checkTokenExpire(status)
        await dispatch({ type: STATUS_FETCH, payload: status })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const deleteStatus = (statusId) => async dispatch => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                'Authorization': authHeader()
            }    
        }
        const status = await apiRequest(`/status/delete/${statusId}`, requestOptions );
        checkTokenExpire(status)
        await dispatch({ type: STATUS_DELETE, payload: status })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}
