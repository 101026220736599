import React, { Component } from 'react';
import { Button, Form, FormControl, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ListItem, ListItemText,Grid  } from '@material-ui/core'
import { connect } from 'react-redux';
import { socket } from '../../actions/actionResponseHandler';
// import openSocket from "socket.io-client";
import { getIndividualNote, getPublicNote } from '../../actions/notesAction';
// import config from '../../helpers/config';
// import swal from 'sweetalert';
import { toast } from "react-toastify";
toast.configure()
// const socket = openSocket(config.apiBaseURL2);
class NotesComponent extends Component{

    constructor(props){
        super(props)
        this.state = {
            fields:{
                privateNote:'',
                publicNote:'',
            },
            loader: false,
            isSaved: false,
            loggedInUserId:'',
            userRole:'',
            displayPrivate:'',
            displayPublic:''
        }
    }
    componentDidMount = async () => {
        this.onSavedNote()
        await this.fetchIndividualNote()
        await this.fetchPublicNote()
        if(this.props.auth && this.props.auth.loggedIn){
            if(this.props.auth.user){
                this.setState({ 
                    loggedInUserId: this.props.auth.user.userId,
                    userRole: this.props.auth.user.role
                })
            }
        }
    }

    fetchIndividualNote = async () => {
        await this.props.getIndividualNote();
        // console.log(this.props.getIndividual)
        if(this.props.getIndividual.response){
            let fields = this.state.fields
            fields['privateNote'] = this.props.getIndividual.data ? this.props.getIndividual.data.note : ''
            this.setState({ fields })
        }
        // else{
        //     toast.dismiss()
        //     toast.error(this.props.getIndividual.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        // }
    }

    fetchPublicNote = async () => {
        await this.props.getPublicNote();
        // console.log(this.props.getPublic)
        if(this.props.getPublic.response){
            let fields = this.state.fields
            fields['publicNote'] = this.props.getPublic.data ? this.props.getPublic.data.note : ''
            this.setState({ fields })
        }
        // else{
        //     toast.dismiss()
        //     toast.error(this.props.getPublic.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        // }
    }

    handleChange = (e) => {
        // console.log("shjdbcjsbdjb")
        const { name, value } = e.target
        let fields = this.state.fields
        fields[name] = value // e
        this.setState({ fields })
    }
    savePrivateNoteToDatabase = () => {
        // console.log( this.state.fields['privateNote'] )

        this.setState({isSaved: false, displayPrivate:''});
        if (this.timeoutId) {
          clearTimeout(this.timeoutId)
        };
        if(this.PL_2_timeoutId_2){
            clearTimeout(this.PL_2_timeoutId_2)
        }
        this.timeoutId = setTimeout( ()  => {
            // console.log("Private Note To Be saved From State", this.state.fields['privateNote'])
            if(this.state.fields['privateNote'] !== undefined){
                socket.emit('save-private-note', { note:( this.state.fields['privateNote'] && this.state.fields['privateNote'].trim().length > 0) ? this.state.fields['privateNote'].trim() : '', user: this.state.loggedInUserId})
                this.setState({isSaved: true, displayPrivate:'Saved'});
            }
        }, 300);
        this.PL_2_timeoutId_2 = setTimeout( ()  => {
            this.setState({displayPrivate:''});
        }, 3000)
    }

    savePublicNoteToDatabase = () => {
        // console.log("Public Note", this.state.fields['publicNote']);
        this.setState({isSaved: false, displayPublic:''});
        if (this.PtimeoutId) {
          clearTimeout(this.PtimeoutId)
        };
        if(this.P_2_timeoutId_2){
            clearTimeout(this.P_2_timeoutId_2)
        }
        this.PtimeoutId = setTimeout( ()  => {
            socket.emit('save-public-note', { note: (this.state.fields['publicNote'] && this.state.fields['publicNote'].trim().length > 0 ) ? this.state.fields['publicNote'].trim() : '' })
            this.setState({isSaved: true, displayPublic:'Saved'});
        }, 300);
        this.P_2_timeoutId_2 = setTimeout( ()  => {
            this.setState({displayPublic:''});
        }, 3000)
    }

    onSavedNote = () => {
        socket.on('public-saved', async data => {
            await this.fetchPublicNote()
        })
    }

    render(){
        const { fields, displayPrivate, displayPublic } = this.state
        // console.log(fields)
        return(            
            <>
            <div className="d-sm-flex justify-content-between align-items-center">
                <h3 className="mt-0 mb-3 d-flex align-items-center"> Private Notes
                    <sup className="ml-2">
                        <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id={`tooltip-project`}>
                                Private notes will only visible to you
                            </Tooltip>
                        }
                        >
                            <i className ="fa fa-info-circle"/>
                        </OverlayTrigger>
                    </sup> 
                    {/* <sup><i className="fa fa-info-circle" data-toggle="tooltip" data-placement="auto" title="Private notes will only visible to you"/></sup> */}
                    <small style={{fontSize:'12px'}}> { displayPrivate } </small>
                </h3>
                <div className="button-group text-right mb-2">
                    <OverlayTrigger
                        placement="left"
                        overlay={
                            <Tooltip id={`tooltip-project`}>
                                Clear your private notes
                            </Tooltip>
                        }
                    >
                        <Button 
                            onClick={() => {
                                let fields = this.state.fields
                                fields['privateNote'] = ''
                                this.setState({ fields }, () => this.savePrivateNoteToDatabase())
                            }}
                        >
                            Clear
                        </Button>
                    </OverlayTrigger>
                </div>
            </div>
            <div className="form-group d-sm-flex align-items-center mb-4">
                <FormControl as="textarea" rows="10" name="privateNote" defaultValue={ fields['privateNote'] } onChange={this.handleChange} onKeyUp={this.savePrivateNoteToDatabase} placeholder="Type something..."/>
            </div>
            <div className="d-sm-flex justify-content-between align-items-center">
                <h3 className="mt-0 mb-3 d-flex align-items-center"> 
                    Public Notes 
                    <sup className="ml-2">
                        <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id={`tooltip-project`}>
                                Public notes will be visible to all team members
                            </Tooltip>
                        }
                        >
                            <i className ="fa fa-info-circle"/>
                        </OverlayTrigger>
                    </sup> 
                    {/* <sup><i className="fa fa-info-circle" data-toggle="tooltip" data-placement="auto" title="Public notes will be visible to all team members"/></sup> */}
                    <small style={{fontSize:'12px'}}> { displayPublic } </small> 
                </h3>
                {this.state.userRole === 'super_admin' && 
                    <div className="button-group text-right mb-2">
                        <OverlayTrigger 
                            placement="left"
                            overlay={
                                <Tooltip id={`tooltip-project`}>
                                    Clear public notes
                                </Tooltip>
                            }
                        >
                            <Button 
                                onClick={() => {
                                    let fields = this.state.fields
                                    fields['publicNote'] = ''
                                    this.setState({ fields }, () => this.savePublicNoteToDatabase())
                                }}
                            >
                                Clear
                            </Button>
                        </OverlayTrigger>
                    </div>
                }
                </div>
                <div className="form-group d-sm-flex align-items-center">
                    <FormControl as="textarea" rows="10" name="publicNote" defaultValue={ fields['publicNote'] } onChange={this.handleChange} onKeyUp={this.savePublicNoteToDatabase} placeholder="Type something..."/>
                </div>
            </>
        )
    }

    
}

const mapStateToProps = state => {
    const { notes, auth } = state
    return {
        auth: auth,
        getIndividual: notes.getIndividualResponse,
        getPublic: notes.getPublicResponse,
    }
}
const dispatchAction = { getIndividualNote, getPublicNote }
export default connect(mapStateToProps, dispatchAction )(NotesComponent);