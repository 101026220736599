import React, { Component } from 'react';
import { Button, Col, Form, Modal, Row, Alert, Spinner } from 'react-bootstrap';
import { fetchMembers } from '../../actions/memberAction';
import { 
    addReport, 
    getSingleProjectReport, 
    getSingleUserReports 
}  from '../../actions/reportActions';
import { 
        fetchSingleUserProjects,
        fetchAllInprogressProjects,
        fetchHoldProjects,
        fetchCompletedProjects,
        getUnassignedAllProjects,
        getSingleProject,
        loadMoreTasks
} from '../../actions/projectActions';
import { connect } from 'react-redux';
import ReportComponent from './ReportsComponent'

import { socket } from '../../actions/actionResponseHandler';
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
toast.configure();

class ReportByProjectComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            openShareMessageTextBox: false,
            memberId:'',
            userRole:'',
            identifying:'',
            permissions:{},
            loggedInUserId:'',
            loggedInUserName:'',
            addReportLoader:false,
            fetchProjectLoader: false,
            fetchReportLoader: true,
            projects:[],
            userProjects:[],
            // ------ // 
            selectProjectId:'',
            selectUserProjectId:'',
            selectedProject:{},

            spentHours:'',
            spenMinutes:'',

            completedTask:[],

            workTestingTimes:'',
            didClientUpdated:'',
            messageForClient:'',
            remarks:'',
            adminList:[],
            membersList:[],
            membersListLoader: false,

            checkValidClientUpdated:'',
            // ---------- //
            remarksModel: false,
            openClientMessageModel: false,
            showClientMessage:'',
            openTaskModel:false,

            isMoreTasks: true, // default true
            skipTasks: 20, // default for loadmore tasks
            loadingMoreTasks: false,
            showTasksUserName: '',
            tasks:[],
            task__projectName:'',
            showReportCompletedTask:[],
            reports:[],
            projectDetail:{},
            backUpReports:[],
            // --- STeps ----//
            showProjectStep: true,
            showTimeSpent: false,
            showTaskList: false,
            showTestingTimes:false,
            showUpdate:false,
            showRemarks:false,
            showPersonalInfoStep: false,
            reportDate: new Date(),

            //  --------- Project Wise Report --- //
            showReportByProjects: true,
            selectedReportUserId:'',
            allCompleted:[],
            allHold:[],
            allUnassigned:[],

            selectedProjectType: 'p-inprogress',

            // ----- react select ----- //
            groupStyles: {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            },
            groupBadgeStyles:{
                backgroundColor: "#5937E5",
                borderRadius: "2em",
                color: "#fff",
                display: "inline-block",
                fontSize: 12,
                fontWeight: "normal",
                lineHeight: "1",
                minWidth: 1,
                padding: "0.16666666666667em 0.5em",
                textAlign: "center"
            }
        }
    }

    componentDidMount(){
        if(this.props.auth && this.props.auth.loggedIn){
            if(this.props.auth.user && this.props.auth.user.permissions && this.props.auth.user.permissions.report){
                this.setState({ 
                    userRole: this.props.auth.user.role, 
                    permissions:{...this.props.auth.user.permissions.report},
                    loggedInUserId: this.props.auth.user.userId,
                    loggedInUserName: this.props.auth.user.fullname,
                    identifying: this.props.auth.user.identifying,
                }, async () => {
                    
                    if((this.state.userRole === 'admin')){ 
                        await this.getSingleUserProjects(this.state.loggedInUserId);
                    }
                    await this.getAllProjects(); 
                })
            }
        }
    }

    sendNotification = async (members, message) => {
        socket.emit('Send_Notification', { room: members, notify: message});
    }

    /**
     *  @function getAllProjects is used to fetch all in progress projects
     */

    getAllProjects = async () => {
        this.setState({ fetchProjectLoader: true})
        await this.props.fetchAllInprogressProjects();
        if(this.props.progressProject.response){
            this.setState({
                projects: this.props.progressProject.projects,
                selectProjectId: this.props.progressProject.projects[0]._id,
                fetchProjectLoader: false,
            }, async ()=> {
            
                await this.fetchSingleProjectReport(this.state.selectProjectId, this.dateConvert(this.state.reportDate));
                // this.fetchSingleProjectReport(this.state.selectProjectId)
                // this.onChangeReportDate(this.state.reportDate)
            })
        }else{
            this.setState({  fetchProjectLoader: false })
        }
    }

    getAllCompletedProjects = async () => {
        await this.props.fetchCompletedProjects();
        if(this.props.completedProject.response){
            this.setState({
                projects: this.props.completedProject.projects,
                selectProjectId: this.props.completedProject.projects[0]._id,
                fetchProjectLoader: false,
            }, async ()=> {
                // await this.fetchSingleProjectReport(this.state.selectProjectId)
                await this.fetchSingleProjectReport(this.state.selectProjectId, this.dateConvert(this.state.reportDate))
        
                // this.onChangeReportDate(this.state.reportDate)
            })
        }else{
            this.setState({ fetchProjectLoader: false })
        }
    }

    getAllHoldProjects = async () => {
        await this.props.fetchHoldProjects();
        if(this.props.holdProject.response){
            this.setState({
                projects: this.props.holdProject.projects,
                selectProjectId: this.props.holdProject.projects[0]._id,
                fetchProjectLoader: false,
            }, async ()=> {
                await this.fetchSingleProjectReport(this.state.selectProjectId, this.dateConvert(this.state.reportDate))
                // await this.fetchSingleProjectReport(this.state.selectProjectId)
                // this.onChangeReportDate(this.state.reportDate)
            })
        }else{
            this.setState({ fetchProjectLoader: false })
        }
    }

    getAllUnassignedProjects = async () => {
        await this.props.getUnassignedAllProjects();
        if(this.props.unAssignedAll.response){
            this.setState({
                projects: this.props.unAssignedAll.projects,
                selectProjectId: this.props.unAssignedAll.projects[0]._id,
                fetchProjectLoader: false,
            }, async ()=> {
                await this.fetchSingleProjectReport(this.state.selectProjectId, this.dateConvert(this.state.reportDate));
                // await this.fetchSingleProjectReport(this.state.selectProjectId)
                // this.onChangeReportDate(this.state.reportDate)
            })
        }else{
            this.setState({ fetchProjectLoader: false })
        }
    }

    

    fetchSingleProjectReport = async (projectId, date) => {
        this.setState({ fetchReportLoader: true })
        await this.props.getSingleProjectReport(projectId, date)
        if(this.props.projectReport.response){
            this.setState({  
                reports: this.props.projectReport.reports,
                backUpReports: this.props.projectReport.reports,
                fetchReportLoader: false,
            }, async ()=> {
                // await this.onChangeReportDate(this.state.reportDate)
                if(this.state.reports.length === 0){
                    await this.getProjectDetails(projectId)
                }
            })          
        }else{
            toast.dismiss()
            toast.error( this.props.projectReport.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ fetchReportLoader: false })
        }
    }

    getProjectDetails = async (projectId) => {
        await this.props.getSingleProject(projectId)
        if(this.props.projectDetail.response){
            this.setState({
                projectDetail: this.props.projectDetail.project
            })
        }
    }

  
    
    getSingleUserProjects = async (loggedInUserId) => {
        this.setState({ fetchProjectLoader: true})
        await this.props.fetchSingleUserProjects(loggedInUserId);
        if(this.props.projectFetch.response){
            this.setState({
                userProjects: this.props.projectFetch.projects,
                fetchProjectLoader: false,
            })
        }else{
            this.setState({ fetchProjectLoader: false})
        }
    }


    changeStep = ( step ) => {
        switch (step) {
            case 1: this.setState({
                        showProjectStep: true,
                        showTimeSpent: false,
                        showTaskList: false,
                        showTestingTimes:false,
                        showUpdate:false,
                        showRemarks:false,
                        showPersonalInfoStep: false
                    })
                    break;
            case 2: this.setState({
                        showProjectStep: false,
                        showTimeSpent: true,
                        showTaskList: false,
                        showTestingTimes:false,
                        showUpdate:false,
                        showRemarks:false,
                        showPersonalInfoStep: false
                    })
                    break;
            case 3: this.setState({
                        showProjectStep: false,
                        showTimeSpent: false,
                        showTaskList: true,
                        showTestingTimes:false,
                        showUpdate:false,
                        showRemarks:false,
                        showPersonalInfoStep: false
                    })
                    break;
            case 4: this.setState({
                        showProjectStep: false,
                        showTimeSpent: false,
                        showTaskList: false,
                        showTestingTimes:true,
                        showUpdate:false,
                        showRemarks:false,
                        showPersonalInfoStep: false
                    })
                    break;
            case 5: this.setState({
                        showProjectStep: false,
                        showTimeSpent: false,
                        showTaskList: false,
                        showTestingTimes:false,
                        showUpdate:true,
                        openShareMessageTextBox: false,
                        showRemarks:false,
                        showPersonalInfoStep: false
                    })
                    break;
            case 6: this.setState({
                        showProjectStep: false,
                        showTimeSpent: false,
                        showTaskList: false,
                        showTestingTimes:false,
                        showUpdate:false,
                        showRemarks:true,
                        showPersonalInfoStep: false
                    })
                    break;
            case 7: this.setState({
                        showProjectStep: false,
                        showTimeSpent: false,
                        showTaskList: false,
                        showTestingTimes:false,
                        showUpdate:false,
                        showRemarks:false,
                        showPersonalInfoStep: true
                    })
                    break;
            default: break;
        }
    }

    checkTasksCompleted = (taskList) => {
        return taskList.filter(x => x.isCompleted === false).length;
    }

    onChangeProject = (e) => {
        let selectedProject = this.state.userProjects.find(obj => obj._id === e.target.value)
        this.setState({
            selectUserProjectId: e.target.value,
            selectedProject
        })
    }

    //  -------------  Step 1 ----------- //
    showProjectStep = () => {
        const { userProjects, selectUserProjectId } = this.state
        let nextButtonDisable = true
        if(selectUserProjectId.trim().length > 0){
            nextButtonDisable = false
        }

        return (
            <div className="form-group mb-0 d-sm-flex align-items-center justify-content-between" style={{flex : 'auto'}}>
                <select className="form-control custom-select mr-sm-2 ml-0 my-3 my-md-0" name="pName" onChange={this.onChangeProject} value={this.state.selectUserProjectId}>
                    {(userProjects && userProjects.length > 0) 
                        ? 
                        <>
                            <option value="" disabled> Select Your Project</option>
                            {userProjects.map((project, idx)=> {
                                if(project.assignedMember[0] && project.assignedMember[0].accepted){
                                    if(this.checkTasksCompleted(project.taskList) > 0){
                                        return (
                                            <option value={project._id} key={idx}> {project.projectName} </option>
                                        )
                                    }
                                }
                            })}
                        </>
                        :
                        <option value="" disabled >Not Assign any project</option>
                    }
                </select>
                <div className="button-group justify-content-end d-flex">
                    <Button type="submit" variant="primary" onClick={() => this.changeStep(2)} disabled={nextButtonDisable}> Next <i className="fa fa-chevron-right"></i></Button>                
                </div>
            </div>
        )
    }



    handleEstimateTimeChange = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    //  --------------  Step 2 --------------- //
    showTimeSpent = () => {
        let nextButtonDisable = true
        const { spentHours, spenMinutes } = this.state 
        if(spentHours.trim().length > 0 && spenMinutes.trim().length > 0){
            nextButtonDisable = false
        }
        return (
            <>
                <div className="p-4 shadow-lg reportForm timeStep">
                    <Row className="justify-content-center">
                        <div className="col-sm-12 animate__animated animate__fadeInRight">
                            <div className="form-group d-sm-flex align-items-center justify-content-between">
                                <label>Time Spent</label>
                                <div className="timeDiv">
                                    <select className="form-control custom-select mr-sm-2" name="spentHours" onChange={this.handleEstimateTimeChange} value={this.state.spentHours}>
                                        <option value="" disabled > Select </option>
                                        <option value="00"> 00 </option>
                                        <option value="01"> 01 </option>
                                        <option value="02"> 02 </option>
                                        <option value="03"> 03 </option>
                                        <option value="04"> 04 </option>
                                        <option value="05"> 05 </option>
                                        <option value="06"> 06 </option>
                                        <option value="07"> 07 </option>
                                        <option value="08"> 08 </option>
                                        <option value="09"> 09 </option>
                                        <option value="10"> 10 </option>
                                        <option value="11"> 11 </option>
                                        <option value="12"> 12 </option>
                                        <option value="13"> 13 </option>
                                        <option value="14"> 14 </option>
                                        <option value="15"> 15 </option>
                                        <option value="16"> 16 </option>
                                        <option value="17"> 17 </option>
                                        <option value="18"> 18 </option>
                                        <option value="19"> 19 </option>
                                        <option value="20"> 20 </option>
                                        <option value="21"> 21 </option>
                                        <option value="22"> 22 </option>
                                        <option value="23"> 23 </option>
                                    </select>
                                    <span> Hours </span>
                                    
                                    <select className="form-control custom-select mr-sm-2" name="spenMinutes" onChange={this.handleEstimateTimeChange} value={this.state.spenMinutes}>
                                        <option value="" disabled > Select </option>
                                        <option value="00"> 00 </option>
                                        <option value="05"> 05 </option>
                                        <option value="10"> 10 </option>
                                        <option value="15"> 15 </option>
                                        <option value="20"> 20 </option>
                                        <option value="25"> 25 </option>
                                        <option value="30"> 30 </option>
                                        <option value="35"> 35 </option>
                                        <option value="40"> 40 </option>
                                        <option value="45"> 45 </option>
                                        <option value="50"> 50 </option>
                                        <option value="55"> 55 </option>
                                    </select>
                                    <span className="mr-2"> Minutes </span>                                
                                </div>
                                <div className="button-group justify-content-end d-flex">
                                    <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(1)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                    <Button type="submit" variant="primary" onClick={() => this.changeStep(3)} disabled={nextButtonDisable}>Next <i className="fa fa-chevron-right"></i></Button>                
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </>
        )
    }

    taskSelected = (e)  => {
        const { checked, value } = e.target
        let completedTask = this.state.completedTask;
        if(checked){
            completedTask.push(value);
        }else{
            completedTask = completedTask.filter(e => e !== value);
        }
        
        this.setState({
            completedTask
        })
    }

    // -------------- Step 3 ----------------- //
    showTaskList = () => {
        const { selectedProject } = this.state
        let nextButtonDisable = true 

        if(this.state.completedTask.length > 0){
            nextButtonDisable = false
        }
        return (
            <>
            <div className="p-4 shadow-lg reportForm">
                <Row className="justify-content-center">
                    <div className="col-sm-12 animate__animated animate__fadeInRight">
                        <label>List of completed tasks</label>
                        <div className="form-group text-left mt-2">
                            <Form className="checkForm">
                                {['checkbox'].map((type, idx) => (
                                    <div key={`inline-${idx}`} className="mb-3">
                                        {selectedProject.taskList.map((task, idx) => {
                                            if(!task.isCompleted){
                                                let checked =  false
                                                if(this.state.completedTask.includes(task._id)){
                                                    checked = true
                                                }
                                                return (
                                                    <Form.Check inline key={idx} label={task.taskName} type={type} id={`inline-${idx}`} onChange={this.taskSelected} value={task._id} checked={checked}/>
                                                )
                                            }
                                        })}
                                    </div>
                                ))}
                            </Form>
                            <div className="button-group justify-content-end d-flex w-100">
                                <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(2)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                <Button type="submit" variant="primary" onClick={() => this.changeStep(4)} disabled = {nextButtonDisable}>Next <i className="fa fa-chevron-right"></i></Button>                
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
            </>
        )
    }

    handleChangeTestingTime = (e) => {
        this.setState({ workTestingTimes: e.target.value })
    }
    showTestingTimes = () => {
        let nextButtonDisable = true
        if( this.state.workTestingTimes.trim().length > 0){
            nextButtonDisable = false
        }
        return (
            <>
            <div className="p-4 shadow-lg reportForm">
            <Row className="justify-content-center">
                    <div className="col-sm-12 animate__animated animate__fadeInRight">
                        <div className="form-group d-sm-flex align-items-center justify-content-between">
                            <label>How Many Times You Tested Your Work ?</label>
                            <select className="form-control custom-select mr-sm-2" name="workTestingTimes" onChange={this.handleChangeTestingTime} value={this.state.workTestingTimes}>
                                <option value="" disabled> Select </option>
                                <option value="0"> 0 Times </option>
                                <option value="1"> 1 Times </option>
                                <option value="2"> 2 Times </option>
                                <option value="3"> 3 Times </option>
                                <option value="4"> 4 Times </option>
                                <option value="5"> 5 Times </option>
                                <option value="6"> 6 Times </option>
                                <option value="7"> 7 Times </option>
                                <option value="8"> 8 Times </option>
                                <option value="9"> 9 Times </option>
                                <option value="10"> 10 Times </option>
                            </select>
                            <div className="button-group justify-content-end d-flex">
                                <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(3)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                <Button type="submit" variant="primary" onClick={() => this.changeStep(5)} disabled={nextButtonDisable}>Next <i className="fa fa-chevron-right"></i></Button>                
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
            </>
        )
    }
    

    OnChangeUpdateOption = (e) => {
        const { value, options, selectedIndex } = e.target
        if(value === 'NO'){
            this.setState({openShareMessageTextBox: true })
        }else{
            this.setState({openShareMessageTextBox: false, messageForClient:''})
        }
        this.setState({ didClientUpdated:options[selectedIndex].innerHTML, checkValidClientUpdated:value })
    }

    // -------------------  Step 5 ----------------- //
    showUpdate = () => {
        const { openShareMessageTextBox, checkValidClientUpdated, messageForClient }= this.state
        let nextButtonDisable = true
        if(checkValidClientUpdated == 'NO' && openShareMessageTextBox){
            if(messageForClient.length > 5){
                nextButtonDisable = false
            }
        }else{
            if(checkValidClientUpdated.length > 0){
                nextButtonDisable = false
            }
        }
        return (
            <>
                <div className="p-4 shadow-lg reportForm">
                    <Row className="justify-content-center">
                        <div className="col-sm-12 animate__animated animate__fadeInRight">
                            <div className="form-group d-sm-flex align-items-center justify-content-between">
                                <label>Did you shared updates with client?</label>
                                <select className="form-control custom-select mr-sm-2" onChange={this.OnChangeUpdateOption}>
                                    <option value=''>Select</option>
                                    <option value='YES'>Yes I did</option>
                                    <option value='NO'>No I didn't</option>
                                    <option value='NR'>Not Required</option>
                                </select>
                                <div className="button-group justify-content-end d-flex">
                                    <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(4)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                    <Button type="submit" variant="primary" onClick={() => this.changeStep(6)} disabled={nextButtonDisable}>Next <i className="fa fa-chevron-right"></i></Button>                
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 mt-3">
                                {openShareMessageTextBox &&
                                <Form.Group controlId="shareUpdateMessage">
                                    <Form.Label> Updates to share with Client </Form.Label>
                                    <Form.Control as="textarea" name="messageForClient" className="mx-0 mt-2" rows="3" onChange={this.handleChangeTextarea} value={messageForClient} placeholder={'Please share a complete message to share with the client along with project URL, screenshots, credentials, etc. whatever is required for the client to test the completed tasks.'}/>
                                </Form.Group>}
                        </div>
                    </Row>
                </div>
            </>
        )
    }


    handleChangeTextarea = (e) => {
        const { name, value } = e.target
        this.setState({ [name]:value})
    }
    // ------------ Step 6 ------------------ //
    showRemarks = () => {
        const { remarks } = this.state
        let nextButtonDisable = true
        if(remarks.length > 1){
            nextButtonDisable = false
        }
        return(
            <>
                <div className="p-4 shadow-lg reportForm">
                    <Row className="justify-content-center">
                            <div className="col-sm-12 animate__animated animate__fadeInRight">
                                <Form.Group controlId="Remarks" className="d-sm-flex align-items-center justify-content-between">
                                    <Form.Label> Remarks </Form.Label>
                                    <Form.Control as="textarea" rows="5" name="remarks" onChange={this.handleChangeTextarea} value={remarks} placeholder={`Please mention the complete details of the project with the Project URL, Screenshots, credentials, or whatever you think is important for the testing of the completed tasks.`}/>
                                    <div className="button-group justify-content-end d-flex">
                                        <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(5)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                        <Button type="submit" variant="primary" onClick={(e) => this.handleFinalReportSubmit(e)} disabled={ nextButtonDisable || this.state.addReportLoader }> {this.state.addReportLoader ? 'Wait...': 'Finish'}</Button>
                                    </div>
                                </Form.Group>
                            </div>
                    </Row>
                </div>
            </> 
        )
    }
    
    handleFinalReportSubmit = async (e) => {
        e.preventDefault();
        let data = {}
        data.user = this.state.loggedInUserId
        data.project = this.state.selectUserProjectId
        data.timeSpent = `${this.state.spentHours}:${this.state.spenMinutes}`
        data.completedTask = this.state.completedTask
        data.workTestingTimes = this.state.workTestingTimes
        data.didClientUpdated = this.state.didClientUpdated
        if(this.state.messageForClient.length > 0){
            data.messageForClient = this.state.messageForClient
        }
        data.remarks = this.state.remarks
        this.setState({ addReportLoader: true })
        await this.props.addReport(data)
        if(this.props.reportAdd.response){
            this.sendNotification(this.state.adminList, `${this.state.loggedInUserName} reported for project "${this.state.selectedProject['projectName']}"`)
            toast.dismiss()
            toast.success( this.props.reportAdd.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ addReportLoader: false })
            if(this.state.identifying === 'hr' || this.state.identifying === 'qualityAuditor'){
                this.setState({
                    memberId: this.state.loggedInUserId
                })
            }
            this.fetchSingleProjectReport(this.state.selectUserProjectId, this.dateConvert(this.state.reportDate))
            this.resetForm()
        }else{
            toast.dismiss()
            toast.error( this.props.reportAdd.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ addReportLoader: false })
        }
    }

    resetForm = () => {
        this.setState({
            selectUserProjectId:'',
            spentHours:'',
            spenMinutes:'',
            completedTask:[],
            workTestingTimes:'',
            didClientUpdated:'',
            messageForClient:'',
            remarks:'',
            selectedProject:{},
            checkValidClientUpdated:'',
            openShareMessageTextBox: false,
        })
        this.getSingleUserProjects(this.state.loggedInUserId);
        this.changeStep(1)
    }

    handleChangeLabel = async (projectId) => {
        socket.emit('change-label', {projectId})
        setTimeout(async ()=> {
            await this.fetchSingleProjectReport(this.state.selectProjectId, this.dateConvert(this.state.reportDate))
        }, 250)
    }


    convertDate = (date) => {
        var d = new Date(date.split('T')[0]);
        var year = (new Date().getFullYear() === d.getFullYear()) ? '' : ", " +  d.getFullYear()
        var monthName = d.toLocaleString('default', { month: 'short' })
        return  (monthName) + " " + d.getDate() +  year
    }

    convertTime = (date) => {
        var d = new Date(date);
        return  d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    }

    dateConvert = (date) => new Date(date).getFullYear() + '-'  + Number(new Date(date).getMonth()+1) + '-' +  new Date(date).getDate();

    onCloseClientMessageModel = () => {
        this.setState({ showClientMessage: '', openClientMessageModel: false, remarksModel: false})
    }

    onCloseTaskModel = () => {
        this.setState({ 
            openTaskModel: false , 
            tasks:[], 
            showReportCompletedTask:[],  
            task__projectName:'',
            isMoreTasks: true, 
            taskProjectId:'',
            skipTasks: 20,
            showTasksUserName: ''
        })
    }

    onChangeReportDate = ( date ) => {
        this.setState({ reportDate: date });
        this.fetchSingleProjectReport(this.state.selectProjectId, this.dateConvert(date));
        // let reports = this.state.backUpReports
        // reports = reports.filter( r => {
        //     return r['createdAt'].split('T')[0] === new Date(date).toISOString().split('T')[0]
        // } )
        // this.setState({ reports })
        // this.getProjectDetails(this.state.selectProjectId);
    }

    onChangeProject_2 = (e) => {
        this.setState({
            selectProjectId: e.target.value,
        }, () => 
            this.fetchSingleProjectReport(this.state.selectProjectId, this.dateConvert(this.state.reportDate))
        )
    }

    showReportByMembers = async () => {
        this.setState({ showReportByProjects: false })
        // await this.getMembersList()
    }

    showReportByProjects = async () => {
        this.setState({ showReportByProjects: true })
        await this.getAllProjects();
    }

    handleChangeProjectType = async (e) => {
        const { value } = e.target
        this.setState({ 
            selectedProjectType: value 
        }, async () => {
            if(value === 'p-inprogress'){
                await this.getAllProjects();
            }else if(value === 'p-onhold'){
                await this.getAllHoldProjects();
            }else if(value === 'p-completed'){
                await this.getAllCompletedProjects();
            }else if(value === 'p-unassigned'){
                await this.getAllUnassignedProjects();
            }
        })

        
    }


    // ----------------------- Filter Tasks ----------------- //

    getPendingTasks = (tasks) => {
        let copiedTasks = []
        copiedTasks = tasks
        if(this.state.reports.length === 0){
            return copiedTasks.filter(t => t.isCompleted === false)
        }else{
            return copiedTasks.filter(t => t.isCompleted === false && t.member.toString() === this.state.selectedReportUserId.toString())
        }
    }

    getTodayCompletedTasks = (tasks) => {
        let copiedTasks = []
        copiedTasks = tasks
        if(this.state.reports.length === 0){
            return copiedTasks.filter(t => t.isCompleted === true ).filter(t => t.completedOn.split('T')[0] === new Date().toISOString().split('T')[0])
        }else{
            return copiedTasks.filter(t => t.isCompleted === true && t.member.toString() === this.state.selectedReportUserId.toString() ).filter(t => t.completedOn.split('T')[0] === new Date().toISOString().split('T')[0])
        }
    }

    getYesterdayCompletedTasks = (tasks) => {
        let copiedTasks = []
        copiedTasks = tasks
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        if(this.state.reports.length === 0){
            return copiedTasks.filter(t => t.isCompleted === true).filter(t => t.completedOn.split('T')[0] === new Date(yesterday).toISOString().split('T')[0])
        }else{
            return copiedTasks.filter(t => t.isCompleted === true && t.member.toString() === this.state.selectedReportUserId.toString() ).filter(t => t.completedOn.split('T')[0] === new Date(yesterday).toISOString().split('T')[0])
        }
    }

    getPreviousCompletedTasks = (tasks) => {
        let copiedTasks = []
        copiedTasks = tasks
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        if(this.state.reports.length === 0){
            let fTasks = copiedTasks.filter(t => t.isCompleted === true).filter(t => t.completedOn.split('T')[0] < new Date(yesterday).toISOString().split('T')[0])
            return fTasks
        }else{
            let fTasks = copiedTasks.filter(t => t.isCompleted === true && t.member.toString() === this.state.selectedReportUserId.toString()).filter(t => t.completedOn.split('T')[0] < new Date(yesterday).toISOString().split('T')[0])
            return fTasks
        }
    }

    filterTasksOnMemberId = (tasks, userId) => {
        return tasks.filter(t => t.member.toString() === userId.toString())
    }


    handleScrollTasks = e => {
        let element = e.target
        if( Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) <= 1 
            && this.state.openTaskModel 
            && !this.state.loadingMoreTasks 
            && this.state.isMoreTasks
        ){
            this.onLoadMoreTasks()
        }
    }
    
    onLoadMoreTasks = async () => {
        var projectId = this.state.taskProjectId;
        var userId = this.state.selectedReportUserId || ''
        var skip = this.state.skipTasks;
        this.setState({ loadingMoreTasks: true })
        await this.props.loadMoreTasks(projectId, userId, skip)
        this.setState({ loadingMoreTasks: false })
        if( this.props.moreTasks && this.props.moreTasks.response && this.props.moreTasks.tasks.length ){
            this.setState({
                tasks: this.state.tasks.concat(this.props.moreTasks.tasks),
                skipTasks: this.state.skipTasks + 20
            })
        }else{
            this.setState({ isMoreTasks: false })
        }
    }


    showTasksOnModal = () => (
        <Modal.Body onScroll={(e) => this.handleScrollTasks(e)}>
            <h5 className="mt-1 text-warning">Pending Tasks</h5>
            {this.getPendingTasks(this.state.tasks).length > 0  
            ?   <Row className="align-items-center">
                <Col sm={12}>
                    <div className="table-responsive ">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th className="border-right-0">Sr. No.</th>
                                    {this.state.reports.length === 0 && <th className="border-right-0 border-left-0">Assigned To</th>}
                                    <th className="border-right-0 border-left-0">Task Name</th>
                                    <th className="border-right-0 border-left-0">Assigned On</th>
                                    <th className="border-left-0">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.getPendingTasks(this.state.tasks).map((task, idx) => {
                                return(
                                    <tr key={idx}>
                                        <td> {idx+1} </td>
                                        {this.state.reports.length === 0 && <td className="border-right-0 border-left-0">
                                            {task.member ? task.member.fullname || 'N/A' : 'N/A'}
                                        </td>}
                                        <td className="border-right-0 border-left-0">
                                            {task.taskName || 'N/A'}
                                        </td>
                                        <td className="border-right-0 border-left-0">
                                            {this.convertDate(task.createdAt) || 'N/A'}
                                        </td>
                                        <td className="border-left-0">
                                            {!task.isCompleted  ?
                                                <span className="text-warning">Pending</span>
                                                :
                                                <span className="text-success">Completed</span>
                                            }
                                        </td>                                                            
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </Col>        
                </Row>
            :   <Alert variant="info"> Nothing to show </Alert>
            }
            <h5 className="mt-3 text-success">Today Completed Task</h5>
            {this.getTodayCompletedTasks(this.state.tasks).length > 0
                ?   <Row className="align-items-center">
                    <Col sm={12}>
                        <div className="table-responsive ">
                            <table className="table table-bordered table-striped">
                                <thead>
                                <tr>
                                    <th className="border-right-0">Sr. No.</th>
                                    {this.state.reports.length === 0 && <th className="border-right-0 border-left-0">Assigned To</th>}
                                    <th className="border-right-0 border-left-0">Task Name</th>
                                    <th className="border-right-0 border-left-0">Assigned On</th>
                                    <th className="border-right-0 border-left-0">Completed On</th>
                                    <th className="border-left-0">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.getTodayCompletedTasks(this.state.tasks).map((task, idx) => {
                                    return(
                                        <tr key={idx}>
                                            <td> {idx+1} </td>
                                            {this.state.reports.length === 0 && <td className="border-right-0 border-left-0">
                                                {task.member ? task.member.fullname || 'N/A' : 'N/A'}
                                            </td>}
                                            <td className="border-right-0 border-left-0">
                                                {task.taskName || 'N/A'}
                                            </td>
                                            <td className="border-right-0 border-left-0">
                                                {this.convertDate(task.createdAt) || 'N/A'}
                                            </td>
                                            <td className="border-right-0 border-left-0">
                                                {this.convertDate(task.completedOn) || 'N/A'}
                                            </td>
                                            <td className="border-left-0">
                                                {!task.isCompleted  ?
                                                    <span className="text-warning">Pending</span>
                                                    :
                                                    <span className="text-success">Completed</span>
                                                }
                                            </td>                                                            
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </Col>        
                    </Row>
                :   <Alert variant="info"> Nothing to show </Alert>
            }
            <h5 className="mt-3 text-success">Yesterday Completed Task</h5>
            {this.getYesterdayCompletedTasks(this.state.tasks).length > 0
                ?   <Row className="align-items-center">
                    <Col sm={12}>
                        <div className="table-responsive ">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-right-0">Sr. No.</th>
                                        {this.state.reports.length === 0 && <th className="border-right-0 border-left-0">Assigned To</th>}
                                        <th className="border-right-0 border-left-0">Task Name</th>
                                        <th className="border-right-0 border-left-0">Assigned On</th>
                                        <th className="border-right-0 border-left-0">Completed On</th>
                                        <th className="border-left-0">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.getYesterdayCompletedTasks(this.state.tasks).map((task, idx) => {
                                    return(
                                        <tr key={idx}>
                                            <td> {idx+1} </td>
                                            {this.state.reports.length === 0 && <td className="border-right-0 border-left-0">
                                                {task.member ? task.member.fullname || 'N/A' : 'N/A'}
                                            </td>}
                                            <td className="border-right-0 border-left-0">
                                                {task.taskName || 'N/A'}
                                            </td>
                                            <td className="border-right-0 border-left-0">
                                                {this.convertDate(task.createdAt) || 'N/A'}
                                            </td>
                                            <td className="border-right-0 border-left-0">
                                                {this.convertDate(task.completedOn) || 'N/A'}
                                            </td>
                                            <td className="border-left-0">
                                                {!task.isCompleted  ?
                                                    <span className="text-warning">Pending</span>
                                                    :
                                                    <span className="text-success">Completed</span>
                                                }
                                            </td>                                                            
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </Col>        
                    </Row>
                :   <Alert variant="info"> Nothing to show </Alert>
            }
            <h5 className="mt-3 text-success">Previous Completed Task</h5>
            {this.getPreviousCompletedTasks(this.state.tasks).length > 0
                ?   <Row className="align-items-center mb-3">
                    <Col sm={12}>
                        <div className="table-responsive ">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-right-0">Sr. No.</th>
                                        {this.state.reports.length === 0 && <th className="border-right-0 border-left-0">Assigned To</th>}
                                        <th className="border-right-0 border-left-0">Task Name</th>
                                        <th className="border-right-0 border-left-0">Assigned On</th>
                                        <th className="border-right-0 border-left-0">Completed On</th>
                                        <th className="border-left-0">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.getPreviousCompletedTasks(this.state.tasks).map((task, idx) => {
                                    return(
                                        <tr key={idx}>
                                            <td> {idx+1} </td>
                                        {this.state.reports.length === 0 && <td className="border-right-0 border-left-0">
                                                {task.member ? task.member.fullname || 'N/A' : 'N/A'}
                                            </td>}  
                                            <td className="border-right-0 border-left-0">
                                                {task.taskName || 'N/A'}
                                            </td>
                                            <td className="border-right-0 border-left-0">
                                                {this.convertDate(task.createdAt) || 'N/A'}
                                            </td>
                                            <td className="border-right-0 border-left-0">
                                                {this.convertDate(task.completedOn) || 'N/A'}
                                            </td>
                                            <td className="border-left-0">
                                                {!task.isCompleted  ?
                                                    <span className="text-warning">Pending</span>
                                                    :
                                                    <span className="text-success">Completed</span>
                                                }
                                            </td>                                                            
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </Col>        
                    </Row>
                :   <Alert variant="info" className="mb-3"> Nothing to show </Alert>
            }
            {this.state.loadingMoreTasks 
            ?   <div className="d-md-flex justify-content-center"> loading...</div>
            :   null
            }
        </Modal.Body>
    )


    showTasksForChoosenDate = () => (
        <Modal.Body>
            <h5 className="mt-1">{this.state.showTasksUserName} Tasks of {new Date(this.state.reportDate).toLocaleString('default', {day:'numeric', month: 'long', year: 'numeric'})}</h5>
            {this.state.tasks.length 
            ?   <Row className="align-items-center">
                    <Col sm={12}>
                        <div className="table-responsive ">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-right-0">Sr. No.</th>
                                        <th className="border-right-0 border-left-0">Task Name</th>
                                        <th className="border-right-0 border-left-0">Assigned On</th>
                                        <th className="border-right-0 border-left-0">Completed On</th>
                                        <th className="border-left-0">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tasks.map((task, idx) => {
                                        return(
                                            <tr key={idx}>
                                                <td> {idx+1} </td>
                                                <td className="border-right-0 border-left-0">
                                                    {task.taskName || 'N/A'}
                                                </td>
                                                <td className="border-right-0 border-left-0">
                                                    {this.convertDate(task.createdAt) || 'N/A'}
                                                </td>
                                                <td className="border-right-0 border-left-0">
                                                    {task.completedOn ? this.convertDate(task.completedOn) : 'N/A'}
                                                </td>
                                                <td className="border-left-0">
                                                    {!task.isCompleted  ?
                                                        <span className="text-warning">Pending</span>
                                                        :
                                                        <span className="text-success">Completed</span>
                                                    }
                                                </td>                                                            
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Col>        
                </Row>
            :  <Alert variant="info" className="mb-3"> Nothing to show </Alert>
            }
        </Modal.Body>
    )
    // ----------------------- Filter Tasks ----------------- //


    //  ---------------- React Select Functions ------------- //
    // formatGroupLabel = (data) => (
    //     <div style={this.state.groupStyles}>
    //       <span style={{color: '#5937E5', fontSize:'13px'}}>{data.label}</span>
    //       <span style={this.state.groupBadgeStyles}>{data.options.length}</span>
    //     </div>
    // );

    // ------------------React Select Functions----------------- //

    render(){
        const {
            showProjectStep,
            showTimeSpent,
            showTaskList,
            showTestingTimes,
            showUpdate,
            showRemarks,
            fetchReportLoader,
            userRole,
            projectDetail,
            reports,
            projects,
            showReportByProjects,
        } = this.state
        if(!showReportByProjects){
           return (<ReportComponent/>) 
        }else{

            return(  
                <>
                <div className="list-group">
                    <div className="d-md-flex align-items-md-center justify-content-md-between mb-3">
                        <h3 className="mb-0"> Reports </h3>
                        {(userRole === 'admin' || userRole === 'super_admin') &&
                        <div className="dateSelect d-md-flex justify-content-end align-items-center w-auto">
                            <Button variant="primary" onClick={() => this.showReportByMembers()}> Check Reports by Members</Button>
                        </div>}
                    </div>
                    { (userRole !== 'super_admin') &&
                        <>
                            <div className="projectTable shadow-lg p-3 animate__animated animate__fadeInUp mb-3">
                                <div className="d-lg-flex align-items-md-center">
                                    <h3 className="mr-3">Submit Report</h3>
                                    { showProjectStep && this.showProjectStep() }
                                </div>
                            </div>
                            { showTimeSpent && this.showTimeSpent() }
                            { showTaskList && this.showTaskList() }
                            { showTestingTimes && this.showTestingTimes() }
                            { showUpdate && this.showUpdate() }
                            { showRemarks && this.showRemarks() }
                            {/* { showPersonalInfoStep && this.showPersonalInfoStep() } */}
                        </>
                    }
                </div>
                <div className="projectTable shadow-lg p-3 animate__animated animate__fadeInUp overflowX">
                    <div className="d-xl-flex align-items-md-center justify-content-md-between mb-3">
                        <h3 className="mb-md-2 mb-lg-0">Showing Reports By Project</h3>
                        <div className="dateSelect d-md-flex justify-content-lg-end justify-content-between align-items-center">
                            {((userRole === 'super_admin' || userRole === 'admin') && ( showReportByProjects === true )) &&
                            <Form inline className="ml-xl-5">
                                <Form.Control as="select" id="projectType" style={{width:'auto'}} className={`mr-3`} custom onChange={(e) => this.handleChangeProjectType(e)} value={this.state.selectedProjectType}>
                                    <option value="" disabled> Select In-progress/Completed</option>
                                    <option value="p-inprogress" style={{ color:"#ffbf00"}}> In Progress </option>
                                    <option value="p-onhold" style={{ color:"#227E9E"}}> On Hold </option>
                                    <option value="p-completed" style={{ color:"#42904e"}}> Completed </option>
                                    <option value="p-unassigned"> Un Assigned </option>
                                </Form.Control>
                                <Form.Control as="select" id="inlineFormCustomMemberSelect1" style={{width:'auto'}} className="mr-3" onChange={this.onChangeProject_2} value={this.state.selectProjectId} custom>
                                    {(projects && projects.length > 0) 
                                        ? 
                                        <>
                                            <option value="" disabled> Select Project</option>
                                            {projects.map((project, idx)=> {
                                                return (
                                                    <option value={project._id} key={idx}> {project.projectName} </option>
                                                )
                                            })}
                                        </>
                                        :
                                        <option value="" disabled>No Project to Show</option>
                                    }
                                </Form.Control>
                            </Form>
                                   
                            } 
                            <span className="mr-2">Select Date</span>
                            <div className="relative">
                                <DatePicker selected={this.state.reportDate} onChange={date => this.onChangeReportDate(date)} maxDate={new Date()}/>                                
                            </div>
                        </div>
                    </div>
                    {fetchReportLoader 
                    ?
                        <div className="text-center circleSpinnner mt-3">
                            <Spinner animation="border" />
                        </div>
                    :
                    (reports && reports.length > 0)
                        ?   <div className="table-responsive ">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th className="border-right-0">{showReportByProjects ? 'Member': 'Reported Date'}</th>
                                        <th className="border-right-0 border-left-0">Reported At</th>
                                        <th className="border-right-0 border-left-0">Project Name</th>
                                        <th className="border-right-0 border-left-0">Client/Contract</th>
                                        {(userRole === 'super_admin' || userRole === 'admin') && <th className="border-right-0 border-left-0">Label</th>}
                                        <th className="border-right-0 border-left-0">Time Spent</th>
                                        <th className="border-right-0 border-left-0 four-child"> Did Client Updated</th>
                                        <th className="border-right-0 border-left-0 four-child"> How Many Time Tested</th>
                                        <th className="border-right-0 border-left-0"> Remarks </th>
                                        <th className="border-left-0"> Tasks Status </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {reports.map((data, idx)=> {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                {showReportByProjects ? (data.user && data.user.fullname) ? data.user.fullname || 'N/A' : 'N/A' : this.convertDate(data.createdAt)}
                                            </td>
                                            <td className="border-right-0 border-left-0">
                                                {this.convertTime(data.createdAt)}
                                            </td>
                                            <td className="border-right-0 border-left-0">{data.project ? data.project.projectName || 'N/A' : 'N/A'}</td>
                                            <td className="border-right-0 border-left-0">{data.project ? data.project.isClientContract || 'N/A' : 'N/A'}</td>
                                            {(userRole === 'super_admin' || userRole === 'admin') &&  
                                                <td className="border-right-0 border-left-0">
                                                    { !data.project 
                                                        ?
                                                        'N/A'
                                                        :
                                                        <span className="spanLabel" style={{backgroundColor:data.project ? data.project.label : '', cursor:'pointer', padding:'0.25rem 2.5rem'}} onClick={()=> data.project ? this.handleChangeLabel(data.project._id) : null }> </span> 
                                                    }   
                                                </td>
                                            }
                                            <td className="border-right-0 border-left-0"> { data.timeSpent || 'N/A'}</td>
                                            <td className="border-right-0 border-left-0">
                                                 { data.didClientUpdated || 'N/A'}
                                                {data.messageForClient && <i className="ml-1 fa fa-eye" style={{cursor:'pointer'}} onClick={()=> this.setState({showClientMessage: data.messageForClient, openClientMessageModel: true})}/>}
                                            </td>
                                            <td className="border-right-0 border-left-0"> { data.workTestingTimes ? `${data.workTestingTimes} Times` : 'N/A'} </td>
                                            <td className="border-right-0 border-left-0">
                                                {data.remarks && data.remarks.trim().length < 5
                                                    ?
                                                    data.remarks
                                                    :
                                                    <i className="ml-1 fa fa-eye" style={{cursor:'pointer'}} onClick={()=> this.setState({showClientMessage: data.remarks, remarksModel: true, openClientMessageModel: true})}/>
                                                }
                                            </td>
                                            <td>
                                                <Button 
                                                    variant="primary" 
                                                    onClick={()=> this.setState({ 
                                                            openTaskModel: true, 
                                                            tasks: (data.project && data.project.taskList.length > 0) ? this.filterTasksOnMemberId(data.project.taskList, data.user._id) : [], 
                                                            selectedReportUserId: data.user ? data.user._id : '', 
                                                            task__projectName: data.project ? data.project.projectName || 'N/A' : 'N/A',
                                                            taskProjectId: data.project._id,
                                                            showTasksUserName: data.user ? data.user.fullname : ''
                                                        })
                                                    }
                                                > 
                                                View 
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            </div>
                        :   Object.keys(projectDetail).length !== 0 
                                ?   <div className="table-responsive ">
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="border-right-0">{showReportByProjects ? 'Member': 'Reported Date'}</th>
                                                    <th className="border-right-0 border-left-0">Reported At</th>
                                                    <th className="border-right-0 border-left-0">Project Name</th>
                                                    <th className="border-right-0 border-left-0">Client/Contract</th>
                                                    {(userRole === 'super_admin' || userRole === 'admin') && <th className="border-right-0 border-left-0">Label</th>}
                                                    <th className="border-right-0 border-left-0">Time Spent</th>
                                                    <th className="border-right-0 border-left-0 four-child"> Did Client Updated</th>
                                                    <th className="border-right-0 border-left-0 four-child"> How Many Time Tested</th>
                                                    <th className="border-right-0 border-left-0"> Remarks </th>
                                                    <th className="border-left-0"> Tasks Status </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> N/A </td>
                                                    <td className="border-right-0 border-left-0"> N/A </td>
                                                    <td className="border-right-0 border-left-0"> {projectDetail ? projectDetail.projectName || 'N/A' : 'N/A'} </td>
                                                    <td className="border-right-0 border-left-0">{projectDetail ? projectDetail.isClientContract || 'N/A' : 'N/A'} </td>
                                                    {(userRole === 'super_admin' || userRole === 'admin') &&  
                                                        <td className="border-right-0 border-left-0">
                                                        <span className="spanLabel" style={{backgroundColor:projectDetail ? projectDetail.label || '' : '', cursor:'pointer', padding:'0.25rem 2.5rem'}} onClick={()=> projectDetail ? this.handleChangeLabel(projectDetail._id) : null }></span>    
                                                    </td>}
                                                    <td className="border-right-0 border-left-0"> N/A </td>
                                                    <td className="border-right-0 border-left-0">
                                                        <i className="ml-1 fa fa-eye" disabled/>
                                                    </td>
                                                    <td className="border-right-0 border-left-0"> N/A </td>
                                                    <td className="border-right-0 border-left-0">
                                                        N/A
                                                    </td>
                                                    <td>
                                                        <Button 
                                                            variant="primary" 
                                                            onClick={()=> this.setState({ 
                                                                    openTaskModel: true, 
                                                                    tasks: (projectDetail && projectDetail.taskList.length > 0) ? projectDetail.taskList : [], 
                                                                    task__projectName: projectDetail ? projectDetail.projectName || 'N/A' : 'N/A',
                                                                    taskProjectId: projectDetail._id
                                                                })
                                                            }
                                                        > 
                                                            View 
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                :   <p className="m-0 p-2 text-center border">  Nothing To Show </p>
                    }
                    </div>
                    <Modal id="clientMessageModel" show = { this.state.openClientMessageModel } onHide={ this.onCloseClientMessageModel }>
                        <Modal.Header closeButton>
                            <Modal.Title>{this.state.remarksModel ? 'Remarks': 'Message For Client'} </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="align-items-center">
                                <Col sm={12}>
                                    <p>
                                        <pre>
                                            {this.state.showClientMessage}
                                        </pre>
                                    </p>
                                </Col>        
                            </Row>
                        </Modal.Body>
                    </Modal>
                    {this.state.openTaskModel 
                    ?   <Modal id="TaskModel" show = { this.state.openTaskModel } onHide={ this.onCloseTaskModel }>
                            <Modal.Header closeButton>
                                <Modal.Title>Task Status For Project: "{this.state.task__projectName}"</Modal.Title>
                            </Modal.Header>
                            {this.state.reports.length === 0 
                                ? this.showTasksOnModal() 
                                : this.showTasksForChoosenDate()
                            }
                        </Modal>
                    :   null
                    }
                </>    
            )
        }
    }
}


const mapStateToProps = state => {
    const { project, auth, member, report } = state
    return {
        auth: auth,
        membersList: member.member,
        projectFetch:project.projectFetch,
        reportAdd: report.reportAdd,
        reportFetch: report.reportFetch,
        projectReport: report.projectReport,
        progressProject: project.progressFetch,
        holdProject: project.holdFetch,
        completedProject: project.completedFetch,
        unAssignedAll: project.unassignedAll,
        projectDetail: project.projectDetail,
        moreTasks: project.moreTasksLoaded,
    }
}
const  dispatchAction =  { 
    fetchSingleUserProjects, 
    fetchAllInprogressProjects,
    fetchHoldProjects,
    fetchCompletedProjects,
    getUnassignedAllProjects, 
    addReport, 
    fetchMembers, 
    getSingleUserReports, 
    getSingleProjectReport ,
    getSingleProject,
    loadMoreTasks
}

export default connect(mapStateToProps, dispatchAction)(ReportByProjectComponent)