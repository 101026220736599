import { SEND_INVITE, FETCH_MEMBERS, DELETE_MEMBER, EDIT_MEMBER, UPDATE_USER_ACCOUNT_STATUS } from './types';
import { apiRequest_2, apiRequest, checkTokenExpire } from './actionResponseHandler';
import authHeader from '../helpers/auth-header'
import { toast } from "react-toastify";
toast.configure()

export const sendInvitation = data => async dispatch => {
    try {
        const requestOptions = {
            method: 'POST',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
            },  
            body: JSON.stringify(data)
        }
        const result = await apiRequest_2( "/invite", requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: SEND_INVITE, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const fetchMembers = () => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },  
        }
        const result = await apiRequest( "/users", requestOptions );
        // console.log(members)
        checkTokenExpire(result)
        await dispatch({ type: FETCH_MEMBERS, payload: result })
    } catch (error) {
        // console.log(error)
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

export const deleteSingleMember = memberId => async dispatch => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },  
        }
        const result = await apiRequest( `/user/${memberId}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: DELETE_MEMBER, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}


export const editSingleMember = (memberId, data) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PUT',
            headers:{
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            }, 
            body: JSON.stringify(data)
        }
        const result = await apiRequest( `/user/${memberId}`, requestOptions );
        checkTokenExpire(result)
        await dispatch({ type: EDIT_MEMBER, payload: result })
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}


export const updateUserAccountStatus = (userId) => async dispatch => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest( `/user/${userId}/account/status`, requestOptions );
        checkTokenExpire(result)
        await dispatch ({ type: UPDATE_USER_ACCOUNT_STATUS, payload: result})
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}


