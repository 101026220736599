import { STATUS_CREATE, STATUS_FETCH, STATUS_DELETE, STATUS_EDIT } from "../actions/types";

const initialState = { createResponse: '', fetchResponse:'', deleteResponse:'', editResponse:''  }

export default ( state = initialState, action ) => {

    switch (action.type) {
        case STATUS_CREATE:
            return {
                ...state,
                createResponse: action.payload
            }
        case STATUS_FETCH:
            return {
                ...state,
                fetchResponse: action.payload
            }
        case STATUS_DELETE:
            return {
                ...state,
                deleteResponse: action.payload
            }
        case STATUS_EDIT:
            return {
                ...state,
                editResponse: action.payload
            }
        default: return state;
    }

}
