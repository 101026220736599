import { combineReducers } from "redux";
import authenticateReducer from './authenticateReducer';
import statusReducers from "./statusReducer";
import memberReducer from "./memberReducer";
import performanceReducer from "./performanceReducer";
import projectReducer from "./projectReducer";
import reportReducer from "./reportReducer";
import tutorialReducer from './tutorialReducer';
import userReducer from './userReducer';
import notesReducer from './notesReducer';
import holidayReducer from './holidayReducer';
import notificationReducer from './notificationReducer'

export default combineReducers({
    status: statusReducers,
    auth: authenticateReducer,
    member:memberReducer,
    performance: performanceReducer,
    project:projectReducer,
    report:reportReducer,
    tutorial: tutorialReducer,
    user:userReducer,
    notes:notesReducer,
    holiday:holidayReducer,
    notify:notificationReducer
})