import React, { Component } from 'react';
import { Button, Col, Form, FormControl, InputGroup, Modal, Row, Spinner, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchPerformance, editPerformance, deletePerformance } from '../../actions/performanceAction';
import { fetchMembers } from '../../actions/memberAction';
import PerformanceForAdminComponent from './ShowOwnPerformanceForAdmin';
import DatePicker from "react-datepicker";
import swal from 'sweetalert';
import { toast } from "react-toastify";
toast.configure()

class PerformanceComponent extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            reportMonth:new Date(),
            userRole:'',
            identifying:'',
            permissions:{},
            membersList:[],
            membersListLoader: false,
            performance:[],
            backupPerformance:[],
            userId:'',
            memberId:'',
            userRoleForPerformance:'',
            performanceLoader: true,
            onMemberChangeShowTable: false,
            editPerformanceLoader: false,
            singlePerformanceData:{},
            selectedPerformance:[],

            //  ---- Performance Edit ---//
            openEditPerformanceModel: false,
            averagePerformance:0,
            averageAttendance:0,
            showAdminMyPerformance: false,
        }
    }

    componentDidMount(){
        if(this.props.auth && this.props.auth.loggedIn){
            if(this.props.auth.user && this.props.auth.user.permissions && this.props.auth.user.permissions.performance){
                this.setState({ 
                    userRole: this.props.auth.user.role, 
                    permissions:{...this.props.auth.user.permissions.performance},
                    userId: this.props.auth.user.userId,
                    identifying: this.props.auth.user.identifying,
                }, async ()=> {
                    if((this.state.userRole === 'super_admin' || this.state.userRole === 'admin')){
                        await this.getMembersList()
                    }else{
                        await this.setUserRoleForOthersuser(this.state.identifying)
                        await this.getUserOwnPerformance(this.state.userId)
                        this.onChangePerformanceMonth(this.state.reportMonth)
                    }
                })
            }
        }
    }

    //  --------------  It is used to store userRole is SEO/Bidder/Hr/Qa to show specific column
    setUserRoleForOthersuser = (role) => {
        this.setState({ userRoleForPerformance: role})
    }
 
    onChangePerformanceMonth = (date) => {
        this.setState({ reportMonth: date })
        let performance =  this.state.backupPerformance;
        let i = 0
        performance = performance.filter( p => {
            // console.log('i --->', i++,'Month ---> ', new Date(p['createdAt']).getMonth()+1, new Date(date).getMonth()+1, ' Year ---> ', new Date(p['createdAt']).getFullYear(), new Date(date).getFullYear() )
            return (new Date(p['createdAt']).getMonth()+1 === new Date(date).getMonth()+1) && (new Date(p['createdAt']).getFullYear() === new Date(date).getFullYear())
        })

        let averagePerformance = performance.length ? performance[0].currentMonthPerformance : 0
        let averageAttendance = performance.length ? performance[0].currentMonthAttendance.toFixed(2) : 0
        this.setState({ performance, averagePerformance, averageAttendance })
    }

    getUserOwnPerformance = async ( userId ) => {
        this.setState({ performanceLoader: true })
        await this.props.fetchPerformance( userId );
        // console.log(this.props.userPerformance)
        if(this.props.userPerformance.response){
            this.setState({
                performance: this.props.userPerformance.performance,
                averagePerformance: this.props.userPerformance.performance.length ? this.props.userPerformance.performance[0].currentMonthPerformance : 0,
                averageAttendance: this.props.userPerformance.performance.length ? this.props.userPerformance.performance[0].currentMonthAttendance.toFixed(2) : 0,
                backupPerformance: this.props.userPerformance.performance,
                performanceLoader: false
            })
        }else{
            toast.dismiss()
            toast.error(this.props.userPerformance.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ performanceLoader: false })
        }
    }

    getMembersList = async () => {
        this.setState({ membersListLoader: true })
        await this.props.fetchMembers();
        if(this.props.membersList.response){
            let memberList  = (this.props.membersList.users && this.props.membersList.users.length) ? this.props.membersList.users.filter(x=> x.role !== 'super_admin') : [] 
            this.setState({
                membersList: memberList, //this.props.membersList.users,
                membersListLoader: false,
                memberId: memberList.length ? memberList[0]._id : '', //this.props.membersList.users.length ? this.props.membersList.users[0]._id :'',
                userRoleForPerformance: memberList.length ?  memberList[0].identifying : '' ,//this.props.membersList.users.length ? this.props.membersList.users[0].role : '',
            }, async ()=> {
                await this.getUserOwnPerformance(this.state.memberId)
                this.onChangePerformanceMonth(this.state.reportMonth)
            })
        }else{
            // toast.dismiss();
            // toast.error(this.props.membersList.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ membersListLoader: false })
        } 
    }

    onChangeMember = async ( e ) => {
        this.setState({ 
            onMemberChangeShowTable: true, 
            performanceLoader: true, 
            memberId: e.target.value,
            userRoleForPerformance: e.target[e.target.selectedIndex].getAttribute('data-userrole')
        }, async ()=> {
            await  this.getUserOwnPerformance(this.state.memberId)
            this.onChangePerformanceMonth(this.state.reportMonth)
        }) 
    }


    onCloseEditPerformanceModel = () => {
        this.setState({ openEditPerformanceModel: false, singlePerformanceData:{} })
    }

    convertDate = (date) => {
        // console.log(new Date(date).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'}))
        var d = new Date(date);
        var year = (new Date().getFullYear() === d.getFullYear()) ? '' : ", " +  d.getFullYear()
        var monthName = d.toLocaleString('default', { month: 'long' })
        var weekDays = d.toLocaleString('default', { weekday:'long'})
        let dt = d.getDate() < 10 ? '0'+ d.getDate() : d.getDate()
        return  weekDays + ", " + dt + " " + (monthName) +  year
    }

    // convertDate = (date) => {
    //     var d = new Date(date);
    //     var gsDayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    //     var year = (new Date().getFullYear() === d.getFullYear()) ? '' : ", " + d.getFullYear();
    //     var weekDay = (new Date().getFullYear() === d.getFullYear()) ? gsDayNames[d.getDay()] + ', ' : '';
    //     var monthName = d.toLocaleString('default', { month: 'short' })
    //     return weekDay + (monthName) + " " + d.getDate() + year
    // }

    handleInputChange = (e) => {
        const { name, value } = e.target
        let singlePerformanceData = this.state.singlePerformanceData
        singlePerformanceData[name] = value
        this.setState({ singlePerformanceData })
    }

    handleSubmitPerformance = async (e) => {
        e.preventDefault();
        this.setState({ editPerformanceLoader: true })
        const { singlePerformanceData } = this.state
        let data =  {}
        data.id = singlePerformanceData['_id']
        data.status = singlePerformanceData['status']
        data.uor = Number(singlePerformanceData['uor'])
        data.punctuality = Number(singlePerformanceData['punctuality']) 
        data.qualityWork = Number(singlePerformanceData['qualityWork'])
        data.taskCompletion = Number(singlePerformanceData['taskCompletion'])
        data.reports = Number(singlePerformanceData['reports'])
        data.repetation = Number(singlePerformanceData['repetation'])
        data.timeConsume = Number(singlePerformanceData['timeConsume'])
        data.behaviour = Number(singlePerformanceData['behaviour'])
        data.responsibility = Number(singlePerformanceData['responsibility'])

        if(this.state.userRoleForPerformance === 'seo' || this.state.userRoleForPerformance === 'hr' || this.state.userRoleForPerformance === 'bidders' || this.state.userRoleForPerformance === 'qualityAuditor'){
            data.conversion = Number(singlePerformanceData['conversion'])
        }
        // console.log(data)
        await this.props.editPerformance(data);
        if(this.props.ePerformance.response){
            toast.success(this.props.ePerformance.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ editPerformanceLoader: false })
            await this.getUserOwnPerformance(this.state.memberId)
            await this.onChangePerformanceMonth(this.state.reportMonth)
            this.onCloseEditPerformanceModel()
        }else{
            toast.error(this.props.ePerformance.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ editPerformanceLoader: false })
        }

    }

    onDeletePerformance = async () => {
        // console.log(this.state.selectedPerformance)
        swal({
            title: "Are you sure?",
            text: "Once deleted, Performance won't be revert!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
                
                swal({ title: "Keep Patience", text: "Deleting...", button: false });
                let data = {
                    performanceId: this.state.selectedPerformance
                }
                await this.props.deletePerformance(data)
                if(this.props.dPerformance.response){
                    await this.getUserOwnPerformance(this.state.memberId)
                    await this.onChangePerformanceMonth(this.state.reportMonth)
                    swal({ title: "Deleted!", text: this.props.dPerformance.message, icon: "success" })
                    this.setState({ selectedPerformance: []})
                }else{
                    swal({ title: "Something went wrong!", text: this.props.dPerformance.message, icon: "error" })
                }

            }
          });
    }

    onPerformanceSelect = (e) => {
        const { checked, value } = e.target
        // console.log(checked, value)
        let selectedPerformance = this.state.selectedPerformance;
        if(checked){
            selectedPerformance.push(value);
        }else{
            selectedPerformance = selectedPerformance.filter(e => e !== value);
        }
        
        this.setState({
            selectedPerformance
        })
    }

    render(){
        const { reportMonth, showAdminMyPerformance, averagePerformance, userRoleForPerformance, performanceLoader, userRole, permissions, editPerformanceLoader, singlePerformanceData, membersList, performance, openEditPerformanceModel, onMemberChangeShowTable } = this.state
        let performanceHighlight = ''
        let fontColor = ''

        if( averagePerformance >= 90){
            performanceHighlight = '#5bba09'  // --  green
            fontColor = '#ffffff'               // -- white
        }else if( averagePerformance <= 89 && averagePerformance >= 70){
            performanceHighlight = '#f7ef11' // -- yellow
            fontColor = '#000000'      // --- black
        }else{
            performanceHighlight = '#d31034' // -- red
            fontColor = '#ffffff'      // --- white
        }

        if(showAdminMyPerformance){
            return (<PerformanceForAdminComponent/>)
        }else{
            return(  
                <>
                <Modal show={ openEditPerformanceModel } onHide={ this.onCloseEditPerformanceModel }>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Performance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <Row>
                                <Col sm={12}>
                                    <form onSubmit={ this.handleSubmitPerformance}>
                                        <div className="form-group">
                                            <label htmlFor="exampleInpu1">Status </label>
                                            <select className="custom-select mr-sm-2" id="status" name="status" onChange={this.handleInputChange} defaultValue={singlePerformanceData['status']}>
                                                <option value="">Select</option>
                                                <option value="T">Two Hours</option>
                                                <option value="H">Half Day</option>
                                                <option value="S">Short Leave</option>
                                                <option value="P">Present</option>
                                                <option value="A">Absent</option>
                                                <option value="O">Holiday</option>
                                                <option value="W">Weekly Off</option>
                                                {/* <option value="OW"> Holiday/Weekly Off </option> */}
                                            </select>
                                        </div>
                                        <div classname="form-group">
                                            <label htmlFor="exampleInput2">Punctuality</label>
                                            <select className="custom-select mr-sm-2" id="punctuality" name="punctuality" onChange={this.handleInputChange} defaultValue={singlePerformanceData['punctuality']}>
                                                <option value="0"> 0 </option>
                                                <option value="1"> 1 </option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="uor">Project Understanding</label>
                                            <select className="custom-select mr-sm-2" id="uor" name="uor" onChange={this.handleInputChange} defaultValue={singlePerformanceData['uor']}>
                                            <option value="0"> 0 </option>
                                                <option value="1"> 1 </option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="repetations"> Repetations </label>
                                            <select className="custom-select mr-sm-2" id="repetation" name="repetation" onChange={this.handleInputChange} defaultValue={singlePerformanceData['repetation']}>
                                            <option value="0"> 0 </option>
                                                <option value="1"> 1 </option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="tc"> Time Consume </label>
                                            <select className="custom-select mr-sm-2" id="timeConsume" name="timeConsume" onChange={this.handleInputChange} defaultValue={singlePerformanceData['timeConsume']}>
                                            <option value="0"> 0 </option>
                                                <option value="1"> 1 </option>
                                            </select>
                                        </div>
    
                                        <div className="form-group">
                                            <label htmlFor="qw"> Quality Work </label>
                                            <select className="custom-select mr-sm-2" id="qw" name="qualityWork" onChange={this.handleInputChange} defaultValue={singlePerformanceData['qualityWork']}>
                                            <option value="0"> 0 </option>
                                                <option value="1"> 1 </option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="taskC"> Task Completetion </label>
                                            <select className="custom-select mr-sm-2" id="taskCompletion" name="taskCompletion" onChange={this.handleInputChange} defaultValue={singlePerformanceData['taskCompletion']}>
                                                <option value="0"> 0 </option>
                                                <option value="1"> 1 </option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="reports"> Reporting </label>
                                            <select className="custom-select mr-sm-2" id="reports" name="reports" onChange={this.handleInputChange} defaultValue={singlePerformanceData['reports']}>
                                            <option value="0"> 0 </option>
                                                <option value="1"> 1 </option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="behaviour"> Behaviour </label>
                                            <select className="custom-select mr-sm-2" id="behaviour" name="behaviour" onChange={this.handleInputChange} defaultValue={singlePerformanceData['behaviour']}>
                                                <option value="0"> 0 </option>
                                                <option value="1"> 1 </option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="Responsibility"> Responsibility </label>
                                            <select className="custom-select mr-sm-2" id="Responsibility" name="responsibility" onChange={this.handleInputChange} defaultValue={singlePerformanceData['responsibility']}>
                                                <option value="0"> 0 </option>
                                                <option value="1"> 1 </option>
                                            </select>
                                        </div>
                                        {(userRoleForPerformance === 'seo' || userRoleForPerformance === 'hr' || userRoleForPerformance === 'bidders' || userRoleForPerformance === 'qualityAuditor') &&
                                        <div className="form-group">
                                            <label htmlFor="conversion"> conversion </label>
                                            <select className="custom-select mr-sm-2" id="conversion" name="conversion" onChange={this.handleInputChange} defaultValue={singlePerformanceData['conversion']}>
                                                <option value="0"> 0 </option>
                                                <option value="1"> 1 </option>
                                            </select>
                                        </div>}
                                        <div className="form-group" style={{float: "right"}}>
                                            <button type="submit" class="btn btn-primary" disabled={ editPerformanceLoader }>{ editPerformanceLoader ? 'Saving...': 'Save'}</button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                    </Modal.Body>
                </Modal>
                <div className="d-lg-flex justify-content-md-between mb-3">
                    <h3 className="m-0">Performance</h3>
                   
                    <div className="dateSelect d-md-flex justify-content-lg-end justify-content-between w-auto">
                        {(userRole === 'super_admin' || userRole === 'admin') &&
                        <Form inline className="mr-md-4 mb-3 mb-md-0 mb-lg-0">
                            <Form.Control as="select" id="inlineFormCustomMemberSelect"  onChange={this.onChangeMember} value={this.state.memberId} custom>
                                {(membersList && membersList.length > 0)
                                    ?
                                    <>
                                    <option value='' disabled > Select member</option>
                                        {membersList.map((member, idx) => {
                                            if(member.role !== 'super_admin')
                                                return (
                                                    <option value={member._id} data-userrole={member.identifying} key={idx}> { member.fullname } - {member.designation} </option>
                                                )
                                            })}
                                        </>
                                        :
                                    <option value="" disabled >No Members</option>
                                }
                            </Form.Control>
                        </Form>}
                        <Form inline>
                            <Form.Label htmlFor="selectMonth" className="mr-2">
                                Select Month: 
                            </Form.Label>
                            <div className="relative">
                            <DatePicker
                                dateFormat="MMMM yyyy"
                                showMonthYearPicker
                                selected={this.state.reportMonth}
                                onChange={this.onChangePerformanceMonth}
                            />
                            </div>
                        </Form>
                        {(userRole === 'admin') && <Button className="ml-2 mr-2" onClick={() => this.setState({ showAdminMyPerformance: true})}> My Performance </Button>}
                    </div>            
                </div>
                    {performanceLoader 
                    ?
                        <div className="text-center circleSpinnner"><Spinner animation="border" /></div>
                    :
                    (!(userRole === 'super_admin' || userRole === 'admin'))
                        ?
                        <>    
                        { (performance && performance.length > 0) && 
                        <Row className="mt-2">
                            <Col md={6}>
                                <h5> 
                                    <span 
                                    style={{
                                        backgroundColor: performanceHighlight,
                                        color: fontColor, 
                                        padding:'0.25rem 0.5rem', 
                                        borderRadius:'5px'
                                    }}>  
                                    Performance: <span className="ml-2"> { this.state.averagePerformance }% </span> 
                                    </span> 
                                </h5> 
                            </Col>
                            <Col md={6} className="text-md-right">
                                <h5>Attendance: <span className="ml-2"> { this.state.averageAttendance }% </span> </h5> 
                            </Col>
                        </Row>}
                        <div className="projectTable shadow-lg mt-2 p-3 animate__animated animate__fadeInUp">
                            {(performance && performance.length > 0)
                                ?              
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="four-child">Date</th>
                                                    <th className="two-child text-center">Status</th>
                                                    <th className="three-child text-center">Punctuality</th>
                                                    <th className="text-center four-child"> Project Understanding</th>
                                                    <th className="text-center"> Repetations </th>
                                                    <th className="six-child text-center"> Time Consume  </th>
                                                    <th className="five-child text-center">Quality Work</th>
                                                    <th className="four-child text-center"> Task Completetion </th>
                                                    <th className="seven-child text-center">Reporting</th>
                                                    <th className="five-child text-center">Behaviour</th>
                                                    <th className="text-center"> Responsibility  </th>
                                                    {(userRoleForPerformance === 'seo' || userRoleForPerformance === 'hr' || userRoleForPerformance === 'bidders' || userRoleForPerformance === 'qualityAuditor') &&
                                                    <th className="text-center"> Conversion  </th>
                                                    }
                                                    <th className="eight-child text-center">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {performance.map((performance, idx ) => {
                                                    let st = false
                                                    if(performance.status === 'W' || performance.status === 'O' || performance.status === 'OW' || performance.status === 'A'){
                                                        st  = true
                                                    }
                                                    return (
                                                        <tr key={idx}>
                                                            <td> { this.convertDate(performance.createdAt) } </td>
                                                            {/* <td> { performance.createdAt.split('T')[0] } </td> */}
    
                                                            <td className="text-center"> 
                                                                { (performance.status === 'A') && 'Absent' }  
                                                                { (performance.status === 'P') && 'Present' }
                                                                { (performance.status === 'S') && 'Short Leave' } 
                                                                { (performance.status === 'H') && 'Half Day' }
                                                                { (performance.status === 'T') && 'Two Hours' }
                                                                { (performance.status === 'W') && 'Weekly Off' }
                                                                { (performance.status === 'O') && 'Holiday' }
                                                                { (performance.status === 'OW') && 'Weekly Off' }
    
                                                            </td>
                                                            <td className="text-center">
                                                                {st ? 'N/A' : performance.punctuality}
                                                            </td>
                                                            <td className="text-center">    
                                                                {st ? 'N/A' : performance.uor}
                                                            </td>
                                                            <td className="text-center">    
                                                                {st ? 'N/A' : performance.repetation}
                                                            </td>
                                                            <td className="text-center">    
                                                                {st ? 'N/A' : performance.timeConsume}
                                                            </td>
                                                            <td className="text-center">    
                                                                {st ? 'N/A' : performance.qualityWork}
                                                            </td>
                                                            <td className="text-center">    
                                                                {st ? 'N/A' : performance.taskCompletion }
                                                            </td>
                                                            <td className="text-center">    
                                                                {st ? 'N/A' : performance.reports}
                                                            </td>
                                                            <td className="text-center">    
                                                                {st ? 'N/A' : performance.behaviour }
                                                            </td>
                                                            <td className="text-center">    
                                                                {st ? 'N/A' : performance.responsibility }
                                                            </td>
                                                            {(userRoleForPerformance === 'seo' || userRoleForPerformance === 'hr' || userRoleForPerformance === 'bidders' || userRoleForPerformance === 'qualityAuditor') &&
                                                                <td className="text-center">    
                                                                    {st ? 'N/A' : performance.conversion }
                                                                </td>
                                                            }
                                                            <td className="text-center">    
                                                                {st ? 'N/A' : `${performance.total}%` }
                                                            </td>                                                    
                                                        </tr>
                                                    )
                                                })}                                
                                            </tbody>                             
                                        </table>
                                    </div>
                                    :
                                    <p className="m-0 p-2 text-center border">
                                        Nothing To Show
                                    </p>
                            }                        
                        </div>  
                        </>
    
                        :
                        <>
                            {(performance && performance.length > 0) && <Row className="mt-2">
                                <Col md={6}>
                                    <h5> 
                                        <span 
                                        style={{
                                            backgroundColor: performanceHighlight,
                                            color: fontColor, 
                                            padding:'0.25rem 0.5rem', 
                                            borderRadius:'5px'
                                        }}>  
                                        <strong> Performance: </strong> <span className="ml-2"> { this.state.averagePerformance }% </span> 
                                        </span> 
                                    </h5> 
                                </Col>
                                <Col md={6} className="text-md-right">
                                    <h5> <strong> Attendance: </strong> <span className="ml-2"> { this.state.averageAttendance }% </span> </h5> 
                                </Col>
                            </Row>}
                            <div className="projectTable shadow-lg mt-2 p-3 animate__animated animate__fadeInUp">
                                {(performance && performance.length > 0)
                                ?
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th className="four-child">Date</th>
                                                <th className="two-child text-center">Status</th>
                                                <th className="three-child text-center">Punctuality</th>
                                                <th className="text-center four-child"> Project Understanding</th>
                                                <th className="text-center"> Repetations </th>
                                                <th className="six-child text-center"> Time Consume  </th>
                                                <th className="five-child text-center">Quality Work</th>
                                                <th className="four-child text-center"> Task Completetion </th>
                                                <th className="seven-child text-center">Reporting</th>
                                                <th className="five-child text-center">Behaviour</th>
                                                <th className="text-center"> Responsibility  </th>
                                                {(userRoleForPerformance === 'seo' || userRoleForPerformance === 'hr' || userRoleForPerformance === 'bidders' || userRoleForPerformance === 'qualityAuditor') &&
                                                    <th className="text-center"> Conversion  </th>
                                                }
                                                <th className="eight-child text-center">Total</th>
                                                <th className="nine-child text-center"> 
                                                    Action
                                                    {/* <Button className="ml-2" variant="danger" disabled={!(userRole === 'super_admin' || userRole === 'admin') } onClick={()=> this.onDeletePerformance() }> Delete </Button>  */}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {performance.map((performance, idx ) => {
                                                let st = false
                                                if(performance.status === 'W' || performance.status === 'O' || performance.status === 'OW' || performance.status === 'A'){
                                                    st  = true
                                                }
                                                return (
                                                    <tr key={idx}>
                                                        <td> { this.convertDate(performance.createdAt) } </td>
                                                        <td className="text-center"> 
                                                                { (performance.status === 'A') && 'Absent' }  
                                                                { (performance.status === 'P') && 'Present' }
                                                                { (performance.status === 'S') && 'Short Leave' } 
                                                                { (performance.status === 'H') && 'Half Day' }
                                                                { (performance.status === 'T') && 'Two Hours' }
                                                                { (performance.status === 'W') && 'Weekly Off' }
                                                                { (performance.status === 'O') && 'Holiday' }
                                                                { (performance.status === 'OW') && 'Weekly Off' }  
                                                        </td>
                                                        <td className="text-center">
                                                            { st ? 'N/A' : performance.punctuality}
                                                        </td>
                                                        <td className="text-center">    
                                                            { st ? 'N/A' :  performance.uor}
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.repetation}
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.timeConsume}
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.qualityWork}
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.taskCompletion }
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.reports}
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.behaviour }
                                                        </td>
                                                        <td className="text-center">    
                                                            {st ? 'N/A' : performance.responsibility }
                                                        </td>
                                                        {(userRoleForPerformance === 'seo' || userRoleForPerformance === 'hr' || userRoleForPerformance === 'bidders' || userRoleForPerformance === 'qualityAuditor') &&
                                                            <td className="text-center">    
                                                                {st ? 'N/A' : performance.conversion }
                                                            </td>
                                                        }
                                                        <td className="text-center">    
                                                            { st ? 'N/A' : `${performance.total}%` }
                                                        </td>
                                                        <td className="text-center">
                                                            <Button variant="primary" disabled={!(userRole === 'super_admin' || userRole === 'admin') } onClick={()=> this.setState({ openEditPerformanceModel: true, singlePerformanceData: performance }) }> Edit </Button>
                        
                                                            {/* <Form.Check inline className="ml-2 mt-2" type="checkbox" id={`inlineCheck`} onChange={this.onPerformanceSelect} value={performance._id}/> */}
                                                        </td>
                                                    </tr>
                                                )
                                            })}                        
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <p className="m-0 p-2 text-center border">
                                    Nothing To Show
                                </p>
                                }
                            </div>
                        </>  
                    }
                    <div className="projectTable shadow-sm bg-white mt-4 text-center p-2 animate__animated animate__fadeInUpBig">
                        <Card className="mb-0">
                            <Card.Body >
                                {/* <strong> Salary Calculation Formula = Total Salary / Total Working Days * Number of Working Days * Overll Performance / 100 </strong> */}
                                <strong> Salary Calculation Formula = Total Salary * Attendance Percentage * Performance Percentage / 10000 </strong>
                            </Card.Body>
                        </Card>
                    </div>
                </>
            )
        }
    }
}


const mapStateToProps = state => {
    const { performance, auth, member } = state
    return {
        userPerformance : performance.performance,
        auth: auth,
        membersList: member.member,
        ePerformance: performance.editPerformance,
        dPerformance: performance.deletePerformance
    }
}
const  dispatchAction =  { fetchPerformance, fetchMembers, editPerformance, deletePerformance }
export default connect(mapStateToProps, dispatchAction)(PerformanceComponent)