import React, { Component } from 'react';
import { Button, Col, Form, Modal, Row, Alert, Spinner } from 'react-bootstrap';
import { fetchMembers } from '../../actions/memberAction';
import { 
    addReport, 
    // getSingleProjectReport, 
    getSingleUserReports 
}  from '../../actions/reportActions';
import { fetchSingleUserProjects, fetchAllInprogressProjects, loadMoreTasks } from '../../actions/projectActions';
import { connect } from 'react-redux';
import ReportByProjectComponent from './ReportsByProjectsForAdmin';
import { socket } from '../../actions/actionResponseHandler';
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
toast.configure();


class ReportComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            openShareMessageTextBox: false,
            memberId:'',
            userRole:'',
            identifying:'',
            permissions:{},
            loggedInUserId:'',
            loggedInUserName:'',
            addReportLoader:false,
            fetchProjectLoader: false,
            fetchReportLoader: false,
            projects:[],
            // ------ // 
            selectProjectId:'',
            selectedProject:{},

            spentHours:'',
            spenMinutes:'',

            completedTask:[],

            workTestingTimes:'',
            didClientUpdated:'',
            messageForClient:'',
            remarks:'',
            adminList:[],
            membersList:[],
            membersListLoader: false,

            checkValidClientUpdated:'',
            // ---------- //
            remarksModel: false,
            openClientMessageModel: false,
            showClientMessage:'',
            openTaskModel:false,
            isMoreTasks: true, // default true
            skipTasks: 20, // default for loadmore tasks
            loadingMoreTasks: false,
            tasks:[],
            task__projectName:'',
            showReportCompletedTask:[],
            reports:[],
            backUpReports:[],
            // --- STeps ----//
            showProjectStep: true,
            showTimeSpent: false,
            showTaskList: false,
            showTestingTimes:false,
            showUpdate:false,
            showRemarks:false,
            showPersonalInfoStep: false,
            reportDate: new Date(),

            //  --------- Project Wise Report --- //
            showReportByProjectsForAdmin: false,

            taskProjectId:'',
        }
    }

    componentDidMount(){
        if(this.props.auth && this.props.auth.loggedIn){
            if(this.props.auth.user && this.props.auth.user.permissions && this.props.auth.user.permissions.report){
                this.setState({ 
                    userRole: this.props.auth.user.role, 
                    permissions:{...this.props.auth.user.permissions.report},
                    loggedInUserId: this.props.auth.user.userId,
                    loggedInUserName: this.props.auth.user.fullname,
                    identifying: this.props.auth.user.identifying,
                }, async () => {
                    if((this.state.userRole === 'super_admin' || this.state.userRole === 'admin')){
                        await this.getMembersList()
                        if(this.state.userRole === 'admin'){
                            await this.getSingleUserProjects(this.state.loggedInUserId);
                        }
                     }else{
                        await this.getAdminListForLoggedInUser()
                        await this.getSingleUserProjects(this.state.loggedInUserId);
                        await this.fetchSingleUSerReports(this.state.loggedInUserId, this.dateConvert(this.state.reportDate))
                     }
                })
            }
        }
    }

    sendNotification = async (members, message) => {
        socket.emit('Send_Notification', { room: members, notify: message});
    }

    // getAllProjects = async () => {
    //     this.setState({ fetchProjectLoader: true})
    //     await this.props.fetchAllInprogressProjects();
    //     if(this.props.progressProject.response){
    //         this.setState({
    //             projects: this.props.progressProject.projects,
    //             selectProjectId: this.props.progressProject.projects[0]._id,
    //             fetchProjectLoader: false,
    //         }, async ()=> {
    //             await this.fetchSingleProjectReport(this.state.selectProjectId)
    //             this.onChangeReportDate(this.state.reportDate)
    //         })
    //     }else{
    //         this.setState({
    //             fetchProjectLoader: false,
    //         })
    //     }
    // }

    // fetchSingleProjectReport = async (projectId) => {
    //     this.setState({ fetchReportLoader: true })
    //     await this.props.getSingleProjectReport(projectId)
    //     if(this.props.projectReport.response){
    //         this.setState({  
    //             reports: this.props.projectReport.reports,
    //             backUpReports: this.props.projectReport.reports,
    //             fetchReportLoader: false,
    //         }, ()=> this.onChangeReportDate(this.state.reportDate))
    //     }else{
    //         toast.dismiss()
    //         toast.error( this.props.projectReport.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
    //         this.setState({ fetchReportLoader: false })
    //     }
    // }

    fetchSingleUSerReports = async (userId, date) => {
        this.setState({ fetchReportLoader: true })
        await this.props.getSingleUserReports(userId, date)
        if(this.props.reportFetch.response){
            this.setState({
                reports: this.props.reportFetch.reports,
                backUpReports: this.props.reportFetch.reports,
                fetchReportLoader: false,
            })
        }else{
            toast.dismiss()
            toast.error( this.props.reportFetch.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ fetchReportLoader: false })
        }
    }
    
    getSingleUserProjects = async (loggedInUserId) => {
        this.setState({ fetchProjectLoader: true})
        await this.props.fetchSingleUserProjects(loggedInUserId);
        if(this.props.projectFetch.response){
            this.setState({
                projects: this.props.projectFetch.projects,
                fetchProjectLoader: false,
            })
        }else{
            this.setState({ fetchProjectLoader: false})
        }
    }

    getMembersList = async () => {
        this.setState({ membersListLoader: true })
        await this.props.fetchMembers();
        
        if(this.props.membersList.response){
            let memberList  = (this.props.membersList.users && this.props.membersList.users.length) ? this.props.membersList.users.filter(x=> x.role !== 'super_admin') : [] 
            let adminList = (this.props.membersList.users && this.props.membersList.users.length) ? this.props.membersList.users.filter(x=> x.role === 'super_admin' || x.role ==='admin').map(m=> m._id):[]
            this.setState({
                adminList: adminList,
                membersList: memberList, //this.props.membersList.users,
                membersListLoader: false,
                memberId: memberList[0]._id //this.props.membersList.users[0]._id
            }, async ()=>{ 
                await this.fetchSingleUSerReports(this.state.memberId, this.dateConvert(this.state.reportDate))
                // this.onChangeReportDate(this.state.reportDate)
            })
        }else{
            this.setState({ membersListLoader: false })
        } 
    }

    getAdminListForLoggedInUser = async () => {
        await this.props.fetchMembers();
        if(this.props.membersList.response){
            
            let adminList = (this.props.membersList.users && this.props.membersList.users.length) ? this.props.membersList.users.filter(x=> x.role === 'super_admin' || x.role ==='admin').map(m=> m._id):[]
  
            this.setState({
                adminList: adminList,
            })
        }
    }

    dateConvert = (date) => {
        let yyyy = new Date(date).getFullYear();
        let mm =  Number(new Date(date).getMonth())+1;
        let dd = new Date(date).getDate()
        return yyyy + '-'  + mm + '-' + dd ;
    }

    changeStep = ( step ) => {
        switch (step) {
            case 1: this.setState({
                        showProjectStep: true,
                        showTimeSpent: false,
                        showTaskList: false,
                        showTestingTimes:false,
                        showUpdate:false,
                        showRemarks:false,
                        showPersonalInfoStep: false
                    })
                    break;
            case 2: this.setState({
                        showProjectStep: false,
                        showTimeSpent: true,
                        showTaskList: false,
                        showTestingTimes:false,
                        showUpdate:false,
                        showRemarks:false,
                        showPersonalInfoStep: false
                    })
                    break;
            case 3: this.setState({
                        showProjectStep: false,
                        showTimeSpent: false,
                        showTaskList: true,
                        showTestingTimes:false,
                        showUpdate:false,
                        showRemarks:false,
                        showPersonalInfoStep: false
                    })
                    break;
            case 4: this.setState({
                        showProjectStep: false,
                        showTimeSpent: false,
                        showTaskList: false,
                        showTestingTimes:true,
                        showUpdate:false,
                        showRemarks:false,
                        showPersonalInfoStep: false
                    })
                    break;
            case 5: this.setState({
                        showProjectStep: false,
                        showTimeSpent: false,
                        showTaskList: false,
                        showTestingTimes:false,
                        showUpdate:true,
                        openShareMessageTextBox: false,
                        showRemarks:false,
                        showPersonalInfoStep: false
                    })
                    break;
            case 6: this.setState({
                        showProjectStep: false,
                        showTimeSpent: false,
                        showTaskList: false,
                        showTestingTimes:false,
                        showUpdate:false,
                        showRemarks:true,
                        showPersonalInfoStep: false
                    })
                    break;
            case 7: this.setState({
                        showProjectStep: false,
                        showTimeSpent: false,
                        showTaskList: false,
                        showTestingTimes:false,
                        showUpdate:false,
                        showRemarks:false,
                        showPersonalInfoStep: true
                    })
                    break;
            default: break;
        }
    }

    checkTasksCompleted = (taskList) => {
        return taskList.filter(x => x.isCompleted === false).length;
    }

    onChangeProject = (e) => {
        let selectedProject = this.state.projects.find(obj => obj._id === e.target.value)
        this.setState({
            selectProjectId: e.target.value,
            selectedProject
        })
    }

    //  -------------  Step 1 ----------- //
    showProjectStep = () => {
        const { projects, selectProjectId } = this.state
        let nextButtonDisable = true
        if(selectProjectId.trim().length > 0){
            nextButtonDisable = false
        }

        return (  
            <div className="form-group mb-0 d-sm-flex align-items-center justify-content-between" style={{flex : 'auto'}}>
                {/* <label>Project Name</label> */}
                <select className="form-control custom-select mr-sm-2 ml-0 my-3 my-md-0" name="pName" onChange={this.onChangeProject} value={this.state.selectProjectId}>
                    {(projects && projects.length > 0) 
                        ?  <>
                                <option value=""  disabled> Select Your Project</option>
                                {projects.map((project, idx)=> {
                                    if(project.assignedMember[0].accepted && project.isAdminAccepted){
                                        if(this.checkTasksCompleted(project.taskList) > 0){
                                            return (
                                                <option value={project._id} key={idx}> {project.projectName} </option>
                                            )
                                        }
                                    }
                                })}
                            </>
                        :   <option value="" disabled>Not Assign any project</option>
                    }
                </select>
                <div className="button-group justify-content-end d-flex">
                    <Button type="submit" variant="primary" onClick={() => this.changeStep(2)} disabled={nextButtonDisable}> Next <i className="fa fa-chevron-right"></i></Button>                
                </div>
            </div>
        )
    }



    handleEstimateTimeChange = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    //  --------------  Step 2 --------------- //
    showTimeSpent = () => {
        let nextButtonDisable = true
        const { spentHours, spenMinutes } = this.state 
        if(spentHours.trim().length > 0 && spenMinutes.trim().length > 0){
            nextButtonDisable = false
        }
        return (
            <div className="p-4 shadow-lg reportForm timeStep">
                <Row className="justify-content-center">
                    <div className="col-sm-12 animate__animated animate__fadeInRight">
                        <div className="form-group d-sm-flex align-items-center justify-content-between">
                            <label>Time Spent</label>
                            {/* <TimePicker showSecond={false} minuteStep={5} placeholder="Select Hour/Minutes" onChange={(newTime) => this.onTimeChange(newTime)}/> */}
                            <div className="timeDiv">
                                <select className="form-control custom-select mr-sm-2" name="spentHours" onChange={this.handleEstimateTimeChange} value={this.state.spentHours}>
                                    <option value="" disabled selected> Select </option>
                                    <option value="00"> 00 </option>
                                    <option value="01"> 01 </option>
                                    <option value="02"> 02 </option>
                                    <option value="03"> 03 </option>
                                    <option value="04"> 04 </option>
                                    <option value="05"> 05 </option>
                                    <option value="06"> 06 </option>
                                    <option value="07"> 07 </option>
                                    <option value="08"> 08 </option>
                                    <option value="09"> 09 </option>
                                    <option value="10"> 10 </option>
                                    <option value="11"> 11 </option>
                                    <option value="12"> 12 </option>
                                    <option value="13"> 13 </option>
                                    <option value="14"> 14 </option>
                                    <option value="15"> 15 </option>
                                    <option value="16"> 16 </option>
                                    <option value="17"> 17 </option>
                                    <option value="18"> 18 </option>
                                    <option value="19"> 19 </option>
                                    <option value="20"> 20 </option>
                                    <option value="21"> 21 </option>
                                    <option value="22"> 22 </option>
                                    <option value="23"> 23 </option>
                                </select>
                                <span> Hours </span>
                                
                                <select className="form-control custom-select mr-sm-2" name="spenMinutes" onChange={this.handleEstimateTimeChange} value={this.state.spenMinutes}>
                                    <option value="" disabled selected> Select </option>
                                    <option value="00"> 00 </option>
                                    <option value="05"> 05 </option>
                                    <option value="10"> 10 </option>
                                    <option value="15"> 15 </option>
                                    <option value="20"> 20 </option>
                                    <option value="25"> 25 </option>
                                    <option value="30"> 30 </option>
                                    <option value="35"> 35 </option>
                                    <option value="40"> 40 </option>
                                    <option value="45"> 45 </option>
                                    <option value="50"> 50 </option>
                                    <option value="55"> 55 </option>
                                </select>
                                <span className="mr-2"> Minutes </span>                                
                            </div>
                            <div class="button-group justify-content-end d-flex">
                                <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(1)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                <Button type="submit" variant="primary" onClick={() => this.changeStep(3)} disabled={nextButtonDisable}>Next <i className="fa fa-chevron-right"></i></Button>                
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        )
    }

    taskSelected = (e)  => {
        const { checked, value } = e.target
        let completedTask = this.state.completedTask;
        if(checked){
            completedTask.push(value);
        }else{
            completedTask = completedTask.filter(e => e !== value);
        }
        
        this.setState({
            completedTask
        })
    }

    // -------------- Step 3 ----------------- //
    showTaskList = () => {
        const { selectedProject } = this.state
        let nextButtonDisable = true 

        if(this.state.completedTask.length > 0){
            nextButtonDisable = false
        }
        return (
            <>
            <div className="p-4 shadow-lg reportForm">
                <Row className="justify-content-center">
                    <div className="col-sm-12 animate__animated animate__fadeInRight">
                        <label>List of completed tasks</label>
                        <div className="form-group text-left mt-2">
                            <Form className="checkForm">
                                {['checkbox'].map((type, idx) => (
                                    <div key={`inline-${idx}`} className="mb-3">
                                        {selectedProject.taskList.map((task, idx) => {
                                            if(!task.isCompleted){
                                                let checked =  false
                                                if(this.state.completedTask.includes(task._id)){
                                                    checked = true
                                                }
                                                return (
                                                    <Form.Check inline label={task.taskName} type={type} id={`inline-${idx}`} onChange={this.taskSelected} value={task._id} checked={checked}/>
                                                )
                                            }
                                        })}
                                    </div>
                                ))}
                            </Form>
                            <div class="button-group justify-content-end d-flex w-100">
                                <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(2)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                <Button type="submit" variant="primary" onClick={() => this.changeStep(4)} disabled = {nextButtonDisable}>Next <i className="fa fa-chevron-right"></i></Button>                
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
            </>
        )
    }

    handleChangeTestingTime = (e) => {
        this.setState({ workTestingTimes: e.target.value })
    }
    showTestingTimes = () => {
        let nextButtonDisable = true
        if( this.state.workTestingTimes.trim().length > 0){
            nextButtonDisable = false
        }
        return (
            <>
            <div className="p-4 shadow-lg reportForm">
            <Row className="justify-content-center">
                    <div className="col-sm-12 animate__animated animate__fadeInRight">
                        <div className="form-group d-sm-flex align-items-center justify-content-between">
                            <label>How Many Times You Tested Your Work ?</label>
                            <select className="form-control custom-select mr-sm-2" name="workTestingTimes" onChange={this.handleChangeTestingTime} value={this.state.workTestingTimes}>
                                <option value="" selected disabled> Select </option>
                                <option value="0"> 0 Times </option>
                                <option value="1"> 1 Times </option>
                                <option value="2"> 2 Times </option>
                                <option value="3"> 3 Times </option>
                                <option value="4"> 4 Times </option>
                                <option value="5"> 5 Times </option>
                                <option value="6"> 6 Times </option>
                                <option value="7"> 7 Times </option>
                                <option value="8"> 8 Times </option>
                                <option value="9"> 9 Times </option>
                                <option value="10"> 10 Times </option>
                            </select>
                            <div class="button-group justify-content-end d-flex">
                                <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(3)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                <Button type="submit" variant="primary" onClick={() => this.changeStep(5)} disabled={nextButtonDisable}>Next <i className="fa fa-chevron-right"></i></Button>                
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
            </>
        )
    }
    

    OnChangeUpdateOption = (e) => {
        const { value, options, selectedIndex } = e.target
        if(value === 'NO'){
            this.setState({openShareMessageTextBox: true })
        }else{
            this.setState({openShareMessageTextBox: false, messageForClient:''})
        }
        this.setState({ didClientUpdated:options[selectedIndex].innerHTML, checkValidClientUpdated:value })
    }

    // -------------------  Step 5 ----------------- //
    showUpdate = () => {
        const { openShareMessageTextBox, checkValidClientUpdated, messageForClient }= this.state
        let nextButtonDisable = true
        if(checkValidClientUpdated == 'NO' && openShareMessageTextBox){
            if(messageForClient.length > 5){
                nextButtonDisable = false
            }
        }else{
            if(checkValidClientUpdated.length > 0){
                nextButtonDisable = false
            }
        }
        return (
            <>
                <div className="p-4 shadow-lg reportForm">
                    <Row className="justify-content-center">
                        <div className="col-sm-12 animate__animated animate__fadeInRight">
                            <div className="form-group d-sm-flex align-items-center justify-content-between">
                                <label>Did you shared updates with client?</label>
                                <select className="form-control custom-select mr-sm-2" onChange={this.OnChangeUpdateOption}>
                                    <option value=''>Select</option>
                                    <option value='YES'>Yes I did</option>
                                    <option value='NO'>No I didn't</option>
                                    <option value='NR'> Not Required </option>
                                </select>
                                <div class="button-group justify-content-end d-flex">
                                    <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(4)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                    <Button type="submit" variant="primary" onClick={() => this.changeStep(6)} disabled={nextButtonDisable}>Next <i className="fa fa-chevron-right"></i></Button>                
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 mt-3">
                                {openShareMessageTextBox &&
                                <Form.Group controlId="shareUpdateMessage">
                                    <Form.Label> Updates to share with Client </Form.Label>
                                    <Form.Control as="textarea" name="messageForClient" className="mx-0 mt-2" rows="3" onChange={this.handleChangeTextarea} value={messageForClient} placeholder={'Please share a complete message to share with the client along with project URL, screenshots, credentials, etc. whatever is required for the client to test the completed tasks.'}/>
                                </Form.Group>}
                        </div>
                    </Row>
                </div>
            </>
        )
    }


    handleChangeTextarea = (e) => {
        const { name, value } = e.target
        this.setState({ [name]:value})
    }
    // ------------ Step 6 ------------------ //
    showRemarks = () => {
        const { remarks } = this.state
        let nextButtonDisable = true
        if(remarks.length > 1){
            nextButtonDisable = false
        }
        return(
            <>
                <div className="p-4 shadow-lg reportForm">
                    <Row className="justify-content-center">
                            <div className="col-sm-12 animate__animated animate__fadeInRight">
                                <Form.Group controlId="Remarks" className="d-sm-flex align-items-center justify-content-between">
                                    <Form.Label> Remarks </Form.Label>
                                    <Form.Control as="textarea" rows="5" name="remarks" onChange={this.handleChangeTextarea} value={remarks} placeholder={`Please mention the complete details of the project with the Project URL, Screenshots, credentials, or whatever you think is important for the testing of the completed tasks.`}/>
                                    <div class="button-group justify-content-end d-flex">
                                        <Button type="submit" variant="outline-primary" className="mr-2" onClick={() => this.changeStep(5)}><i className="fa fa-chevron-left"></i> Previous </Button>
                                        <Button type="submit" variant="primary" onClick={(e) => this.handleFinalReportSubmit(e)} disabled={ nextButtonDisable || this.state.addReportLoader }> {this.state.addReportLoader ? 'Wait...': 'Finish'}</Button>
                                    </div>
                                </Form.Group>
                            </div>
                    </Row>
                </div>
            </> 
        )
    }
    
    handleFinalReportSubmit = async (e) => {
        e.preventDefault();
        let data = {}
        data.user = this.state.loggedInUserId
        data.project = this.state.selectProjectId
        data.timeSpent = `${this.state.spentHours}:${this.state.spenMinutes}`
        data.completedTask = this.state.completedTask
        data.workTestingTimes = this.state.workTestingTimes
        data.didClientUpdated = this.state.didClientUpdated
        if(this.state.messageForClient.length > 0){
            data.messageForClient = this.state.messageForClient
        }
        data.remarks = this.state.remarks
        this.setState({ addReportLoader: true })
        await this.props.addReport(data)
        if(this.props.reportAdd.response){
            this.sendNotification(this.state.adminList, `${this.state.loggedInUserName} reported for project "${this.state.selectedProject['projectName']}"`)
            toast.dismiss()
            toast.success( this.props.reportAdd.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ addReportLoader: false })
            if(this.state.userRole === 'admin'){
                this.setState({
                    memberId: this.state.loggedInUserId
                })
            }
            this.fetchSingleUSerReports(this.state.loggedInUserId, this.dateConvert(this.state.reportDate))
            this.resetForm()
        }else{
            toast.dismiss()
            toast.error( this.props.reportAdd.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ addReportLoader: false })
        }
    }

    resetForm = () => {
        this.setState({
            selectProjectId:'',
            spentHours:'',
            spenMinutes:'',
            completedTask:[],
            workTestingTimes:'',
            didClientUpdated:'',
            messageForClient:'',
            remarks:'',
            selectedProject:{},
            checkValidClientUpdated:'',
            openShareMessageTextBox: false,
        })
        this.getSingleUserProjects(this.state.loggedInUserId);
        this.changeStep(1)
    }


    handleChangeLabel = async (projectId) => {
        socket.emit('change-label', {projectId})
        setTimeout(async ()=> {
            await this.fetchSingleUSerReports(this.state.memberId, this.dateConvert(this.state.reportDate))
        }, 250)
    }

    onChangeMember = (e) => {
        this.setState({
            memberId: e.target.value
        }, ()=> this.fetchSingleUSerReports(this.state.memberId, this.dateConvert(this.state.reportDate)))
    }

    convertDate = (date) => {
        var d = new Date(date.split('T')[0]);
        var year = (new Date().getFullYear() === d.getFullYear()) ? '' : ", " +  d.getFullYear()
        var monthName = d.toLocaleString('default', { month: 'short' })
        return  (monthName) + " " + d.getDate() +  year
    }

    convertTime = (date) => {
        var d = new Date(date);
        return  d.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    }

    onCloseClientMessageModel = () => {
        this.setState({ showClientMessage: '', openClientMessageModel: false, remarksModel: false})
    }

    onCloseTaskModel = () => {
        this.setState({ openTaskModel: false, 
            tasks:[], 
            showReportCompletedTask:[], 
            task__projectName:'', 
            isMoreTasks: true,
            taskProjectId: '',
            skipTasks: 20,
        })
    }

    onChangeReportDate = ( date ) => {
        this.setState({ reportDate: date })
        var  userId = this.state.userRole === "user" ? this.state.loggedInUserId : this.state.memberId
        this.fetchSingleUSerReports(userId, this.dateConvert(date))
        // let reports = this.state.backUpReports
        // reports = reports.filter( r => {
        //     return r['createdAt'].split('T')[0] === new Date(date).toISOString().split('T')[0]
        // } )
        // this.setState({ reports })

    }

    // onChangeProject_2 = (e) => {
    //     this.setState({
    //         selectProjectId: e.target.value,
    //     }, () => this.fetchSingleProjectReport(this.state.selectProjectId))
    // }

    // showReportByMembers = async () => {
    //     this.setState({ showReportByProjects: false })
    //     await this.getMembersList()
    // }

    // showReportByProjects = async () => {
    //     this.setState({ showReportByProjects: true })
    //     await this.getAllProjects();
    // }

    // ----------------------- Filter Tasks ----------------- //

    getPendingTasks = (tasks) => {
        return tasks.filter(t => t.isCompleted === false )
    }

    getTodayCompletedTasks = (tasks) => {
        return tasks.filter(t => t.isCompleted === true ).filter(t => t.completedOn.split('T')[0] === new Date().toISOString().split('T')[0])
    }

    getYesterdayCompletedTasks = (tasks) => {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        return tasks.filter(t => t.isCompleted === true ).filter(t => t.completedOn.split('T')[0] === new Date(yesterday).toISOString().split('T')[0])
    }

    getPreviousCompletedTasks = (tasks) => {
        const today = new Date()
        const yesterday = new Date(today)
        yesterday.setDate(yesterday.getDate() - 1)
        return tasks.filter(t => t.isCompleted === true ).filter(t => t.completedOn.split('T')[0] < new Date(yesterday).toISOString().split('T')[0])
    }

    // ----------------------- Filter Tasks ----------------- //

    handleScrollTasks = e => {
        let element = e.target
        if( Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) <= 1 
            && this.state.openTaskModel 
            && !this.state.loadingMoreTasks 
            && this.state.isMoreTasks
        ){
            this.onLoadMoreTasks()
        }
    }
    
    onLoadMoreTasks = async () => {
        var projectId = this.state.taskProjectId;
        var userId = ''
        if(this.state.userRole === 'super_admin' || this.state.userRole === 'admin'){
            userId = this.state.memberId // userId of single member
        }else{
            userId = this.state.loggedInUserId; // userId of non-admin
        }
        var skip = this.state.skipTasks
        this.setState({ loadingMoreTasks: true })
        await this.props.loadMoreTasks(projectId, userId, skip)
        this.setState({ loadingMoreTasks: false })
        if( this.props.moreTasks && this.props.moreTasks.response && this.props.moreTasks.tasks.length ){
            this.setState({
                tasks: this.state.tasks.concat(this.props.moreTasks.tasks),
                skipTasks: this.state.skipTasks + 20
            })
        }else{
            this.setState({ isMoreTasks: false })
        }
    }

    render(){
        const {
            showProjectStep,
            showTimeSpent,
            showTaskList,
            showTestingTimes,
            showUpdate,
            showRemarks,
            // showPersonalInfoStep,
            fetchReportLoader,
            userRole,
            // identifying,
            membersList,
            reports,
            // projects,
            // showReportByProjects,
            showReportByProjectsForAdmin,
        } = this.state
        
        
        if(showReportByProjectsForAdmin){
            return ( 
                <ReportByProjectComponent/>
            )
        }else{
            return(  
                <>
                    <div className="list-group">
                        <div className="d-md-flex align-items-md-center justify-content-md-between mb-3">
                            <h3 className="mb-0"> Reports </h3>
                            
                            {(userRole === 'admin' || userRole === 'super_admin') &&
                            <div className="dateSelect d-md-flex justify-content-end align-items-center">
                                <Button variant="primary" onClick={() => this.setState({ showReportByProjectsForAdmin: true })}>  Check Reports by Projects</Button>
                            </div>
                            }
                        </div>
                        { (userRole !== 'super_admin') &&
                        <>
                            <div className="projectTable shadow-lg p-3 animate__animated animate__fadeInUp mb-3">
                                <div className="d-lg-flex align-items-md-center">
                                    <h3 className="mr-3"> Submit Report </h3>
                                    { showProjectStep && this.showProjectStep() }
                                </div>
                            </div>
                            { showTimeSpent && this.showTimeSpent() }
                            { showTaskList && this.showTaskList() }
                            { showTestingTimes && this.showTestingTimes() }
                            { showUpdate && this.showUpdate() }
                            { showRemarks && this.showRemarks() }
                            {/* { showPersonalInfoStep && this.showPersonalInfoStep() } */}
                        </>
                        }
                    </div>
                    <div className="projectTable shadow-lg p-3 animate__animated animate__fadeInUp overflowX">
                        <div className="d-xl-flex align-items-md-center justify-content-md-between mb-3">
                            <h3 className="mb-md-2 mb-lg-0">{ (userRole === 'super_admin' || userRole === 'admin') ? 'Showing Reports By Member' : 'Daily Reports'}</h3>
                            <div className="dateSelect d-md-flex justify-content-lg-end justify-content-between align-items-center">
                                {(userRole === 'super_admin' || userRole === 'admin') &&
                                    <Form inline className="mr-md-4 mb-3 mb-md-0 mb-lg-0">
                                        <Form.Control as="select" id="inlineFormCustomMemberSelect2"  onChange={this.onChangeMember} value={this.state.memberId} custom>
                                            {(membersList && membersList.length > 0)
                                                ?
                                                <>
                                                <option value='' disabled > Select member</option>
                                                    {membersList.map((member, idx) => {
                                                        if(member.role !== 'super_admin')
                                                            return (
                                                                <option value={member._id} key={idx}> { member.fullname } - { member.designation } </option>
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                <option value="" disabled >No Members</option>
                                            }
                                        </Form.Control>
                                    </Form>
                                } 
                                <span className="mr-2">Select Date</span>
                                <div className="relative">
                                    <DatePicker selected={this.state.reportDate} onChange={date => this.onChangeReportDate(date)} maxDate={new Date()}/>                                
                                </div>
                            </div>
                        </div>
                        {fetchReportLoader 
                        ?
                        <div className="text-center circleSpinnner mt-3">
                                <Spinner animation="border" />
                            </div>
                        :
                        (reports && reports.length > 0)
                            ?
                            <div className="table-responsive ">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-right-0">Reported Date</th>
                                            <th className="border-right-0 border-left-0">Reported At</th>
                                            <th className="border-right-0 border-left-0">Project Name</th>
                                            <th className="border-right-0 border-left-0">Client/Contract</th>
                                            {(userRole === 'super_admin' || userRole === 'admin') && <th className="border-right-0 border-left-0">Label</th>}
                                            <th className="border-right-0 border-left-0">Time Spent</th>
                                            <th className="border-right-0 border-left-0 four-child"> Did Client Updated</th>
                                            <th className="border-right-0 border-left-0 four-child"> How Many Time Tested</th>
                                            <th className="border-right-0 border-left-0"> Remarks </th>
                                            <th className="border-left-0"> Tasks Status </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {reports.map((data, idx)=> {
                                            return (
            
                                                <tr key={idx}>
                                                    <td>
                                                        { this.convertDate(data.createdAt)}
                                                    </td>
                                                    <td className="border-right-0 border-left-0">
                                                        {this.convertTime(data.createdAt)}
                                                    </td>
                                                    <td className="border-right-0 border-left-0">{data.project ? data.project.projectName || 'N/A' : 'N/A'}</td>
                                                    <td className="border-right-0 border-left-0">{data.project ? data.project.isClientContract || 'N/A' : 'N/A'}</td>
                                                    {(userRole === 'super_admin' || userRole === 'admin') &&  
                                                    <td className="border-right-0 border-left-0">
                                                       { 
                                                        !data.project 
                                                            ?
                                                            'N/A'
                                                            :
                                                        <span className="spanLabel" style={{backgroundColor:data.project ? data.project.label : '', cursor:'pointer', padding:'0.25rem 2.5rem'}} onClick={()=> data.project ? this.handleChangeLabel(data.project._id) : null }></span>
                                                        }    
                                                    </td>}
                                                    <td className="border-right-0 border-left-0"> { data.timeSpent || 'N/A'}</td>
                                                    <td className="border-right-0 border-left-0">
                                                        { data.didClientUpdated || 'N/A'}
                                                        {data.messageForClient && <i className="ml-1 fa fa-eye" style={{cursor:'pointer'}} onClick={()=> this.setState({showClientMessage: data.messageForClient, openClientMessageModel: true})}/>}
                                                    </td>
                                                    <td className="border-right-0 border-left-0"> { data.workTestingTimes ? `${data.workTestingTimes} Times` : 'N/A'} </td>
                                                    <td className="border-right-0 border-left-0">
                                                        {data.remarks && data.remarks.trim().length < 5
                                                            ?
                                                            data.remarks
                                                            :
                                                            <i className="ml-1 fa fa-eye" style={{cursor:'pointer'}} onClick={()=> this.setState({showClientMessage: data.remarks, remarksModel: true, openClientMessageModel: true})}/>
                                                        }
                                                    </td>
                                                    <td>
                                                        <Button 
                                                            variant="primary" 
                                                            onClick={()=> this.setState({ 
                                                                    openTaskModel: true, 
                                                                    tasks: (data.project && data.project.taskList.length > 0) ? data.project.taskList : [], 
                                                                    task__projectName: data.project ? data.project.projectName || 'N/A' : 'N/A',
                                                                    taskProjectId: data.project._id
                                                                })
                                                            }
                                                        >
                                                            View 
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <p className="m-0 p-2 text-center border">
                                Nothing To Show
                            </p>
        
                        }
                        </div>
                        <Modal id="clientMessageModel" show = { this.state.openClientMessageModel } onHide={ this.onCloseClientMessageModel }>
                            <Modal.Header closeButton>
                                <Modal.Title>{this.state.remarksModel ? 'Remarks': 'Message For Client'} </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="align-items-center">
                                    <Col sm={12}>
                                        <p>
                                            <pre>
                                                {this.state.showClientMessage}
                                            </pre>
                                        </p>
                                    </Col>        
                                </Row>
                            </Modal.Body>
                        </Modal>
                        <Modal id="TaskModel" show = { this.state.openTaskModel } onHide={ this.onCloseTaskModel }>
                            <Modal.Header closeButton>
                                <Modal.Title>Task Status For Project : "{this.state.task__projectName}"</Modal.Title>
                            </Modal.Header>
                            <Modal.Body onScroll={(e) => this.handleScrollTasks(e)}>
                                <h5 className="mt-1 text-warning">Pending Tasks</h5>
                                {this.getPendingTasks(this.state.tasks).length > 0  
                                ?
                                <Row className="align-items-center">
                                    <Col sm={12}>
                                        <div className="table-responsive ">
                                            <table className="table table-bordered table-striped">
                                                <tr>
                                                    <th className="border-right-0">Sr. No.</th>
                                                    <th className="border-right-0 border-left-0">Task Name</th>
                                                    <th className="border-right-0 border-left-0">Assigned On</th>
                                                    <th className="border-left-0">Status</th>
                                                </tr>
                                                {this.getPendingTasks(this.state.tasks).map((task, idx) => {
                                                    return(
                                                        <tr key={idx}>
                                                             <td> {idx+1} </td>
                                                        <td className="border-right-0 border-left-0">
                                                            {task.taskName || 'N/A'}
                                                        </td>
                                                            <td className="border-right-0 border-left-0">
                                                                {this.convertDate(task.createdAt) || 'N/A'}
                                                            </td>
                                                            <td className="border-left-0">
                                                                {!task.isCompleted  ?
                                                                    <span className="text-warning">Pending</span>
                                                                    :
                                                                    <span className="text-success">Completed</span>
                                                                }
                                                            </td>                                                            
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </div>
                                    </Col>        
                                </Row>
                                :
                                <Alert variant="info"> Nothing to show </Alert>
                                }
                                <h5 className="mt-3 text-success">Today Completed Task</h5>
                                {this.getTodayCompletedTasks(this.state.tasks).length > 0
                                    ?
                                    <Row className="align-items-center">
                                        <Col sm={12}>
                                            <div className="table-responsive ">
                                                <table className="table table-bordered table-striped">
                                                    <tr>
                                                        <th className="border-right-0">Sr. No.</th>
                                                    <th className="border-right-0 border-left-0">Task Name</th>
                                                        <th className="border-right-0 border-left-0">Assigned On</th>
                                                        <th className="border-right-0 border-left-0">Completed On</th>
                                                        <th className="border-left-0">Status</th>
                                                    </tr>
                                                    {this.getTodayCompletedTasks(this.state.tasks).map((task, idx) => {
                                                        return(
                                                            <tr key={idx}>
                                                                 <td> {idx+1} </td>
                                                        <td className="border-right-0 border-left-0">
                                                            {task.taskName || 'N/A'}
                                                        </td>
                                                                <td className="border-right-0 border-left-0">
                                                                    {this.convertDate(task.createdAt) || 'N/A'}
                                                                </td>
                                                                <td className="border-right-0 border-left-0">
                                                                    {this.convertDate(task.completedOn) || 'N/A'}
                                                                </td>
                                                                <td className="border-left-0">
                                                                    {!task.isCompleted  ?
                                                                        <span className="text-warning">Pending</span>
                                                                        :
                                                                        <span className="text-success">Completed</span>
                                                                    }
                                                                </td>                                                            
                                                            </tr>
                                                        )
                                                    })}
                                                </table>
                                            </div>
                                        </Col>        
                                    </Row>
                                    :
                                    <Alert variant="info"> Nothing to show </Alert>
                                }
                                <h5 className="mt-3 text-success">Yesterday Completed Task</h5>
                                {this.getYesterdayCompletedTasks(this.state.tasks).length > 0
                                    ?
                                    <Row className="align-items-center">
                                        <Col sm={12}>
                                            <div className="table-responsive ">
                                                <table className="table table-bordered table-striped">
                                                    <tr>
                                                        <th className="border-right-0">Sr. No.</th>
                                                        <th className="border-right-0 border-left-0">Task Name</th>
                                                        <th className="border-right-0 border-left-0">Assigned On</th>
                                                        <th className="border-right-0 border-left-0">Completed On</th>
                                                        <th className="border-left-0">Status</th>
                                                    </tr>
                                                    {this.getYesterdayCompletedTasks(this.state.tasks).map((task, idx) => {
                                                        return(
                                                            <tr key={idx}>
                                                                 <td> {idx+1} </td>
                                                                <td className="border-right-0 border-left-0">
                                                                    {task.taskName || 'N/A'}
                                                                </td>
                                                                <td className="border-right-0 border-left-0">
                                                                    {this.convertDate(task.createdAt) || 'N/A'}
                                                                </td>
                                                                <td className="border-right-0 border-left-0">
                                                                    {this.convertDate(task.completedOn) || 'N/A'}
                                                                </td>
                                                                <td className="border-left-0">
                                                                    {!task.isCompleted  ?
                                                                        <span className="text-warning">Pending</span>
                                                                        :
                                                                        <span className="text-success">Completed</span>
                                                                    }
                                                                </td>                                                            
                                                            </tr>
                                                        )
                                                    })}
                                                </table>
                                            </div>
                                        </Col>        
                                    </Row>
                                    :
                                    <Alert variant="info"> Nothing to show </Alert>
                                }
                                <h5 className="mt-3 text-success">Previous Completed Task</h5>
                                {this.getPreviousCompletedTasks(this.state.tasks).length > 0
                                    ?
                                    <Row className="align-items-center mb-3">
                                        <Col sm={12}>
                                            <div className="table-responsive ">
                                                <table className="table table-bordered table-striped">
                                                    <tr>
                                                        <th className="border-right-0">Sr. No.</th>
                                                        <th className="border-right-0 border-left-0">Task Name</th>
                                                        <th className="border-right-0 border-left-0">Assigned On</th>
                                                        <th className="border-right-0 border-left-0">Completed On</th>
                                                        <th className="border-left-0">Status</th>
                                                    </tr>
                                                    {this.getPreviousCompletedTasks(this.state.tasks).map((task, idx) => {
                                                        return(
                                                            <tr key={idx}>
                                                                 <td> {idx+1} </td>
                                                                <td className="border-right-0 border-left-0">
                                                                    {task.taskName || 'N/A'}
                                                                </td>
                                                                <td className="border-right-0 border-left-0">
                                                                    {this.convertDate(task.createdAt) || 'N/A'}
                                                                </td>
                                                                <td className="border-right-0 border-left-0">
                                                                    {this.convertDate(task.completedOn) || 'N/A'}
                                                                </td>
                                                                <td className="border-left-0">
                                                                    {!task.isCompleted  ?
                                                                        <span className="text-warning">Pending</span>
                                                                        :
                                                                        <span className="text-success">Completed</span>
                                                                    }
                                                                </td>                                                            
                                                            </tr>
                                                        )
                                                    })}
                                                </table>
                                            </div>
                                        </Col>        
                                    </Row>
                                    :
                                    <Alert variant="info" className="mb-3"> Nothing to show </Alert>
                                }
                                {this.state.loadingMoreTasks 
                                ?   <div className="d-md-flex justify-content-center"> loading...</div>
                                :   null
                                }
                            </Modal.Body>
                        </Modal>
                </>    
            )
        }
    }
}


const mapStateToProps = state => {
    const { project, auth, member, report } = state
    return {
        auth: auth,
        membersList: member.member,
        projectFetch:project.projectFetch,
        reportAdd: report.reportAdd,
        reportFetch: report.reportFetch,
        projectReport: report.projectReport,
        progressProject: project.progressFetch,
        moreTasks: project.moreTasksLoaded,
    }
}
const  dispatchAction =  { 
    fetchSingleUserProjects, 
    fetchAllInprogressProjects, 
    addReport, 
    fetchMembers, 
    getSingleUserReports, 
    // getSingleProjectReport,
    loadMoreTasks 
}

export default connect(mapStateToProps, dispatchAction)(ReportComponent)