module.exports = [
    { designation:'Trainee Web Designer', identifying:'designer', dValue:"t_designer"},
    { designation:'Trainee Software Engineer', identifying:'developer', dValue:"t_developer"},
    
    { designation:'Web Designer', identifying:'designer', dValue:"designer"},
    { designation:'Sr. Web Designer', identifying:'designer', dValue:"s_designer" },
    
    { designation:'Software Engineer', identifying:'developer', dValue:"developer" },
    { designation:'Sr. Software Engineer', identifying:'developer', dValue:"s_developer" },
    
    { designation:'SEO Executive', identifying:'seo', dValue:"seo" },
    { designation:'Sr. SEO Executive', identifying:'seo', dValue:"s_seo" },
    { designation:'SEO Manager', identifying:'seo', dValue:"m_seo" },

    { designation:'Quality Analyst', identifying:'qualityAuditor', dValue:"qualityAuditor" },
    { designation:'Sr. Quality Analyst', identifying:'qualityAuditor' , dValue:"s_qualityAuditor"},
    
    { designation:'Business Developer', identifying:'bidders', dValue:"bidders"},
    { designation:'Sr. Business Developer', identifying:'bidders', dValue:"s_bidders"},
    
    { designation:'Human Resource', identifying:'hr', dValue:"hr" },
    { designation:'Sr. Human Resource', identifying:'hr', dValue:"s_hr" },

    { designation:'Team Leader', identifying:'teamLeader', dValue:"teamLeader" },
    { designation:'Sr. Team Leader', identifying:'teamLeader', dValue:"s_teamLeader" },

    { designation:'Project Manager', identifying:'projectManager', dValue:"projectManager" },
    { designation:'Sr. Project Manager', identifying:'projectManager', dValue:"s_projectManager" },

]