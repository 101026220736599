import React, { Component } from 'react';
import {Accordion, Button, Card, Col, Form, Modal, Row, Spinner} from 'react-bootstrap';
import {connect} from 'react-redux';
import { createTutorial, fetchRoleTutorials, updateTutorial, fetchAllTutorials, deleteTutorial } from '../../actions/tutorialAction';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { socket } from '../../actions/actionResponseHandler';
// import openSocket from "socket.io-client";
import config from '../../helpers/config';
import { toast } from "react-toastify";
toast.configure()
// const socket = openSocket(config.apiBaseURL2);
class TutorialComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            fields:{},
            identifying:'',
            error:{},
            tutorials:[],
            userRole:'',
            permissions:{},
            loggedInUserId:'',
            openModel: false,
            addTutorialLoader: false,
            gettutorialsLoader: false,
            designation:'all',
            openEditModel: false,
            updateFields:{},
            editTutorialLoader: false,
            //--  pagination --//
            currentPage:0,
            resultsPerPage:10,
            //--  pagination --//
        }
    }

    componentDidMount(){
        if(this.props.auth && this.props.auth.loggedIn){
            if(this.props.auth.user && this.props.auth.user.permissions && this.props.auth.user.permissions.tutorials){
                this.setState({ 
                    userRole: this.props.auth.user.role,    // admin/user/super_admin
                    permissions:{...this.props.auth.user.permissions.tutorials},
                    loggedInUserId: this.props.auth.user.userId,
                    identifying: this.props.auth.user.identifying // developer/bidder/hr/qualityAuditor/designer/
                }, () => {
                    if(!(this.state.userRole === 'super_admin' || this.state.userRole === 'admin')){
                       this.getTutorialsForLoggedInUsers(this.state.identifying)
                    }else{
                        this.getAllTutorials()
                    }
                })
            }
        }
    }
    /* @@

    ## Here Role in tutorial is different on the basis of tutorial "role" user "identifying" is matched to show particular tutorials to 

    @@ */

    getTutorialsForLoggedInUsers = async (role) => {
        this.setState({ gettutorialsLoader: true})
        await this.props.fetchRoleTutorials(role);
        if( this.props.tutorials.response ){
            this.setState({
                tutorials: this.props.tutorials.tutorials,
                gettutorialsLoader: false,
            })
        }else{
            toast.dismiss()
            toast.error( this.props.tutorials.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ gettutorialsLoader: false})
        }
    }

    getTutorialsForRole = async (role) => {
        this.setState({ gettutorialsLoader: true})
        await this.props.fetchRoleTutorials(role);
        // console.log(this.props.tutorials.tutorials)
        if( this.props.tutorials.response ){
            let i = 0
            let proj = this.props.tutorials.tutorials.map(function(el) {
                let o = Object.assign({}, el);
                o.id = i;
                i++;
                return o;
            })
            this.setState({
                tutorials: proj,
                gettutorialsLoader: false,
            })
        }else{
            toast.dismiss()
            toast.error( this.props.tutorials.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ gettutorialsLoader: false})
        }
    }

    getAllTutorials = async () => {
        this.setState({ gettutorialsLoader: true})
        await this.props.fetchAllTutorials();
        if( this.props.tutorials.response ){
            let i = 0
            let proj = this.props.tutorials.tutorials.map(function(el) {
                let o = Object.assign({}, el);
                o.id = i;
                i++;
                return o;
            })
            this.setState({
                tutorials: proj,
                gettutorialsLoader: false,
            })
        }else{
            toast.dismiss()
            toast.error( this.props.tutorials.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ gettutorialsLoader: false})
        }
    }

    handleInputChange = (e) => {
        const { name, value, options, selectedIndex } = e.target
        let fields = this.state.fields
        if(name == 'designation'){
            fields[name] = options[selectedIndex].innerHTML
            fields['role'] = value
        }else if(name == 'order'){
                if(isNaN(value)){
                    // console.log("Not a Number ")
                }else{
                    fields[name] = value
                }
        }else{
            fields[name] = value
        }
        this.setState({ fields })
    }

    handleEditChange = (e) => {
        const { name, value, options, selectedIndex } = e.target
        let updateFields = this.state.updateFields
        if(name == 'designation'){
            updateFields[name] = options[selectedIndex].innerHTML
            updateFields['role'] = value
        }else if(name == 'order'){
            if(isNaN(value)){
                // console.log("Not a Number ")
            }else{
                updateFields[name] = value
            }
        }else{
            updateFields[name] = value
        }
        this.setState({ updateFields })
    }

    handleSubmitTutorial = async (e) => {
        e.preventDefault();
        // console.log( this.state.fields )
        this.setState({ addTutorialLoader: true})
        await this.props.createTutorial(this.state.fields)
        if(this.props.addTutorial.response){
            toast.dismiss()
            toast.success( this.props.addTutorial.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ addTutorialLoader: false})
            if(this.state.designation === 'all'){
                this.getAllTutorials()
            }else{
                this.getTutorialsForRole(this.state.designation)
            }
            this.onCloseModel()
        }else{
            toast.dismiss()
            toast.error( this.props.addTutorial.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ addTutorialLoader: false})
        }
    }

    handleUpdateTutorial = async (e) =>{
        e.preventDefault();
        this.setState({ editTutorialLoader: true})
        // console.log( this.state.updateFields )
        let tutorialId = this.state.updateFields['_id']
        // console.log( tutorialId )
        let data = JSON.parse(JSON.stringify(this.state.updateFields));
        delete data['_id']
        delete data['createdAt']
        delete data['updatedAt']
        // console.log( data )
        await this.props.updateTutorial(tutorialId, data)
        if( this.props.editTutorial.response ){
            toast.dismiss()
            toast.success( this.props.editTutorial.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ editTutorialLoader: false})
            if(this.state.designation === 'all'){
                this.getAllTutorials()
            }else{
                this.getTutorialsForRole(this.state.designation)
            }
            this.onCloseEditModel()
        }else{
            toast.dismiss()
            toast.error( this.props.editTutorial.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
            this.setState({ editTutorialLoader: false})
        }

    }

    onCloseModel = () => {
        this.setState({ openModel: false })
        this.resetForm()
    }

    onCloseEditModel = () => {
        this.setState({ openEditModel: false })
        this.resetForm()
    }

    resetForm = () => {
        // let fields =  this.state.fields
        // let updateFields = this.state.updateFields
        this.setState({ fields:{}, updateFields:{} })
    }

    handleChangeDesignation = (e) => {
        const { name, value, options, selectedIndex } = e.target
        let designation = this.state.designation
        if(value == 'all'){
            designation = value
            this.getAllTutorials()
        }else{
            designation = value
            this.getTutorialsForRole(value)
        }

        this.setState({ designation })
    }

    convertDate = (date) => {
        var d = new Date(date);
        var year = (new Date().getFullYear() === d.getFullYear()) ? '' : ", " +  d.getFullYear()
        var monthName = d.toLocaleString('default', { month: 'short' })
        return  (monthName) + " " + d.getDate() +  year
    }

    onDeleteTutorial = ( id ) => {
        swal({
            title: "Are you sure?",
            text: `Once Deleted, Can't be Undo`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
                swal({ title: "Keep Patience", text: "Deleting...", button: false });
                await this.props.deleteTutorial(id)
                if(this.props.deletedTutorial.response){
                    swal({ title: "Deleted!", text: this.props.deletedTutorial.message, icon: "success" })
                    this.getAllTutorials();
                }else{
                    swal({ title: "Something went wrong!", text: this.props.deletedTutorial.message, icon: "error" })
                }
            } else {
                swal("Good job!", `Tutorial is Safe`, "success");
            }
          });
    }

    itemRenderer = (tutorial, index) => {
        const { permissions } = this.state
            return(
                <Card key={ index }>
                    <Accordion.Toggle as={ Card.Header } eventKey={ index.toString() }> 
                            <span> { tutorial.title } </span>
                            <span className="actions">  
                                { ( permissions.actions.canEdit ) && <i className="fa fa-pencil" aria-hidden="true" onClick={()=> this.setState({ openEditModel: true, updateFields: tutorial })}/>}
                                { ( permissions.actions.canDelete ) && <i className="fa fa-trash-o" aria-hidden="true" onClick={()=> this.onDeleteTutorial( tutorial._id )}/>}
                            </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={ index.toString() }>
                        <Card.Body> 
                            <pre> { tutorial.description }  </pre>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
        )
    }

    handleRLDDChange = (reorderedArray) => {
        // console.log(reorderedArray)
        socket.emit('reorder-tutorials', {data: reorderedArray })
        this.setState({ tutorials: reorderedArray})
    }


    render(){
        const { userRole, openModel, fields, gettutorialsLoader, addTutorialLoader, editTutorialLoader, tutorials, openEditModel, updateFields, permissions, currentPage, resultsPerPage } = this.state
        
        let addButtonDisable = true
        let editButtonDisable = true
        if(fields['designation'] && fields['role'] && fields['title'] && fields['description']){
            if(fields['designation'].trim() && fields['role'].trim() && fields['title'].trim() && fields['description'].trim()){
                addButtonDisable = false
            }
        }

        if(updateFields['designation'] && updateFields['role'] && updateFields['title'] && updateFields['description']){
            if(updateFields['designation'].trim() && updateFields['role'].trim() && updateFields['title'].trim() && updateFields['description'].trim()){
                editButtonDisable = false
            }
        }
        return(            
            <>  
                <Modal show={ openModel } onHide={ this.onCloseModel } ref={m => this.addTutorialModelRef = m}>
                    <Modal.Header closeButton>
                        <Modal.Title> Add Tutorial </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <Row>
                                <Col sm={12}>
                                    <form onSubmit={ this.handleSubmitTutorial}>
                                        <div className="form-group">
                                            <label htmlFor="exampleInpu1"> Designation </label>
                                            <select className="form-control mr-2" id="designation" name="designation" onChange={this.handleInputChange} value={ fields['role'] || ''}>
                                                <option value='' disabled>Select Designation</option>
                                                <option value='forAll'>All</option>
                                                <option value="designer">Web Designer</option>
                                                <option value="developer">Software Engineer</option>
                                                <option value="seo">SEO Manager & Executive</option>
                                                <option value="qualityAuditor">Quality Analyst</option>
                                                <option value="bidders">Business Developer</option>
                                                <option value="hr">Human Resources</option>
                                                <option value="teamLeader">Team Leader</option>
                                                <option value="projectManager">Project Manager</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInput2">Title</label>
                                            <input type="text" name="title" className="form-control" onChange={this.handleInputChange} value={fields['title'] || ''} placeholder="Enter Title"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="uor">Description</label>
                                            <textarea className="form-control" rows="6" name="description" onChange={this.handleInputChange} value={fields['description'] || ''} placeholder="Enter Description"/>
                                        </div>
                                        <div className="form-group" style={{float: "right"}}>
                                            <button type="submit" className="btn btn-primary" disabled={ addButtonDisable || addTutorialLoader }>{ addTutorialLoader ? 'Adding...': 'Add'}</button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                    </Modal.Body>
                </Modal>
                {this.state.updateFields &&
                <Modal show={ openEditModel } onHide={ this.onCloseEditModel }>
                    <Modal.Header closeButton>
                        <Modal.Title> Edit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <Row>
                                <Col sm={12}>
                                    <form onSubmit={ this.handleUpdateTutorial}>
                                        <div className="form-group">
                                            <label htmlFor="exampleInpu1"> Designation </label>
                                            <select className="form-control mr-2" id="designation" name="designation" onChange={this.handleEditChange} value={ updateFields['role'] || ''}>
                                                <option value='' disabled>Select Designation</option>
                                                <option value='forAll'>All</option>
                                                <option value="designer">Web Designer</option>
                                                <option value="developer">Software Engineer</option>
                                                <option value="seo">SEO Manager & Executive</option>
                                                <option value="qualityAuditor">Quality Analyst</option>
                                                <option value="bidders">Business Developer</option>
                                                <option value="hr">Human Resources</option>
                                                <option value="teamLeader">Team Leader</option>
                                                <option value="projectManager">Project Manager</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="exampleInput2">Title</label>
                                            <input type="text" name="title" className="form-control" onChange={this.handleEditChange} value={updateFields['title'] || ''} placeholder="Enter Title"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="uor">Description</label>
                                            <textarea className="form-control" rows="6" name="description" onChange={this.handleEditChange} value={updateFields['description'] || ''} placeholder="Enter Description"/>
                                        </div>
                                        <div className="form-group" style={{float: "right"}}>
                                            <button type="submit" className="btn btn-primary" disabled={ editButtonDisable || editTutorialLoader }>{ editTutorialLoader ? 'Updating...': 'Update'}</button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                    </Modal.Body>
                </Modal>}
                <div className="d-lg-flex justify-content-md-between mb-3 align-items-center">
                    <h3 className="mt-0 mb-0 mr-3">Tutorials</h3>
                    {(userRole === 'super_admin' || userRole === 'admin') &&
                    <div className="dateSelect w-auto d-md-flex justify-content-lg-end justify-content-between text-right text-md-left  align-items-center">
                        <Form inline className="mr-md-2 mb-2 mb-md-0">
                            {/* <Form.Label htmlFor="designation" className="mr-2">
                                Select Designation: 
                            </Form.Label> */}
                            <Form.Control as="select" id="inlineFormCustomSelect22"  onChange={this.handleChangeDesignation} value={ this.state.designation } custom>
                                    <option value='' disabled>Select All/Designation</option>
                                    <option value="all">All</option>
                                    <option value="designer">Web Designer</option>
                                    <option value="developer">Software Engineer</option>
                                    <option value="seo">SEO Manager & Executive</option>
                                    <option value="qualityAuditor">Quality Analyst</option>
                                    <option value="bidders">Business Developer</option>
                                    <option value="hr">Human Resources</option>
                                    <option value="teamLeader">Team Leader</option>
                                    <option value="projectManager">Project Manager</option>
                            </Form.Control>
                        </Form>
                        <Button variant="primary" className="ml-auto" onClick={()=> this.setState({ openModel: true })}>Add Tutorial</Button>
                    </div>}
                </div>
                { gettutorialsLoader
                    
                    ?
                    <div className="text-center circleSpinnner"><Spinner animation="border" /></div>
                    :
                
                    (tutorials && tutorials.length > 0)
                    ?
                    (this.state.userRole === 'super_admin' || this.state.userRole === 'admin')
                     ?
                        <Accordion defaultActiveKey='-1'>
                            <RLDD 
                                // cssClasses="example"
                                items={tutorials}
                                itemRenderer={this.itemRenderer}
                                onChange={this.handleRLDDChange}
                            />
                        </Accordion>
                    :
                        <Accordion defaultActiveKey='-1'>
                            {tutorials.map((tutorial, idx)=> {
                                return(
                                    <Card key={idx}>
                                        <Accordion.Toggle as={Card.Header} eventKey={idx.toString()}> 
                                                <span> {tutorial.title} 
                                                    {/* <span style={{color:'#999595', fontSize:'11px'}}> {`(${this.convertDate(tutorial.createdAt)})`} </span>  */}
                                                </span>
                                                <span className="actions">  
                                                    {/* {tutorial.designation}  */}
                                                    { ( permissions.actions.canEdit ) && <i className="fa fa-pencil" aria-hidden="true" onClick={()=> this.setState({ openEditModel: true, updateFields: tutorial })}/>}
                                                    { ( permissions.actions.canDelete ) && <i className="fa fa-trash-o" aria-hidden="true" onClick={()=> this.onDeleteTutorial(tutorial._id)}/>}
                                                </span>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={idx.toString()}>
                                            <Card.Body> 
                                                <pre> {tutorial.description}  </pre>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })}
                        </Accordion>
                    :
                    <p className="m-0 p-2 text-center border">
                        Nothing To Show
                    </p>
                }
                {/* {pageNumbers > 1 &&
                    <section className="pagination">
                        <ReactPaginate
                            previousLabel={'<<'}
                            nextLabel={'>>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageNumbers}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={paginate}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </section>} */}
            </>
        )
    }
}

const mapStateToProps = state => {
    const { tutorial, auth } = state
    return {
        auth: auth,
        addTutorial: tutorial.addTutorial,
        tutorials: tutorial.tutorials,
        editTutorial: tutorial.editTutorial,
        deletedTutorial: tutorial.deleteTutorial,
    }
}
const dispatchAction = { createTutorial, fetchRoleTutorials, updateTutorial, fetchAllTutorials, deleteTutorial }
export default connect( mapStateToProps, dispatchAction )(TutorialComponent)