// module.exports = {
//     // port 3555 api
//     apiBaseURL: "https://api.team.web1experts.com/api/v1",
//     apiBaseURL2: "https://api.team.web1experts.com",
//     mediaPath: "https://api.team.web1experts.com/status",

// }
const DEVELOPMENT = {
    apiBaseURL: "http://localhost:3555/api/v1",
    apiBaseURL2: "http://localhost:3555",
    mediaPath: "http://localhost:3555/status",
}

const STAGING = {
    apiBaseURL: "https://team.web1experts.com:8443/api/v1",
    apiBaseURL2: "https://team.web1experts.com:8443",
    mediaPath: "https://team.web1experts.com:8443/status",
}

const PRODUCTION = {
    apiBaseURL: "https://api.team.web1experts.com/api/v1",
    apiBaseURL2: "https://api.team.web1experts.com",
    mediaPath: "https://api.team.web1experts.com/status",
}


const ENVIRONMENT = STAGING // STAGING // DEVELOPMENT // PRODUCTION 

module.exports = ENVIRONMENT