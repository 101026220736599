import React, { Component } from 'react';
import { Row, Col, Container, Form, Button, Spinner, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
// import Header from '../../containers/Header';

import { login, logout, forgotPassword } from '../../actions/authenticateActions';
import { toast } from "react-toastify";
toast.configure();
class Login extends Component {
    constructor(props){
        super(props)
        this.state = {
            fields:{},
            errors:{},
            loginDisable: false,
            remember:false,
            openModel: false,
            email:'',
            forgotDisable: true
        }
    }

    componentDidMount = () =>{
        if(this.props.auth.loggedIn){
            return this.props.history.push('/status')
        }else{
             this.props.logout();
            let rememberMe = JSON.parse( localStorage.getItem('RememberMe') );
            if( rememberMe ){
                // console.log(rememberMe)
                let fields = this.state.fields
                fields['email'] = rememberMe.email
                fields['password'] = rememberMe.password
                let remember = rememberMe.remember
                this.setState({ fields, remember })
            }
        }

        if(localStorage.getItem('RememberMe')){
            localStorage.removeItem('RememberMe');
        }
    }

    onCloseModel = () => {
        this.setState({ openModel: false, email:'' })
    }


    handleRememberMe = () => {
        let remember  = this.state.remember;

        if(this.state.remember){
            remember = false
        }else{
            remember = true
        }

        this.setState({ remember }, ()=>{
            if ( this.state.remember ){
                const data = { email: this.state.fields['email'], password: this.state.fields['password'], remember: true }
                localStorage.setItem('RememberMe', JSON.stringify(data));
            }else{
                if( localStorage.getItem('RememberMe') ){
                    localStorage.removeItem('RememberMe')
                }
            }
        });
    }

    onHandleInputChange = (e) => {
        const { name, value } = e.target
        let fields = this.state.fields;
        let errors = {}
        if( name === 'email' ){
            value.toLowerCase();
        }
        fields[name] = value
        errors[name] = ''
        this.setState({ fields, errors })
    }

    handleForgotInputChange = (e) => {
        const { value } = e.target
        let email = value
        if( value.length > 0 ){
            this.setState({ email, forgotDisable: false })
        }else{
            this.setState({ email, forgotDisable: true })
        }
    }

    isFormValid = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if(!fields["email"]){
            formIsValid = false;
            errors["email"] = "Email is required";
        }

        if(!fields["password"]){
            formIsValid = false;
            errors["password"] = " Password is required";
        }
       
        this.setState({ errors });
        return formIsValid;
    }

    handleLoginSubmit = async e  => {
        e.preventDefault();
        if(this.isFormValid()){
            this.setState({ loginDisable: true })
            await this.props.login( this.state.fields )
            if(this.props.auth.user){
                if(this.props.auth.user.response){
                    toast.dismiss()
                    toast.success(this.props.auth.user.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
                    setTimeout( () => {
                        window.location.replace('/status'); // this.props.history.push('/');
                    }, 850)
                }else{
                    this.setState({ loginDisable: false })
                    toast.dismiss()
                    toast.error(this.props.auth.user.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
                }
            }else{
                this.setState({ loginDisable: false })
                toast.dismiss()
                toast.error('Unable to complete your request please try after some time', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
            }
        }
    }

    handleForgotSubmit = async (e) => {
        e.preventDefault()
        this.setState({ forgotLoader: true })
        await this.props.forgotPassword(this.state.email)
        if(this.props.forgotRequest.response){
            toast.dismiss()
            toast.success(this.props.forgotRequest.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
            this.setState({ forgotLoader: false })
            this.onCloseModel()
        }else{
            toast.dismiss()
            toast.error(this.props.forgotRequest.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
            this.setState({ forgotLoader: false })
        }

    }

    render() {
        const { loginDisable, fields, errors, forgotDisable, openModel } = this.state
        return (
            <>
            {/* <Header/> */}
            <Modal show={ openModel } onHide={ this.onCloseModel }>
                <Modal.Header closeButton>
                    <Modal.Title> Forgot Password </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <Row>
                            <Col sm={12}>
                                <form onSubmit={ this.handleForgotSubmit }>
                                    <div class="form-group">
                                        <label for="exampleInput2">Email </label>
                                        <input type="text" name="email" className="form-control" onChange={this.handleForgotInputChange} value={this.state.email} placeholder="Enter Email"/>
                                    </div>
                                    <div class="form-group" style={{float: "right"}}>
                                        <button type="submit" class="btn btn-primary" disabled={ forgotDisable }> {this.state.forgotLoader ? 'wait...': 'Send'} </button>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                </Modal.Body>
            </Modal>
            <div className="default_sec contact_sec hv-100">
                <Container className="h-100">
                    <Row className="align-items-center h-100">
                        <Col md={6} className="loginImage">
                            <img src="../images/loginImage.png" alt="..." />
                        </Col>
                        <Col md={6}>
                            <div class="mb-3">
                                <img src="../images/web1logo.png" style={{maxWidth: "240px"}} alt="Web1Experts"/>
                            </div>
                            <Form id="contentdata" onSubmit={ this.handleLoginSubmit }>
                                <Row>
                                    <Col sm={12}>
                                        <h2 className="mb-3">Let's Get Started</h2>
                                        <div className="form-input">
                                            <input type="text" name="email" placeholder="Enter Email Address"  style={{ borderBottomColor: errors["email"] ? 'red': '' }} className="form-control" onChange={this.onHandleInputChange} value={ fields['email'] || ''}/>
                                        </div>
                                        <div className="form-input"> 
                                            <input type="password" name="password" placeholder="Enter Your Password" style={{ borderBottomColor: errors["password"] ? 'red': '' }} className="form-control" onChange={this.onHandleInputChange} value={ fields['password'] || ''}/>
                                        </div>
                                        <div className="form-input d-flex align-items-center justify-content-between">
                                            <div className="form-check">
                                                {/* <input className="form-check-input" type="checkbox" value="" id="Remember" onChange={(e)=> this.handleRememberMe(e)} checked={this.state.remember}/>
                                                <label className="form-check-label" htmlFor="Remember">
                                                    Remember Me
                                                </label> */}
                                            </div>
                                            <span style={{borderBottom:'2px solid #000', cursor:'pointer', padding:'0rem .25rem'}} className="forgotLink" onClick={()=> this.setState({ openModel: true })}>Forgot Password ?</span>
                                        </div>                                
                                    </Col>
                                    <Col sm={12} className="text-center"> 
                                        {/* <input type="submit" name="login" value="Login" /> */}
                                        <Button type='submit' variant="primary" disabled={ loginDisable }> 
                                            { loginDisable 
                                                ? 
                                                <> 
                                                {/* <Spinner  as="span" className="mr-1 mb-1" animation="border" size="sm" role="status" aria-hidden="true" variant="light" /> */}
                                                    Wait...
                                                </>
                                                : 
                                                <>
                                                    Login
                                                </> 
                                            } 
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>                
                </Container>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    const { auth } = state
    return {
        auth: auth,
        forgotRequest: auth.user
    }
}

const dispatchActions  = { login, logout, forgotPassword }
export default connect( mapStateToProps, dispatchActions )(Login)