import { FETCH_USER } from './types';
import { apiRequest, checkTokenExpire } from './actionResponseHandler';
import authHeader from '../helpers/auth-header'
import { toast } from "react-toastify";
toast.configure()

export const fetchUser = (userId) => async dispatch => {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": authHeader()
            },
        }
        const result = await apiRequest( `/user/${userId}`, requestOptions );
        checkTokenExpire(result)
        await dispatch ({ type: FETCH_USER, payload: result})
    } catch (error) {
        if(error && error.status === 401){
            checkTokenExpire(error.data)
        }else{
            toast.dismiss()
            toast.error('Something Went Wrong', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true })
        }
    }
}

//  ------ Not In Use ------- //
export const updateHeaderFromSetting = (data) => async dispatch => {
    await dispatch ({ type: 'CUSTOME_ACTION_TO_UPDATE_HEADER', payload: data})
}


